import {
  ACTIVE_AJAX_FANSHOP_IDS,
  ORGANIZATION_UNIT_ID,
} from '~source/constants';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import transformAvailibility from '~source/core/transformers/product/transform-availability';

export type AvailabilityProduct = {
  ID?: number;
  QuantityRequested: number;
};

export default async function getProductAvailability({
  products,
  locale,
  authenticationToken,
}: {
  products: AvailabilityProduct[];
  locale: LocaleLanguageType;
  authenticationToken?: string | null;
  includeOrganizationUnitsWithoutStock?: boolean;
}) {
  if (products.length === 0) return null;

  const organizationUnitID = Number(ORGANIZATION_UNIT_ID);

  const response = await getEvaService(
    Core.GetProductAvailability,
    {
      OrganizationUnitID: organizationUnitID,
      Options: {
        Bundles: {
          IncludeBundleInformation: true,
        },
        Delivery: {
          AvailabilityDate: true,
        },
        Pickup: {
          AvailabilityDate: true,
          IncludePickupOrganizationUnits: true,
          IncludeOrganizationUnitsWithoutStock: false,
        },
        CarryOut: {
          IncludeOrganizationUnitsWithoutStock: true,
          IncludeCarryOutOrganizationUnits: true,
          OrganizationUnitIDs: ACTIVE_AJAX_FANSHOP_IDS,
        },
        IncludeRelatedStockReplacingProducts: true,
        QuantityAvailable: false,
      },
      Products: products,
    },
    { authenticationToken: authenticationToken ?? undefined },
  );

  return transformAvailibility(response, locale);
}
