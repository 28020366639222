import { VideoPlayer } from '~source/core/models/components/molecules/video-player';
import { VideoPlayerResponse } from '~source/core/models/response/video-player-response';
import uuid from '~source/core/utils/uuid';

export default async function transformVideoPlayer(
  response: VideoPlayerResponse,
): Promise<VideoPlayer> {
  const {
    content: {
      landscapeVideoTitle,
      landscapeVideoSubtitle,
      landscapeVideoUrl,
      portraitVideoTitle,
      portraitVideoSubtitle,
      portraitVideoUrl,
      buttonLabel,
      buttonLink,
    },
  } = response;

  return {
    id: uuid(),
    moduleType: 'videoPlayer',
    buttonLabel,
    buttonLink,
    videoModes: {
      portrait: {
        title: portraitVideoTitle,
        subtitle: portraitVideoSubtitle,
        url: portraitVideoUrl,
      },
      landscape: {
        title: landscapeVideoTitle,
        subtitle: landscapeVideoSubtitle,
        url: landscapeVideoUrl,
      },
    },
  };
}
