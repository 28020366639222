import { BannersSlider } from '~source/core/models/components/organisms/banners-slider';
import { BannersSliderResponse } from '~source/core/models/response/banner-response';
import transformHeaderBanner from '~source/core/transformers/molecules/transform-header-banner';
import uuid from '~source/core/utils/uuid';

export default async function transformBannersSlider(
  response: BannersSliderResponse,
): Promise<BannersSlider> {
  const { banners } = response.content;

  const transformedBanners = banners.map((banner) =>
    transformHeaderBanner(banner),
  );

  return {
    id: uuid(),
    moduleType: 'bannersSlider',
    banners: transformedBanners,
  };
}
