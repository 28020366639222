export default function trimString(
  string: string | null | undefined,
): string | null {
  // if the string is nullish, return null.
  if (string == null) {
    return null;
  }
  // otherwise, trim the string and check if it is empty.
  // If not, return the value.
  const result = string.trim();
  if (result === '') return null;
  return result;
}
