import { LoginButton } from '~source/core/models/components/atoms/login-button';
import { LoginButtonResponse } from '~source/core/models/response/login-button-response';
import uuid from '~source/core/utils/uuid';

export default async function transformLoginButton(
  response: LoginButtonResponse,
): Promise<LoginButton> {
  const { content, displaySettings } = response;

  return {
    id: uuid(),
    moduleType: 'loginButton',
    label: content.label,
    displaySettings,
  };
}
