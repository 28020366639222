import * as React from 'react';
import { AbortableFetch } from '~source/core/models/fetch/AbortableFetch';

function useAbortableFetch<T, A extends any[]>(
  abortableFetch: AbortableFetch<T, A>,
  ...args: A
) {
  const [value, setValue] = React.useState<T | null>(null);
  const [pending, setPending] = React.useState(true);
  const [error, setError] = React.useState<unknown | null>(null);

  React.useEffect(() => {
    setPending(true);
    const { promise, abort } = abortableFetch(...args);
    promise
      .then(setValue)
      .catch(setError)
      .finally(() => setPending(false));
    return abort;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortableFetch, ...args]);

  return [value, pending, error] as const;
}

export default useAbortableFetch;
