import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import * as React from 'react';
import * as sessionStorage from '~source/core/services/local-storage/session-storage';
import getSingleValue from '~source/ui/utils/getters/get-single-value';

interface SiteConfig {
  isApp: 'true' | 'false' | 'unknown';
  devRedirectPaymentToAppSuccessPage: 'true' | 'false' | 'unknown';
}

const SESSION_STORAGE_KEY = 'ajax.siteconfig';

const SiteConfigContext = React.createContext<SiteConfig | null>(null);

export function useSiteConfig() {
  const context = React.useContext(SiteConfigContext);
  if (context === null) throw new Error('Missing Provider around root');
  return context;
}

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const [siteConfig, setSiteConfig] = React.useState<SiteConfig>({
    isApp: 'unknown',
    devRedirectPaymentToAppSuccessPage: 'unknown',
  });

  React.useEffect(() => {
    const localSiteConfig = sessionStorage.getItem(SESSION_STORAGE_KEY);
    const isApp = getSingleValue(router.query.isApp) === 'true';
    const devRedirectPaymentToAppSuccessPage =
      getSingleValue(router.query.devRedirectPaymentToAppSuccessPage) ===
      'true';

    if (isApp || devRedirectPaymentToAppSuccessPage) {
      const nextSiteConfig: SiteConfig = {
        isApp: isApp ? 'true' : 'false',
        devRedirectPaymentToAppSuccessPage: devRedirectPaymentToAppSuccessPage
          ? 'true'
          : 'false',
      };
      sessionStorage.setItem(SESSION_STORAGE_KEY, nextSiteConfig);
      setSiteConfig(nextSiteConfig);
    } else if (localSiteConfig) {
      setSiteConfig(localSiteConfig);
    } else {
      setSiteConfig({
        isApp: 'false',
        devRedirectPaymentToAppSuccessPage: 'false',
      });
    }
  }, [router.query.isApp, router.query.devRedirectPaymentToAppSuccessPage]);

  Sentry.setTag('isApp', siteConfig.isApp);

  return (
    <SiteConfigContext.Provider value={siteConfig}>
      {children}
    </SiteConfigContext.Provider>
  );
};

export default useSiteConfig;
