import {
  ID_DHL_SERVICENAME,
  ID_DHL_SERVICEPOINT,
  ID_DELIVERY_INFO,
} from '~source/constants';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export async function setOrderField({
  orderId,
  deliveryInfo,
  dhlServicepointId,
  dhlServicepointName,
  signal,
}: {
  deliveryInfo: string;
  dhlServicepointId?: string;
  dhlServicepointName?: string;
  orderId: number;
  signal?: AbortSignal;
}) {
  const customFields = [
    {
      CustomFieldID: parseInt(ID_DELIVERY_INFO, 10),
      StringValue: deliveryInfo,
    },
  ];

  if (dhlServicepointId) {
    customFields.push({
      CustomFieldID: parseInt(ID_DHL_SERVICEPOINT, 10),
      StringValue: dhlServicepointId,
    });
  }
  if (dhlServicepointName) {
    customFields.push({
      CustomFieldID: parseInt(ID_DHL_SERVICENAME, 10),
      StringValue: dhlServicepointName,
    });
  }

  return getEvaService(
    Core.UpdateOrderCustomFields,
    {
      OrderID: orderId,
      CustomFields: customFields,
    },
    { signal },
  );
}
