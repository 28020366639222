import { CART_PATH } from '~source/constants/paths';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './cart-icon.module.scss';

type Props = {
  amount: number;
};

export default function CartIcon({ amount }: Props) {
  const t = useTranslate();
  return (
    <AjaxLink
      link={CART_PATH}
      aria-label={t('CART_BUTTON')}
      className={cx($.link, $.basket)}
    >
      <img
        src="/shop/images/icons/ic-basked-filled.svg"
        alt=""
        width="20"
        height="20"
      />
      <span className="visually-hidden">{t('ITEMS_IN_CART')}</span>
      <span className={$.basketItems}> {amount}</span>
    </AjaxLink>
  );
}
