import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export const cancelDiscountOrderLine = async (
  {
    orderLineId,
  }: {
    orderLineId: number;
  },
  authenticationToken?: string | null,
) => {
  return getEvaService(
    Core.CancelDiscountOrderLine,
    { OrderLineID: orderLineId },
    { authenticationToken: authenticationToken ?? undefined },
  );
};
