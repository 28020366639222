import type { HorizontalDivider } from '~source/core/models/components/atoms/horizontal-divider';
import type { HorizontalDividerResponse } from '~source/core/models/response/horizontal-divider-response';
import uuid from '~source/core/utils/uuid';

export default async function transformHorizontalDivider(
  response: HorizontalDividerResponse,
): Promise<HorizontalDivider> {
  const { displaySettings } = response;

  return {
    id: uuid(),
    moduleType: 'horizontalDivider',
    displaySettings,
  };
}
