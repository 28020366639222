import { AuthenticationResult } from '@azure/msal-browser';
import * as Sentry from '@sentry/nextjs';
import { deleteCookie, getCookie } from 'cookies-next';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import React from 'react';
import {
  acquireTokenSilentMsal,
  ssoSilentMsal,
} from '~source/core/services/msal';

/** Takes care of signing a user in automatically when coming from the Ajax site */
function useAutoSignInFromSite({
  onSuccess,
}: {
  onSuccess: (result: AuthenticationResult) => void;
}) {
  React.useEffect(() => {
    // The shopLoginHint cookie is set by the Ajax site after the user is logged in.
    // When it's present, it's a hint for the shop to log in the user silently.
    const shopLoginHint = getCookie('shopLoginHint');
    if (!shopLoginHint) return;

    (async () => {
      const decodedLoginHint = jwtDecode<JwtPayload & { email: string }>(
        shopLoginHint,
      );

      await ssoSilentMsal({ loginHint: decodedLoginHint.email });

      const result = await acquireTokenSilentMsal();
      if (result) {
        onSuccess(result);
      }
    })()
      .catch((error) => Sentry.captureException(error))
      .finally(() => deleteCookie('shopLoginHint'));
  }, [onSuccess]);
}

export default useAutoSignInFromSite;
