import {
  LocalStorageDiscount,
  LocalStorageDiscountCodes,
} from '~source/core/models/components/atoms/discount';
import * as localStorage from '~source/core/services/local-storage/local-storage';

export const COUPON_CODES_LOCAL_STORAGE_KEY = 'coupon-codes';

export function isCouponExpired(discount: LocalStorageDiscount) {
  const dateNow = Date.now();
  const dayInSeconds = 86400;
  const passedTime = dateNow - discount.dateAdded;
  return passedTime > dayInSeconds;
}

export function setCouponCodeInLocalStorage(codes: string | string[]) {
  const date = Date.now();
  const listOfCodes = Array.isArray(codes) ? codes : [codes];
  const codesWithAddedDate = listOfCodes.map((code) => ({
    code,
    dateAdded: date,
  }));

  localStorage.setItem(COUPON_CODES_LOCAL_STORAGE_KEY, codesWithAddedDate);
}

export function removeCouponCodeFromLocalStorage(
  codesToRemove?: string | string[],
) {
  const currentCodes = localStorage.getItem(
    COUPON_CODES_LOCAL_STORAGE_KEY,
  ) as LocalStorageDiscountCodes;
  if (!currentCodes) return;

  if (!codesToRemove) {
    localStorage.removeItem(COUPON_CODES_LOCAL_STORAGE_KEY);
    return;
  }

  const codesToBeRemoved = Array.isArray(codesToRemove)
    ? codesToRemove
    : [codesToRemove];
  const updatedCodes = currentCodes
    .filter(
      (code) =>
        !codesToBeRemoved.find((codeToRemove) => codeToRemove === code.code),
    )
    .map((code) => code.code);

  setCouponCodeInLocalStorage(updatedCodes);
}
