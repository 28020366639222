import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import { CartLineWithBundleProducts } from '~source/core/models/components/atoms/cart-line';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import assignProductPropsToBundle from '~source/core/transformers/cart/assign-product-props-to-bundle';
import getMainProducts from '~source/core/transformers/cart/get-main-products';
import mergeDuplicateBundles from '~source/core/transformers/cart/merge-duplicate-bundles';
import transformLineToCartProduct from '~source/core/transformers/cart/transform-line-to-cart-product';
import {
  isDiscount,
  isGiftWrapping,
  isShipping,
} from '~source/ui/utils/checks/is-line-type';

export default function transformLines({
  lines,
  additionalPrintings,
  availabilityOfProducts,
  isOrdered,
  locale,
}: {
  lines: EVA.Core.OrderLineDto[];
  additionalPrintings: AdditionalPrintings[] | null;
  availabilityOfProducts: AvailabilityIndication | null;
  isOrdered: boolean;
  locale?: LocaleLanguageType;
}): CartLineWithBundleProducts[] {
  if (!lines) return [];
  // Discounts, shipping costs and gift wrapping costs are considered a line in EVA so we have to filter them out
  const products = lines.filter(
    (line) => !isShipping(line) && !isDiscount(line) && !isGiftWrapping(line),
  );
  const transformedProducts = products.map((line) =>
    transformLineToCartProduct(
      line,
      additionalPrintings,
      availabilityOfProducts,
      isOrdered,
    ),
  );

  // Badges, names and backnumbers are added as a line so we have to remove them and add them later on to their bundle
  const mainProducts = getMainProducts(transformedProducts);

  // Assign the names, backnumbers and badges to the bundle itself
  const productsWithBundleInfo = mainProducts.map((product) =>
    assignProductPropsToBundle({
      product,
      cartProducts: transformedProducts,
      availabilityOfProducts,
      locale,
    }),
  );

  // Bundle products can't be duplicated but if there are two bundles that are the same
  // Only show the user one and manually change the quantityOpen to the amount of bundles
  const uniqueListOfProducts = productsWithBundleInfo.reduce(
    mergeDuplicateBundles,
    [],
  );
  return uniqueListOfProducts;
}
