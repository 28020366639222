import { ImageBlob } from '~source/core/models/components/atoms/image-blob';
import { useApplicationConfig } from '~source/ui/hooks/useApplicationConfig';

type ExtensionType = 'jpg' | 'png' | null;

function getExtensionFromType(type: string): ExtensionType {
  switch (type.trim()) {
    case 'image/jpeg':
    case 'image/jpg':
      return 'jpg';
    case 'image/png':
      return 'png';
    default:
      return null;
  }
}

export default function useImageUrl(
  image: ImageBlob | null,
  config: {
    width?: number;
    height?: number;
    extension?: ExtensionType;
  } = {
    width: 500,
    height: 500,
  },
) {
  const appConfig = useApplicationConfig();
  if (!image) return `/shop/images/fallback.webp`;
  const assetsUrl = appConfig && appConfig.assetsUrl;
  if (!assetsUrl) return '';
  const { blob, type } = image;
  const { width, height, extension = getExtensionFromType(type) } = config;

  if (extension && assetsUrl && blob) {
    return `${assetsUrl}/image/${width}/${height}/${image.blob}.${extension}`;
  }

  return `/shop/images/fallback.webp`;
}
