import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import getAdditionalPrintingValue from '~source/core/transformers/cart/get-additional-printing-value';

export default function getBackNumber({
  id,
  printOption,
  productRequirements,
  additionalPrintings,
}: {
  id: number;
  productRequirements: any; // EVA hasn't typed the product requirements
  printOption: 'number' | 'letters';
  additionalPrintings: AdditionalPrintings[] | null;
}) {
  if (printOption !== 'number') return undefined;
  if (!additionalPrintings) return ''; // No additionalPrintings means still retrieving printings

  return getAdditionalPrintingValue(
    additionalPrintings,
    id,
    productRequirements,
  );
}
