import * as Sentry from '@sentry/nextjs';
import { CompletedOrder } from '~source/core/models/components/templates/order';
import { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';
import {
  ECommerceInputItem,
  ECommerceEventType,
  EcommerceProductDataToTrack,
  EcommerceProductsDataToTrack,
} from '~source/core/models/payload/e-commerce';
import { PushECommerceProps } from '~source/core/models/payload/send-e-commerce';
import getECommerceProduct from '~source/core/services/eva/api/get-e-commerce-product';
import transformDataToECommerceItem from '~source/core/transformers/e-commerce/transform-data-to-e-commerce-item';
import transformLinesToECommerceItems from '~source/core/transformers/e-commerce/transform-lines-to-e-commerce-items';
import transformProductsToECommerceItems from '~source/core/transformers/e-commerce/transform-products-to-e-commerce-items';
import transformThirdPartyDataToECommerceItems from '~source/core/transformers/e-commerce/transform-third-party-data-to-e-commerce-items';

function pushECommerceEvent({
  eventType,
  items,
  orderId,
  orderData,
}: PushECommerceProps) {
  try {
    if (items.length === 0) return;
    const eventData = {
      event: eventType,
      ecommerce: {
        ...orderData,
        orderId,
        items,
      },
      _clear: true,
    };
    // @ts-ignore dataLayer does exist
    const dataLayer = window.dataLayer || [];
    dataLayer.push(eventData);
  } catch (error) {
    Sentry.captureException(error);
  }
}

// handle e-commerce events which require cart information
export const sendECommerceCart = (
  eventType: ECommerceEventType,
  cart: ShoppingCart | null,
) => {
  if (!cart) return;
  const items = transformLinesToECommerceItems(cart.lines);
  if (!items) return;
  pushECommerceEvent({
    eventType,
    items,
  });
};

// handle purchase e-commerce event
export const sendECommercePurchase = (order: CompletedOrder) => {
  if (!order) return;
  const items = transformThirdPartyDataToECommerceItems(
    order.thirdPartyProductsData,
  );
  if (!items) return;
  const {
    transactionId,
    transactionTotal,
    transactionTax,
    transactionShipping,
    transactionCurrency,
    transactionPromoCode,
  } = order.transactionData || {};
  const orderData = {
    transaction_id: transactionId,
    affiliation: 'Online Store',
    value: transactionTotal,
    tax: transactionTax,
    shipping: transactionShipping,
    currency: transactionCurrency,
    coupon: transactionPromoCode,
  };
  pushECommerceEvent({
    eventType: 'purchase',
    items,
    orderData,
  });
};

// handle e-commerce events on items
export const sendECommerce = (
  eventType: ECommerceEventType,
  eCommerceInputData: ECommerceInputItem | number,
) => {
  if (!eCommerceInputData) return;

  let item: ECommerceInputItem = {};
  if (typeof eCommerceInputData === 'number') {
    item = { id: eCommerceInputData };
  } else {
    item = eCommerceInputData;
  }

  getECommerceProduct({ item })
    .then((transformedItems) => {
      if (!transformedItems) return;
      pushECommerceEvent({
        eventType,
        orderId: item.orderId,
        items: [transformedItems],
      });
    })
    .catch(Sentry.captureException);
};

export const sendAnalyticsOfItem = (data: EcommerceProductDataToTrack) => {
  const { product, eventType } = data;
  const item = transformDataToECommerceItem(data, product);
  sendECommerce(eventType, item);
};

export const sendAnalyticsOfItemList = (data: EcommerceProductsDataToTrack) => {
  const { products, eventType } = data;
  const items = transformProductsToECommerceItems(products, data);
  if (!items) return;
  pushECommerceEvent({
    eventType,
    items,
  });
};
