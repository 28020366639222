import { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';

export default function calculateDisplayCartAmount(
  shoppingCart: ShoppingCart | null,
): number | null {
  if (!shoppingCart) return null;
  return shoppingCart.lines.reduce(
    (totalAmount, line) => totalAmount + line.quantityOpen,
    0,
  );
}
