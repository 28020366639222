import React from 'react';
import $ from './hidden-box.module.scss';

type Props = {
  description: string;
  id: string;
  value: string | number | undefined;
};

// this box is hidden in the UI and not accessible, but its content is used for Dynamic Yield purposes
const HiddenBox: React.FC<Props> = ({ id, description, value }) => {
  if (!id) return null;

  return (
    <div id={id} className={$.container} aria-hidden="true">
      <p>{description}</p>
      <p>{value}</p>
    </div>
  );
};

export default HiddenBox;
