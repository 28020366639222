import * as Sentry from '@sentry/nextjs';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import React from 'react';
import { AJAX_SSO_CLIENT_NATIVE_APP_PROVIDER_ID } from '~source/constants';
import { AjaxUserAccount } from '~source/core/models/account';
import * as EvaAccountService from '~source/core/services/eva/api/account/account';
import parseNumber from '~source/core/utils/parse-number';
import removeQueryParam from '~source/ui/utils/formatters/remove-query-param/remove-query-param';
import retrieveThrownErrorMessage from '~source/ui/utils/getters/retrieve-thrown-error-message';

const RELATIVE_URL_RE = /^\/shop\/?/;

function relativePath(path: string | null) {
  if (path === null) return null;
  return path.replace(RELATIVE_URL_RE, '/');
}

/** Takes care of signing a user in automatically when viewing in the webview inside the Ajax app */
function useAutoSignInFromApp({
  onSuccess,
}: {
  onSuccess: (account: AjaxUserAccount) => void;
}) {
  const router = useRouter();

  React.useEffect(() => {
    const idToken = getCookie('appLoginHint');
    if (!idToken) return;

    const providerId = parseNumber(AJAX_SSO_CLIENT_NATIVE_APP_PROVIDER_ID);

    EvaAccountService.loginUserAccount({
      idToken,
      providerId: providerId ?? undefined,
    })
      .then(async (account) => {
        onSuccess(account);

        const redirectPath =
          typeof router.query.redirectPath === 'string'
            ? relativePath(router.query.redirectPath)
            : null;

        if (redirectPath) {
          await router.push(redirectPath);
          removeQueryParam('redirectPath', router);
        }
      })
      .catch((error) => {
        const message = retrieveThrownErrorMessage(error);
        if (message.includes('User does not exist')) return;

        Sentry.captureException(error);
      });
  }, [onSuccess, router]);
}

export default useAutoSignInFromApp;
