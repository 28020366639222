import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

// 1 = Shipping to dutch address
// 2 = shipping to foreign address
// 13 = shipping by DHL Service Point for production
// 16 = shipping by DHL Service Point for development
// These values are determined by AJAX
export enum ShippingMethod {
  HOME_DUTCH = 1,
  HOME_FOREIGN = 2,
  DHL_PRODUCTION = 13,
  DHL_DEVELOPMENT = 16,
}

export async function setShippingMethod({
  orderId,
  shippingMethodId,
  signal,
}: {
  orderId: number;
  shippingMethodId: ShippingMethod;
  signal?: AbortSignal;
}) {
  return getEvaService(
    Core.SetShippingMethod,
    {
      OrderID: orderId,
      ShippingMethodID: shippingMethodId,
    },
    { signal },
  );
}
