import React from 'react';

const useExternalScript = ({ src }: { src: string }) => {
  const [state, setState] = React.useState<'PENDING' | 'LOADED' | 'FAILED'>(
    'PENDING',
  );

  React.useEffect(() => {
    const exists = !!document.querySelector(`script[src="${src}"]`);
    if (exists) setState('LOADED');

    const handleScriptEvent = (e: Event) =>
      setState(e.type === 'load' ? 'LOADED' : 'FAILED');

    if (exists) return undefined;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = src;
    script.async = true;

    script.addEventListener('load', handleScriptEvent);
    script.addEventListener('error', handleScriptEvent);

    document.head.appendChild(script);

    return () => {
      script.removeEventListener('load', handleScriptEvent);
      script.removeEventListener('error', handleScriptEvent);
    };
  }, [src]);

  return state;
};

export default useExternalScript;
