import { AjaxAccount } from '~source/core/models/account';

const getAccountDisplayName = (account: AjaxAccount) => {
  if (account.type === 'ajax-guest-account') {
    return [account.firstName, account.lastName].join(' ').trim() ?? '';
  }

  return account.firstName || account.email || '';
};

export default getAccountDisplayName;
