import { Core } from '@springtree/eva-services-core';
import { SEARCH_PRODUCTS_REQUIRED_FIELDS } from '~source/constants';
import { SortDirection } from '~source/core/models/components/molecules/filters';
import { ProductPagination } from '~source/core/models/components/molecules/product-teaser';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { getAbortableEvaService } from '~source/core/services/eva/api/get-eva-service';
import transformSearchProducts from '~source/core/transformers/product/transform-search-products';

export const productPaginationEmpty: ProductPagination = {
  currentPage: 0,
  products: [],
  totalProducts: 0,
  totalPages: 0,
};

export function searchProducts({
  query,
  filters,
  sortDirection,
  currentPage = 1,
  pageSize = 18,
  locale,
}: {
  query?: string;
  filters?: Record<string, EVA.Core.FilterModel>;
  sortDirection?: SortDirection;
  currentPage?: number;
  pageSize?: number;
  locale?: LocaleLanguageType;
}) {
  let sort: EVA.Core.SortFieldDescriptor[] = [
    { FieldName: 'prio_score2', Direction: 1 },
  ];
  if (sortDirection) {
    switch (sortDirection) {
      case 'alphabet':
        sort = [
          {
            FieldName: 'display_value',
            Direction: 0,
          },
        ];
        break;
      case 'priceup':
        sort = [
          {
            FieldName: 'display_price',
            Direction: 0,
          },
        ];
        break;
      case 'pricedown':
        sort = [
          {
            FieldName: 'display_price',
            Direction: 1,
          },
        ];
        break;
      case 'unsorted':
      default:
        break;
    }
  }

  return getAbortableEvaService(
    {
      service: Core.SearchProducts,
      payload: {
        Query: query,
        Filters: filters,
        IncludedFields: SEARCH_PRODUCTS_REQUIRED_FIELDS,
        Sort: sort,
        PageSize: pageSize,
        Page: currentPage,
      },
      options: {
        locale,
      },
    },
    (response) => transformSearchProducts(response),
    () => productPaginationEmpty,
  );
}

export function searchProductsByCollection({
  collection,
  filterProperties = {},
  sortDirection,
  currentPage,
  pageSize,
  locale,
}: {
  collection: string;
  filterProperties?: Record<string, string[]>;
  sortDirection?: SortDirection;
  currentPage?: number;
  pageSize?: number;
  locale?: LocaleLanguageType;
}) {
  const filters = Object.entries(filterProperties).reduce<
    Record<string, EVA.Core.FilterModel>
  >((acc, [property, values]) => {
    acc[property] = {
      Values: values,
    };
    return acc;
  }, {});
  return searchProducts({
    sortDirection,
    currentPage,
    pageSize,
    filters: {
      ...filters,
      logical_level: {
        IncludeMissing: true,
        Values: ['root'],
      },
      [collection]: {
        Values: [true],
        IncludeMissing: false,
      },
      searchable: {
        IncludeMissing: true,
        Values: ['true'],
      },
    },
    locale,
  });
}

export default searchProducts;
