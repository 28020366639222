import {
  REQUIRED_FOR_TYPE,
  SHOPPING_CART_REQUIRED_PROPERTIES,
} from '~source/constants';
import type { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import type { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import type { LocaleLanguageType } from '~source/core/models/unions/locale';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import getProductRequirementValuesForOrder from '~source/core/services/eva/api/order/get-product-requirement-values-for-order';
import type { AvailabilityProduct } from '~source/core/services/eva/api/product/get-product-availability';
import getProductAvailability from '~source/core/services/eva/api/product/get-product-availability';
import transformShoppingCart from '~source/core/transformers/cart/transform-shopping-cart';
import { isBundle } from '~source/ui/utils/checks/is-product-type';

const convertOrderLinesToProducts = (lines: EVA.Core.OrderLineDto[]) => {
  return lines.reduce<AvailabilityProduct[]>((products, line) => {
    if (isBundle([line.Product?.Type]) || !line.ProductID) return products;

    const matchingProduct = products.find(
      (product) => product.ID === line.ProductID,
    );

    // Merge stock products from bundles into one item and update quantity to get a more acurate API response
    if (matchingProduct) {
      const updatedTotal = products.filter(
        (product) => product.ID !== line.ProductID,
      );
      updatedTotal.push({
        ...matchingProduct,
        QuantityRequested: matchingProduct.QuantityRequested + 1,
      });
      return updatedTotal;
    }

    const product = {
      ID: line.ProductID,
      QuantityRequested: line.QuantityOpen,
    };

    return [...products, product];
  }, []);
};

export async function getShoppingCart({
  orderId,
  locale,
}: {
  orderId: number;
  locale: LocaleLanguageType;
}) {
  const response = await getEvaService(Core.GetShoppingCart, {
    OrderID: orderId,
    IncludeAllPayments: true,
    AdditionalOrderDataOptions: {
      RequiredFor: REQUIRED_FOR_TYPE as EVA.Core.RequiredFor,
      ProductProperties: SHOPPING_CART_REQUIRED_PROPERTIES,
      IncludeProductRequirements: false,
      IncludeGiftWrapping: false,
      IncludePrefigureDiscounts: false,
      IncludePickProductOptions: false,
      IncludeAvailableShippingMethods: false,
      IncludeAvailablePaymentMethods: false,
      IncludeAvailableRefundPaymentMethods: false,
      IncludeValidateShipment: false,
      IncludePaymentTransactionActions: false,
      IncludeCheckoutOptions: false,
      IncludeOrganizationUnitData: false,
      IncludeCustomerCustomFields: false,
    },
  });

  if (!response?.ShoppingCart) {
    throw new Error('[getShoppingCart] No cart found');
  }

  const { Lines: orderLines } = response.ShoppingCart;

  let productRequirements: AdditionalPrintings[] | null = null;
  let productAvailability: AvailabilityIndication | null = null;

  if (orderLines.length > 0) {
    productRequirements = await getProductRequirementValuesForOrder({
      orderId,
    });
    productAvailability = await getProductAvailability({
      locale,
      products: convertOrderLinesToProducts(orderLines),
    });
  }

  const shoppingCart = transformShoppingCart({
    response: response.ShoppingCart,
    additionalPrintings: productRequirements,
    availabilityOfProducts: productAvailability,
    locale,
  });

  return shoppingCart;
}
