import {
  OrganizationUnitHub,
  IJoinOrganizationUnitHub,
} from '@springtree/eva-sdk-signalr';
import React from 'react';
import { getEndpoint } from '~source/core/services/eva/api/get-eva-service';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';

const SUB: IJoinOrganizationUnitHub = {
  subscriberName: 'ajax-shop',
};

export const useSignalR = () => {
  const { account } = useAccount();

  const hubRef = React.useRef<OrganizationUnitHub | null>(null);

  const getHub = React.useCallback(async () => {
    if (hubRef.current) return hubRef.current;

    const evaEndpoint = await getEndpoint();

    const organizationUnitHub = new OrganizationUnitHub(
      evaEndpoint,
      account?.evaAuthenticationToken ?? undefined,
    );

    await organizationUnitHub.join(SUB);

    hubRef.current = organizationUnitHub;

    return organizationUnitHub;
  }, [account?.evaAuthenticationToken]);

  const subscribeToOrder = React.useCallback(
    async (orderId: number, onEvent: () => void) => {
      const hub = await getHub();

      hub.setEventHandler('SubscribedOrderUpdated', onEvent);

      await hub.subscribeToOrder(orderId);

      return () => hub.unsubscribeFromOrder(orderId);
    },
    [getHub],
  );

  return {
    subscribeToOrder,
  };
};
