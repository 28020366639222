import { Availability } from '~source/core/models/components/atoms/availability-indication';
import { CartLine } from '~source/core/models/components/atoms/cart-line';

export const isProductAvailable = (availability: Availability) =>
  availability === 'available' || availability === 'available-in-future';

export const bundleProductAvailability = (
  bundleProducts: CartLine[],
): Availability => {
  const mainProduct = bundleProducts.find((product) => !!product.size);
  if (!mainProduct) return 'unavailable';

  return mainProduct.availability;
};
