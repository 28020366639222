import { BundleLineBase } from '~source/core/models/bundle-line-base';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

type AddBundleProductToOrderPayload = {
  orderId: number;
  bundleProductId: number;
  lineActionType: number;
  lines: BundleLineBase[];
};

export const addBundleProductToOrder = async (
  {
    orderId,
    bundleProductId,
    lineActionType,
    lines,
  }: AddBundleProductToOrderPayload,
  authenticationToken?: string | null,
) => {
  return getEvaService(
    Core.AddBundleProductToOrder,
    {
      OrderID: orderId,
      BundleProductID: bundleProductId,
      LineActionType: lineActionType,
      LineSelection: lines.map((line) => ({
        SelectedProductID: line.id,
        ProductBundleLineID: line.productBundleLineId,
        LineSelection: [],
      })),
    },
    {
      authenticationToken: authenticationToken ?? undefined,
    },
  );
};
