import { API_URL } from '~source/constants';
import { ContentPageResponse } from '~source/core/models/response/content-page-response';
import { InitResponse } from '~source/core/models/response/init-response';
import { LanguageResponse } from '~source/core/models/response/language-response';
import {
  HomepageResponse,
  PageResponse,
} from '~source/core/models/response/page-response';
import { ProductOverviewPageResponse } from '~source/core/models/response/product-overview-page';
import { RedirectResponse } from '~source/core/models/response/redirect-response';
import { SuccessPageResponse } from '~source/core/models/response/success-page-response';
import createCacheFunction from '~source/core/utils/create-cache-function';
import { QueryObject } from '~source/ui/utils/urls/url-query';

type GetApiOptions = {
  query?: QueryObject;
};

const cache = createCacheFunction({
  maxSize: 100,
  maxAge: 30,
});

interface Options {
  params?: QueryObject;
}

async function fetchWithTimeout<T>(
  url: string,
  timeout?: number,
  options: Options = {},
) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  const data = (await response.json()) as T;
  return data;
}

export async function getApi<T>(
  endpoint: string,
  { query = {} }: GetApiOptions = {},
) {
  const key = `${endpoint}:${JSON.stringify(query)}`;
  const value = await cache(key, () => {
    const url = `${API_URL}/${endpoint}`;
    return fetchWithTimeout<T>(url, 5000, { params: query });
  });

  return value;
}

type ResponseMapGet = {
  '/': InitResponse;
  '/home': HomepageResponse;
  '/cultures': LanguageResponse[];
  '/checkout/checkout-success': SuccessPageResponse;
  [key: `/redirect?sourceUrl=${string}`]: RedirectResponse;
  [key: `/productoverview/${string}`]: ProductOverviewPageResponse;
  [key: `/contentpage/${string}`]: ContentPageResponse;
};

export async function get<P extends keyof ResponseMapGet>({
  path,
  culture,
}: {
  path: P;
  culture?: string;
}) {
  const cultureParam = culture ? `?culture=${culture}` : '';

  try {
    const res = await getApi<ResponseMapGet[P]>(`${path}${cultureParam}`);
    return res;
  } catch (err) {
    return null;
  }
}
