import { DynamicYieldCartItem } from '~source/core/models/payload/dy-cart-item';

export default function createUniqueDYProductsList(
  products: DynamicYieldCartItem[],
) {
  return products.reduce(
    (
      uniqueList: DynamicYieldCartItem[],
      currentProduct: DynamicYieldCartItem,
    ) => {
      const matchingItem = uniqueList.find(
        (uniqueItem) =>
          uniqueItem.productId === currentProduct.productId &&
          uniqueItem.size === currentProduct.size,
      );
      if (!matchingItem) return [...uniqueList, currentProduct];
      const listWithoutMatchingItem = uniqueList.filter(
        (uniqueItem) => uniqueItem.productId !== currentProduct.productId,
      );
      return [
        ...listWithoutMatchingItem,
        {
          ...matchingItem,
          quantity: matchingItem.quantity + currentProduct.quantity,
        },
      ];
    },
    [],
  );
}
