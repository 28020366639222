import React from 'react';
import { AjaxAccount } from '~source/core/models/account';
import useTimer from '~source/ui/hooks/helper/useTimer/useTimer';

export const AUTHENTICATION_TOKEN_DURATION = 24 * 60 * 60 * 1000 * 0.95; // 95% of a day.

function useReloadAccount(account: AjaxAccount | null) {
  const [shouldReload, setShouldReload] = React.useState(false);

  const handleReload = React.useCallback(() => setShouldReload(true), []);

  const timer = useTimer(AUTHENTICATION_TOKEN_DURATION, handleReload);

  React.useEffect(() => {
    if (account?.evaAuthenticationToken) {
      timer.start();
      return () => timer.stop();
    }

    return undefined;
  }, [account?.evaAuthenticationToken, timer]);

  return shouldReload;
}

export default useReloadAccount;
