export default function parseNumber(
  number: string | null | undefined,
  int = false,
) {
  if (number == null) return null;
  const trimmedNumber = number.trim();
  if (trimmedNumber === '') return null;
  const value = int ? parseInt(trimmedNumber, 10) : parseFloat(trimmedNumber);
  if (Number.isNaN(value)) return null;
  return value;
}
