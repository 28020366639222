import { AjaxAddress } from '~source/core/models/components/atoms/address';

function isNotEmpty(value: string | null | undefined): boolean {
  if (value == null) return false;
  if (value.trim() === '') return false;
  return true;
}

function isValidValue(value: string | null | undefined): boolean {
  const trimmedValue = value?.trim() ?? null;
  if (trimmedValue == null) return false;
  if (trimmedValue === '0') return false;
  if (trimmedValue.toLowerCase() === 'leeg') return false;

  return true;
}

export function transformAddress(
  data: EVA.Core.AddressDto | undefined | null,
): AjaxAddress | null {
  if (!data) return null;
  if (!isValidValue(data.Address1)) return null;
  if (!isNotEmpty(data.Address1)) return null;
  if (!isValidValue(data.ZipCode)) return null;
  if (!isNotEmpty(data.ZipCode)) return null;
  if (!isValidValue(data.City)) return null;
  if (!isNotEmpty(data.City)) return null;

  return {
    type: 'ajax-address',
    addressId: data.ID,
    firstName: data.FirstName || null,
    lastName: data.LastName || null,
    address1: data.Address1 || null,
    address2: data.Address2 || null,
    houseNumber: data.HouseNumber || null,
    zipCode: data.ZipCode || null,
    city: data.City || null,
    countryId: data.CountryID || null,
    region: data.Region || null,
  };
}
