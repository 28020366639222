import { ProductsList } from '~source/core/models/components/organisms/products-list';
import {
  ProductsCarouselResponse,
  ProductsMiniGridResponse,
} from '~source/core/models/response/products-list-response';
import getProducts from '~source/core/services/eva/api/get-products';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import uuid from '~source/core/utils/uuid';

async function fetchEvaProducts(ids: number[]) {
  try {
    const response = await getProducts(ids);
    return response;
  } catch {
    return null;
  }
}

export default async function transformProductsList(
  response: ProductsCarouselResponse | ProductsMiniGridResponse,
): Promise<ProductsList> {
  const { content, component } = response;
  const { products: productIds, text } = content;

  const products = await fetchEvaProducts(productIds);

  const moduleType =
    component === 'ProductSliderComponent'
      ? 'productsCarousel'
      : 'productsMiniGrid';

  const productComponent =
    component === 'ProductSliderComponent' ? 'Slider' : 'Grid';

  return {
    id: uuid(),
    moduleType,
    products: products ? products.filter((product) => product != null) : [],
    title: addFallbackValue(text, `Product ${productComponent} title`, ''),
  };
}
