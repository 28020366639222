import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

interface Options {
  orderId: number;
  evaId: number;
  authenticationToken: string;
}

export async function attachOrderToAccount({
  orderId,
  evaId,
  authenticationToken,
}: Options) {
  await getEvaService(
    Core.AttachCustomerToOrder,
    { OrderID: orderId, UserID: evaId },
    { authenticationToken },
  );
}

export default attachOrderToAccount;
