import {
  Category,
  FooterLinks,
  PartnersAndSponsors,
} from '~source/core/models/components/molecules/footer';
import {
  CategoryResponse,
  FooterResponse,
} from '~source/core/models/response/footer-response';
import { ImageLinkResponse } from '~source/core/models/response/image-link-response';
import transformIcon from '~source/core/transformers/atoms/transform-icon';
import transformImage from '~source/core/transformers/atoms/transform-image';
import transformLink from '~source/core/transformers/atoms/transform-link';
import addFallbackValue from '~source/core/utils/add-fallback-value';

function transformCategory(
  category: CategoryResponse,
  index: number,
): Category {
  return {
    id: index,
    title: category.menuName,
    links: category.submenu.map((link, i) => transformLink(link, i.toString())),
  };
}

function transformPartnersAndSponsors({
  partners,
  sponsors,
}: {
  partners: ImageLinkResponse[];
  sponsors: ImageLinkResponse[];
}): PartnersAndSponsors {
  const transformImgLinkResponse = (data: ImageLinkResponse[]) =>
    data.map((item) => transformIcon(item));
  return {
    partners: transformImgLinkResponse(partners) ?? [],
    sponsors: transformImgLinkResponse(sponsors) ?? [],
  };
}

export default function transformFooterData({
  categories,
  copyright,
  footerLogo,
  newsletter,
  partners,
  sponsors,
  socials,
}: FooterResponse): FooterLinks {
  const firstNewsletterItem = addFallbackValue(
    newsletter?.[0],
    'Footer newsletter',
    null,
  );
  const transformedCategories = categories.map((category, index) =>
    transformCategory(category, index),
  );
  return {
    categories: addFallbackValue(
      transformedCategories,
      'Footer categories',
      [],
    ),
    copyright: addFallbackValue(copyright, 'Footer copyright', ''),
    footerImage: transformImage(footerLogo),
    newsletter: firstNewsletterItem
      ? {
          text: firstNewsletterItem.instructions,
          link: transformLink(firstNewsletterItem.link),
        }
      : null,
    partnersSponsors: transformPartnersAndSponsors({ partners, sponsors }),
    socials: socials ? socials.map((social) => transformIcon(social)) : null,
  };
}
