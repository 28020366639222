import * as Msal from '@azure/msal-browser';
import * as Sentry from '@sentry/nextjs';
import React from 'react';
import * as AttachOrderToAccountService from '~source/core/services/eva/api/order/attach-order-to-account';
import * as CreateOrderService from '~source/core/services/eva/api/order/create-order';
import {
  ORDER_ID_LOCAL_STORAGE_KEY,
  ORDER_STATUS_LOCAL_STORAGE_KEY,
  PAYMENT_TIMESTAMP_START_LOCAL_STORAGE_KEY,
} from '~source/core/services/local-storage/cart';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import { useMsal } from '~source/ui/hooks/auth/useMsal';
import usePersistedState from '~source/ui/hooks/helper/usePersistedState/usePersistedState';

export type OrderStatus = 'unset' | 'pending' | 'payment' | 'completed';

export type PaymentTimestamp = number | null;

type OrderContextType = {
  orderId: number | null;
  orderStatus: OrderStatus | null;
  getOrder: () => Promise<number>;
  clearOrder: () => void;
  updateOrder: (args: { status?: OrderStatus }) => void;
  paymentTimestamp: PaymentTimestamp;
  setPaymentTimestamp: (timestamp: PaymentTimestamp) => void;
};

const OrderContext = React.createContext<OrderContextType | null>(null);

export function useOrder(): OrderContextType {
  const context = React.useContext(OrderContext);
  if (!context) throw Error('[useOrder] No context found');
  return context;
}
export const OrderProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { msalApp } = useMsal();
  const { account } = useAccount();

  const [orderId, _setOrderId] = usePersistedState<number | null>(
    ORDER_ID_LOCAL_STORAGE_KEY,
    (stored) => stored || null,
  );
  const [orderStatus, setOrderStatus] = usePersistedState<OrderStatus | null>(
    ORDER_STATUS_LOCAL_STORAGE_KEY,
    (stored) => stored || 'unset',
  );

  const [paymentTimestamp, setPaymentTimestamp] =
    usePersistedState<PaymentTimestamp>(
      PAYMENT_TIMESTAMP_START_LOCAL_STORAGE_KEY,
      (stored) => stored || null,
    );

  // Store order ID in ref, so it can be accessed without requiring a re-render first
  const orderIdRef = React.useRef(orderId);
  React.useEffect(() => {
    orderIdRef.current = orderId;
  }, [orderId]);

  const setOrderId = React.useCallback(
    (value: number | null) => {
      orderIdRef.current = value;
      _setOrderId(value);
    },
    [_setOrderId],
  );

  const createOrder = React.useCallback(async () => {
    const createdOrderId = await CreateOrderService.createOrder({
      authenticationToken: account?.evaAuthenticationToken ?? null,
    });
    if (!createdOrderId) {
      throw Error('[useOrder/createOrder] Could not create new order');
    }

    setOrderId(createdOrderId);
    setOrderStatus('pending');

    return createdOrderId;
  }, [account?.evaAuthenticationToken, setOrderId, setOrderStatus]);

  const updateOrder = React.useCallback(
    ({ id, status }: { id?: number; status?: OrderStatus }) => {
      if (id !== undefined) {
        setOrderId(id);
      }

      if (status !== undefined) {
        setOrderStatus(status);
      }

      // Update payment timestamp
      if (status === 'payment') {
        setPaymentTimestamp(Date.now());
      }
    },
    [setOrderId, setOrderStatus, setPaymentTimestamp],
  );

  const clearOrder = React.useCallback(() => {
    setOrderId(null);
    setOrderStatus('unset');
    setPaymentTimestamp(null);
  }, [setOrderId, setOrderStatus, setPaymentTimestamp]);

  const getOrder = React.useCallback(async () => {
    // Just return current order ID when it already exists
    if (orderIdRef.current) return orderIdRef.current;

    const createdOrderId = await createOrder();
    return createdOrderId;
  }, [createOrder]);

  /** When there is an online account, attach the order to the account */
  React.useEffect(() => {
    if (
      orderId &&
      account &&
      account.type === 'ajax-account' &&
      account.evaUserId &&
      account.evaAuthenticationToken &&
      // TODO: Do we only need to do this for employee accounts?
      account.isEmployee
    ) {
      AttachOrderToAccountService.attachOrderToAccount({
        orderId,
        evaId: account.evaUserId,
        authenticationToken: account.evaAuthenticationToken,
      }).catch((error) => {
        Sentry.captureException(error);

        clearOrder();
      });
    }
  }, [account, orderId, clearOrder]);

  /** Listen to msal events */
  React.useEffect(() => {
    const callbackId = msalApp?.addEventCallback((message) => {
      // Clear order connected to guest account, since it can't be reused for an online account
      if (
        account &&
        account.type === 'ajax-guest-account' &&
        account.evaAuthenticationToken &&
        message.eventType === Msal.EventType.LOGIN_START
      ) {
        clearOrder();
      }

      // Clear the order when logging out, because it can't be reused afterwards
      if (message.eventType === Msal.EventType.LOGOUT_START) {
        clearOrder();
      }
    });

    return () => {
      if (callbackId) msalApp?.removeEventCallback(callbackId);
      msalApp?.disableAccountStorageEvents();
    };
  }, [msalApp, account, clearOrder]);

  const value = React.useMemo(
    () => ({
      orderId,
      orderStatus,
      getOrder,
      clearOrder,
      updateOrder,
      paymentTimestamp,
      setPaymentTimestamp,
    }),
    [
      orderId,
      orderStatus,
      getOrder,
      clearOrder,
      updateOrder,
      paymentTimestamp,
      setPaymentTimestamp,
    ],
  );

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};
