/* eslint-disable react/button-has-type */
import cx from 'classnames';
import Link from 'next/link';
import * as React from 'react';
import BaseButton from '~source/ui/components/atoms/buttons/base-button/base-button';
import AjaxLink from '~source/ui/components/atoms/Link/link';
import $ from './button.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  variant?:
    | 'red'
    | 'light-grey'
    | 'white'
    | 'grey'
    | 'black'
    | 'outline'
    | 'outline-red'
    | 'outline-light-grey'
    | 'transparent-black'
    | 'transparent-red';
  disabled?: boolean;
  fontSize?: 'small' | 'extra-small';
  fontWeight?: 'normal';
  isExternal?: boolean;
  link?: string;
  label?: string;
  // eslint-disable-next-line no-unused-vars
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ref?: React.ForwardedRef<HTMLButtonElement | null>;
  type?: 'button' | 'submit' | 'reset' | 'link';
  icon?: string;
  ariaLabelledby?: string;
  id?: string;
};

const Button: React.FunctionComponent<Props> = React.forwardRef(
  (props, ref) => {
    const {
      children,
      className,
      variant,
      disabled,
      label,
      onClick,
      type,
      link,
      isExternal,
      fontSize,
      fontWeight,
      icon,
      ariaLabelledby,
      id,
    } = props;

    const renderChildren = React.useCallback(() => {
      if (icon) {
        return (
          <>
            <img
              className={$.icon}
              src={`/shop/images/icons/ic-${icon}.svg`}
              alt=""
            />
            {label || children}
          </>
        );
      }
      if (label) {
        return label;
      }
      if (children) {
        return children;
      }
      return 'Button';
    }, [icon, label, children]);

    const buttonClassName = cx(
      $.button,
      variant === 'red' && $.red,
      variant === 'light-grey' && $.lightGrey,
      variant === 'grey' && $.grey,
      variant === 'black' && $.black,
      variant === 'outline' && $.outline,
      variant === 'outline-red' && $.outlineRed,
      variant === 'outline-light-grey' && $.outlineLightGrey,
      variant === 'transparent-black' && $.transparentBlack,
      variant === 'transparent-red' && $.transparentRed,
      fontSize === 'small' && $.fontSizeSmall,
      fontSize === 'extra-small' && $.fontSizeExtraSmall,
      fontWeight === 'normal' && $.fontWeightNormal,
      className,
      icon && $.iconButton,
    );

    if (type === 'link') {
      if (!link) return null;

      return (
        <AjaxLink
          className={buttonClassName}
          link={link}
          isExternal={isExternal}
        >
          {renderChildren()}
        </AjaxLink>
      );
    }

    const button = (
      <BaseButton
        className={buttonClassName}
        onClick={onClick}
        type={type || 'button'}
        disabled={disabled}
        ref={ref}
        id={id}
        {...(ariaLabelledby && { 'aria-labelledby': ariaLabelledby })}
      >
        {renderChildren()}
      </BaseButton>
    );

    if (link) {
      return (
        <Link
          href={link || '/'}
          style={disabled ? { pointerEvents: 'none' } : undefined}
          aria-disabled={disabled}
        >
          {button}
        </Link>
      );
    }

    return button;
  },
);

export default Button;
