import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef, useState } from 'react';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';
import useOnClickOutside from '~source/ui/hooks/ui/useOnClickOutside/useOnClickOutside';

export const useMobileMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const mobileMenuRef = useRef<HTMLDivElement | null>(null);
  const { isLaptop } = useBreakpoints();

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
    mobileMenuRef.current && disableBodyScroll(mobileMenuRef.current);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    mobileMenuRef.current && enableBodyScroll(mobileMenuRef.current);
  };

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen) {
      closeMobileMenu();
      return;
    }
    openMobileMenu();
  };

  useOnClickOutside(mobileMenuRef, (e) => {
    if (
      mobileMenuButtonRef.current?.contains(e.target as Node) &&
      isMobileMenuOpen
    )
      return;

    closeMobileMenu();
  });

  useEffect(() => {
    if (isLaptop && isMobileMenuOpen) closeMobileMenu();
  }, [isLaptop, isMobileMenuOpen]);

  return {
    isMobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
    toggleMobileMenu,
    mobileMenuRef,
    mobileMenuButtonRef,
  };
};
