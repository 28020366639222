import * as msal from '@azure/msal-browser';
import {
  msalConfig,
  tokenRequest,
  loginRequest,
} from '~source/core/services/msal/config';
import { b2cPolicies } from '~source/core/services/msal/policies';

const globalMsalApp =
  typeof window === 'undefined'
    ? null
    : new msal.PublicClientApplication(msalConfig);

export async function getMsalApp() {
  if (!globalMsalApp) throw Error('[getMsalApp] globalMsalApp not found');
  await globalMsalApp.initialize();
  return globalMsalApp;
}

export function accountInformationIsEmployee(
  information: msal.AuthenticationResult,
) {
  return (information.idTokenClaims as any)?.idp === 'AjaxAD';
}

/**
 * See here for more info on account retrieval:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
 */
export async function acquireTokenSilentMsal() {
  const msalApp = await getMsalApp();
  await msalApp.handleRedirectPromise();

  const account = msalApp.getAllAccounts().at(0);
  if (!account) return null;

  const request: msal.SilentRequest = {
    ...tokenRequest,
    account,
  };

  try {
    const response = await msalApp.acquireTokenSilent(request);
    return response;
  } catch (error: any) {
    // Acquire token silent failure, and send an interactive request
    if (error?.errorMessage?.indexOf('interaction_required') !== -1) {
      const response = await msalApp.acquireTokenPopup(request);
      return response;
    }

    throw error;
  }
}

export async function ssoSilentMsal({ loginHint }: { loginHint: string }) {
  try {
    const msalApp = await getMsalApp();
    await msalApp.handleRedirectPromise();

    const request: msal.SsoSilentRequest = {
      ...loginRequest,
      loginHint,
    };

    return await msalApp.ssoSilent(request);
  } catch {
    return null;
  }
}

/**
 * We need to reject id tokens that were not issued with the default sign-in policy.
 * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
 */
async function handlePolicyChange(response: msal.AuthenticationResult) {
  const msalApp = await getMsalApp();

  const claims = response.idTokenClaims as Record<string, unknown> | undefined;

  if (claims?.acr === b2cPolicies.names.forgotPassword) {
    await msalApp.logoutRedirect();
  }
}

/**
 * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
 */
export async function loginMsal() {
  const msalApp = await getMsalApp();

  try {
    await msalApp.loginRedirect(loginRequest);
  } catch (error: any) {
    if (error?.errorMessage) {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage?.indexOf('AADB2C90118') > -1) {
        msalApp
          .loginRedirect({
            ...loginRequest,
            ...b2cPolicies.authorities.forgotPassword,
          })
          .then((response: any) => handlePolicyChange(response));
      }
    }
  }
}

export async function loginSilentMsal() {
  const msalApp = await getMsalApp();

  const account = msalApp.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: ['user.read'],
    account,
  };

  try {
    return await msalApp.acquireTokenSilent(accessTokenRequest);
  } catch {
    return null;
  }
}

export async function signUpMsal() {
  const msalApp = await getMsalApp();

  return msalApp
    .loginRedirect({
      ...loginRequest,
      ...b2cPolicies.authorities.signUp,
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function logoutMsal() {
  const msalApp = await getMsalApp();
  await msalApp.logoutRedirect();
}
