import { Core } from '@springtree/eva-services-core';
import { ApplicationConfiguration } from '~source/core/models/application-configuration';
import getEvaService from '~source/core/services/eva/api/get-eva-service';
import transformApplicationConfiguration from '~source/core/transformers/api/transform-application-configuration';
import createCachedFunction from '~source/core/utils/create-cached-function';

const getApplicationConfiguration = createCachedFunction(
  async (): Promise<ApplicationConfiguration | null> => {
    const response = await getEvaService(Core.GetApplicationConfiguration);
    const transformedResponse =
      (response && transformApplicationConfiguration(response)) || null;

    return transformedResponse;
  },
  {
    maxAge: 5 * 60 * 1000, // five minutes in ms.
  },
);

export default getApplicationConfiguration;
