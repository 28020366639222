import { Core } from '@springtree/eva-services-core';
import { GET_PRODUCTS_REQUIRED_FIELDS } from '~source/constants';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import transformGetProducts from '~source/core/transformers/product/transform-get-products';
import getEvaService from './get-eva-service';

export default async function getProducts(
  ids: number[],
): Promise<ProductTeaser[] | null> {
  const response = await getEvaService(Core.GetProducts, {
    ProductIDs: ids,
    IncludedFields: GET_PRODUCTS_REQUIRED_FIELDS,
  });

  const transformedResponse =
    (response && transformGetProducts(response)) || null;

  return transformedResponse;
}
