import { throttle } from 'lodash';
import * as React from 'react';

type NaviagtionContextType = {
  isAtTop: boolean;
  isTakeoverBanner: boolean;
  setIsTakeoverBanner: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavigationContext = React.createContext<NaviagtionContextType | null>(
  null,
);

export const useNavigation = () => {
  const context = React.useContext(NavigationContext);
  if (!context) throw new Error('[useNavigation] No context found');
  return context;
};

export const NavigationContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [isTakeoverBanner, setIsTakeoverBanner] = React.useState(false);

  React.useEffect(() => {
    if (!isTakeoverBanner) return undefined;

    const handleScroll = throttle(() => {
      if (window.scrollY > 30) return setIsAtTop(false);
      return setIsAtTop(true);
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isTakeoverBanner]);

  const contextValue = React.useMemo<NaviagtionContextType>(
    () => ({
      isTakeoverBanner,
      isAtTop,
      setIsTakeoverBanner,
    }),
    [isTakeoverBanner, isAtTop, setIsTakeoverBanner],
  );

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};
