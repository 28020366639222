import { Price } from '~source/core/models/components/atoms/price';

export default function calculateNewTotalPrice(
  price: Price,
  oldQuantity: number,
  newQuantity: number,
): Price {
  return {
    sale: price.sale !== null ? (price.sale / oldQuantity) * newQuantity : null,
    original:
      price.original !== null
        ? (price.original / oldQuantity) * newQuantity
        : null,
  };
}
