import { BannerImage } from '~source/core/models/components/atoms/banner-image';
import {
  TitleFont,
  TitleSize,
} from '~source/core/models/components/molecules/banner';
import { PlayerBanner } from '~source/core/models/components/organisms/player-banner';
import { BannerImageResponse } from '~source/core/models/response/banner-image-response';
import { PlayerBannerResponse } from '~source/core/models/response/banner-response';
import transformBannerImage from '~source/core/transformers/atoms/transform-banner-image';
import transformButton from '~source/core/transformers/atoms/transform-button';
import uuid from '~source/core/utils/uuid';

export default async function transformPlayerBanner(
  response: PlayerBannerResponse,
): Promise<PlayerBanner> {
  const {
    content: {
      bannerImages,
      buttons,
      titleFont: font,
      titleWeight,
      titleFontColor,
      name,
      jerseyNumber,
      position,
      description,
      playerStatistics,
    },
  } = response;

  const images = bannerImages.reduce(
    (acc: BannerImage[], image: BannerImageResponse) => {
      if (!image.bannerDevice) return acc;
      const transformedImage = transformBannerImage(image);
      return [...acc, transformedImage];
    },
    [],
  );
  const links = buttons.map((button) => transformButton(button));
  const titleFont = font.toLowerCase() as TitleFont;
  const titleSize = titleWeight.toLowerCase() as TitleSize;
  const playerName = name || '';

  return {
    id: uuid(),
    moduleType: 'playerBanner',
    images,
    links,
    titleFont,
    titleSize,
    titleFontColor,
    playerName,
    jerseyNumber,
    position,
    description,
    playerStatistics,
  };
}
