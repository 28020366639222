import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export const addDiscountCouponToOrder = async (
  {
    orderId,
    couponCode,
  }: {
    orderId: number;
    couponCode: string;
  },
  authenticationToken?: string | null,
) => {
  return getEvaService(
    Core.AddDiscountCouponToOrder,
    {
      OrderID: orderId,
      CouponCode: couponCode,
    },
    { authenticationToken: authenticationToken ?? undefined },
  );
};
