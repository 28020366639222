import {
  BannersCarousel,
  CarouselBanner,
  CarouselItemSize,
} from '~source/core/models/components/molecules/banners-carousel';
import { BannersCarouselResponse } from '~source/core/models/response/banners-carousel-response';
import transformBanner from '~source/core/transformers/molecules/transform-banner';
import uuid from '~source/core/utils/uuid';

export default async function transformBannersCarousel(
  response: BannersCarouselResponse,
): Promise<BannersCarousel> {
  const { content } = response;
  const { banners } = content;

  const newBanners: CarouselBanner[] = banners.map((banner, index) => {
    const id = `${index}`;
    const {
      images,
      price,
      title,
      text,
      links,
      isDark,
      titleFont,
      titleSize,
      moduleType,
    } = transformBanner(banner);

    const width = banner.width.toLowerCase();

    let tileSize: CarouselItemSize = 12;
    switch (width) {
      case 'full_width':
        tileSize = 12;
        break;
      case 'xxxl':
        tileSize = 11;
        break;
      case 'xxl':
        tileSize = 10;
        break;
      case 'xl':
        tileSize = 9;
        break;
      case 'l':
        tileSize = 8;
        break;
      case 'm':
        tileSize = 7;
        break;
      case 's':
        tileSize = 6;
        break;
      case 'xs':
        tileSize = 5;
        break;
      case 'xxs':
        tileSize = 4;
        break;
      case 'xxxs':
        tileSize = 3;
        break;
      default:
    }

    return {
      id,
      moduleType,
      images,
      isDark,
      links,
      price,
      title,
      text,
      titleFont,
      titleSize,
      tileSize,
    };
  });

  return {
    id: uuid(),
    moduleType: 'bannersCarousel',
    banners: newBanners,
  };
}
