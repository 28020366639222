import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useRef, useState } from 'react';

export const useDropdown = () => {
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [dropdownHeight, setDropdownHeight] = useState<number | null>(null);
  const backdropRef = useRef<HTMLDivElement | null>(null);

  const openDropdown = (id: string) => {
    setActiveDropdownId(id);
    backdropRef.current && disableBodyScroll(backdropRef.current);
  };

  const closeDropdown = () => {
    setActiveDropdownId(null);
    backdropRef.current && enableBodyScroll(backdropRef.current);
  };

  const toggleDropdown = (id: string) => {
    if (id === activeDropdownId) {
      closeDropdown();
      return;
    }
    openDropdown(id);
  };

  const updateDropdownHeight = (height: number) => {
    if (dropdownHeight === null || height > dropdownHeight) {
      setDropdownHeight(height);
    }
  };

  return {
    activeDropdownId,
    dropdownHeight,
    openDropdown,
    closeDropdown,
    toggleDropdown,
    updateDropdownHeight,
    backdropRef,
  };
};
