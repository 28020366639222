export default function focus(el: HTMLElement) {
  // Calling focus on an element that the user has not clicked on on IOS does not work
  // This is a workaround to enforce such focussing on an element and opening the users keyboard
  // Source: https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '0';
  fakeInput.style.fontSize = '16px'; // disable auto zoom
  document.body.insertBefore(fakeInput, document.body.childNodes[0]);
  fakeInput.focus();

  setTimeout(() => {
    el.focus();
    fakeInput.remove();
  }, 1000);
}
