import {
  CartLine,
  CartLineWithBundleProducts,
} from '~source/core/models/components/atoms/cart-line';
import { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';
import { DynamicYieldCartItem } from '~source/core/models/payload/dy-cart-item';
import createUniqueDYProductsList from '~source/ui/utils/dynamic-yield/create-unique-dy-products-list';

function transformCartLineToDYFormat(line: CartLine): DynamicYieldCartItem {
  const { backendId, quantityOpen, price } = line;
  const priceValue = price.sale ?? price.original;
  return {
    productId: backendId || '',
    quantity: quantityOpen,
    itemPrice: priceValue ? priceValue / quantityOpen : 0,
    size: line.size || null,
  };
}

export default function transformCartToDYFormat(shoppingCart: ShoppingCart) {
  const stockProducts = shoppingCart.lines.reduce(
    (lines: CartLineWithBundleProducts[], line: CartLineWithBundleProducts) => {
      if (!line.bundleProducts) return [...lines, line];
      const mainStockProduct = line.bundleProducts.find(
        (bundleProduct) => !!bundleProduct.size,
      );
      if (!mainStockProduct) return lines;
      return [...lines, mainStockProduct];
    },
    [],
  );
  const dynamicYieldProducts = stockProducts.map((line) =>
    transformCartLineToDYFormat(line),
  );

  return createUniqueDYProductsList(dynamicYieldProducts);
}
