// Values of EVA.Core.OrderLinetype
export const OrderLineTypes = {
  NormalProduct: 0,
  Discount: 1,
  ExtraCosts: 2,
  ShippingCosts: 5,
  ReturnCosts: 6,
  PriceCorrection: 7,
  Service: 8,
  GiftWrappingCosts: 9,
  DownPayment: 10,
};

export const EVA_DEFAULT_PRODUCT_PROPERTIES = [
  'backend_id',
  'barcodes',
  'categorie',
  'clothing_primary_color',
  'clothing_size',
  'config_contains_no_name',
  'configurable_properties',
  'currency_id',
  'custom_id',
  'display_price',
  'display_value',
  'hoofd_categorie',
  'is_sellable',
  'item',
  'logical_level',
  'max_print_length',
  'ophalen_fanshop',
  'original_price',
  'primary_image.blob',
  'printing',
  'product_description',
  'product_id',
  'product_media',
  'product_name',
  'product_requirements',
  'product_review',
  'product_status',
  'product_statuses',
  'product_types',
  'recommended_retail_price',
  'related_product_grid',
  'related_product_slider',
  'shirt_type',
  'short_description',
  'short_product_description',
  'size',
  'unstackable',
  'verzend_retour',
];
