import { format, getUnixTime, parse } from 'date-fns';
import {
  AjaxAccount,
  AjaxGuestAccount,
  AjaxUserAccount,
} from '~source/core/models/account';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import getEvaService, {
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { assert } from '~source/core/utils/validators';
import { isValidDate } from '~source/ui/utils/checks/is-valid';

export function createGuestAccountPayload(
  accountInfo: Partial<AjaxGuestAccount>,
) {
  const parsedDate =
    accountInfo.dateOfBirth && isValidDate(accountInfo.dateOfBirth)
      ? parse(
          `${accountInfo.dateOfBirth} Z`,
          'dd/MM/yyyy X',
          new Date(),
        ).toISOString()
      : undefined;

  return {
    FirstName: accountInfo.firstName ?? undefined,
    LastName: accountInfo.lastName ?? undefined,
    PhoneNumber: accountInfo.phoneNumber ?? undefined,
    EmailAddress: accountInfo.email ?? undefined,
    DateOfBirth: parsedDate,
    CountryId: accountInfo.countryId ?? undefined,
    LanguageID: accountInfo.languageId ?? undefined,
  };
}

export async function getGuestAccount({
  authenticationToken: authenticationTokenFromProps,
  signal,
}: {
  authenticationToken: string | null;
  signal?: AbortSignal;
}) {
  const response = await getEvaService(Core.GetCurrentUser, {
    authenticationToken: authenticationTokenFromProps,
    signal,
  });
  return response;
}

export async function createGuestAccount({
  orderId,
  payload,
  signal,
}: {
  orderId: number | null;
  payload: Partial<AjaxGuestAccount>;
  signal?: AbortSignal;
}): Promise<AjaxGuestAccount> {
  const response = await getEvaService(
    Core.CreateCustomer,
    {
      User: createGuestAccountPayload(payload),
      AutoLogin: true,
      AccountType: 2,
      AttachToOrder: true,
      OrderID: orderId ?? undefined,
    },
    { signal },
  );

  assert(response?.User, "'User' not found in response");

  const {
    ID: evaUserId,
    AuthenticationToken: evaAuthenticationToken,
    FirstName,
    LastName,
    EmailAddress,
    PhoneNumber,
    DateOfBirth,
    CountryID,
    LanguageID,
  } = response.User;

  assert(evaUserId, "'User.ID' not found");
  assert(evaAuthenticationToken, "'User.AuthenticationToken' not found");
  assert(FirstName, "'User.FirstName' not found");
  assert(LastName, "'User.LastName' not found");
  assert(EmailAddress, "'User.EmailAddress' not found");

  const phoneNumber = addFallbackValue(PhoneNumber, 'User PhoneNumber', '');
  const dateOfBirth = addFallbackValue(DateOfBirth, 'User DateOfBirth', '');
  const countryId = addFallbackValue(CountryID, 'User CountryID', null);
  const languageId = addFallbackValue(LanguageID, 'User LanguageID', null);

  return {
    type: 'ajax-guest-account',
    firstName: FirstName,
    lastName: LastName,
    email: EmailAddress,
    phoneNumber,
    dateOfBirth: dateOfBirth
      ? format(new Date(dateOfBirth), 'dd/MM/yyyy')
      : dateOfBirth,
    orderId,
    countryId,
    languageId,
    evaUserId,
    evaAuthenticationToken,
    creationDate: getUnixTime(new Date()),
  };
}

export async function loginUserAccount({
  idToken,
  providerId,
  isEmployee,
  signal,
}: {
  idToken: string;
  providerId?: number;
  isEmployee?: boolean;
  signal?: AbortSignal;
}): Promise<AjaxUserAccount> {
  const response = await getEvaService(
    Core.Login,
    {
      AsEmployee: isEmployee ?? false,
      SelectFirstOrganizationUnit: true,
      CustomAuthenticatorType: 'OpenID',
      CustomAuthenticateData: {
        id_token: idToken,
        provider: providerId,
      },
    },
    { abortSignal: signal },
  );

  assert(response?.User, "'User' not found in response");

  const {
    ID: evaUserId,
    AuthenticationToken: evaAuthenticationToken,
    EmailAddress: email,
    FirstName: firstName,
    LastName: lastName,
    PhoneNumber: phoneNumber,
  } = response.User;

  assert(evaUserId, "'User.ID' not found");
  assert(evaAuthenticationToken, "'User.AuthenticationToken' not found");
  assert(email, "'User.EmailAddress' not found");

  return {
    type: 'ajax-account',
    isEmployee: isEmployee ?? false,
    firstName,
    lastName,
    email,
    phoneNumber,
    evaUserId,
    evaAuthenticationToken,
  };
}

export async function updateAccount({
  account,
  locale,
}: {
  account: AjaxAccount;
  locale?: LocaleLanguageType;
}) {
  if (account.type === 'ajax-guest-account') {
    if (!account.evaUserId) {
      throw new Error('[updateAccount] Guest account has no evaUserId');
    }

    return getEvaService(
      Core.UpdateUser,
      {
        ID: account.evaUserId,
        ...createGuestAccountPayload(account),
      },
      {
        authenticationToken: account.evaAuthenticationToken ?? undefined,
      },
    );
  }

  const [languageId, countryId] = locale?.split('-') ?? [];
  const { phoneNumber, evaUserId, firstName, lastName, email } = account;

  return getEvaService(
    Core.UpdateUser,
    {
      ID: evaUserId,
      FirstName: firstName || undefined,
      LastName: lastName || undefined,
      PhoneNumber: phoneNumber || undefined,
      EmailAddress: email || undefined,
      LanguageID: languageId,
      CountryID: countryId,
    },
    { authenticationToken: account.evaAuthenticationToken },
  );
}
