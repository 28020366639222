import { AjaxGuestAccount, AjaxUserAccount } from '~source/core/models/account';
import * as localStorage from '~source/core/services/local-storage/local-storage';

export const USER_ACCOUNT_LOCAL_STORAGE_KEY = 'ajax.userAccount';
export const GUEST_ACCOUNT_LOCAL_STORAGE_KEY = 'ajax.guestAccount';

export function setUserAccountLocally(account: AjaxUserAccount | null) {
  localStorage.setItem(USER_ACCOUNT_LOCAL_STORAGE_KEY, account);

  const event = new StorageEvent('storage', {
    key: USER_ACCOUNT_LOCAL_STORAGE_KEY,
  });
  window.dispatchEvent(event);
}

export function getLocalUserAccount() {
  return localStorage.getItem<AjaxUserAccount | null>(
    USER_ACCOUNT_LOCAL_STORAGE_KEY,
  );
}

export function setGuestAccountLocally(account: AjaxGuestAccount | null) {
  localStorage.setItem(GUEST_ACCOUNT_LOCAL_STORAGE_KEY, account);

  const event = new StorageEvent('storage', {
    key: GUEST_ACCOUNT_LOCAL_STORAGE_KEY,
  });
  window.dispatchEvent(event);
}

export function getLocalGuestAccount() {
  return localStorage.getItem<AjaxGuestAccount | null>(
    GUEST_ACCOUNT_LOCAL_STORAGE_KEY,
  );
}
