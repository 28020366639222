import { COUNTRIES_WITHOUT_ZIPCODE } from '~source/constants';
import {
  Address,
  AjaxAddress,
} from '~source/core/models/components/atoms/address';
import {
  Core,
  getEvaService,
} from '~source/core/services/eva/api/get-eva-service';
import { transformAjaxUserAccountAddressArray } from '~source/core/transformers/address/transform-ajax-account-address';
import { assert, assertString } from '~source/core/utils/validators';
import isDutchAddress from '~source/ui/utils/checks/is-dutch-address';

function createPayload(address: Address) {
  return {
    FirstName: address.firstName || undefined,
    LastName: address.lastName || undefined,
    Address1: address.address1 || undefined,
    Address2: address.address2 || undefined,
    HouseNumber: address.houseNumber || undefined,
    ZipCode: address.zipCode || undefined,
    City: address.city || undefined,
    CountryID: address.countryId || undefined,
  };
}

export async function getAccountAddresses({
  userId,
  authenticationToken,
  signal,
}: {
  userId: number;
  authenticationToken: string | undefined;
  signal?: AbortSignal;
}): Promise<AjaxAddress[] | null> {
  const response = await getEvaService(
    Core.ListAddressBook,
    {
      PageConfig: {
        Filter: {
          UserID: userId,
        },
      },
    },
    { authenticationToken, signal },
  );

  if (response?.Result.Page) {
    return transformAjaxUserAccountAddressArray(response.Result.Page);
  }

  return null;
}

export async function createAccountAddress({
  userId,
  address,
  defaultBillingAddress,
  defaultShippingAddress,
  authenticationToken,
}: {
  userId: number;
  address: Address;
  defaultShippingAddress: boolean;
  defaultBillingAddress: boolean;
  authenticationToken: string | undefined;
}) {
  const response = await getEvaService(
    Core.CreateAddressBookItem,
    {
      UseAsDefaultShippingAddress: defaultShippingAddress,
      UseAsDefaultBillingAddress: defaultBillingAddress,
      UserID: userId,
      Address: createPayload(address),
    },
    { authenticationToken: authenticationToken ?? undefined },
  );
  if (!response?.ID) throw new Error('[createAccountAddress] ID not found');
  return response?.ID;
}

export async function updateAccountAddress({
  addressBookItemId,
  address,
  defaultBillingAddress,
  defaultShippingAddress,
  authenticationToken,
}: {
  address: Address;
  addressBookItemId: number;
  defaultShippingAddress: boolean;
  defaultBillingAddress: boolean;
  authenticationToken: string | undefined;
}) {
  const [
    isAddressBookItemIdUpdated,
    isDefaultShippingAddressSet,
    isDefaultBillingAddressSet,
  ] = await Promise.all([
    getEvaService(
      Core.UpdateAddressBookItem,
      {
        ID: addressBookItemId,
        Address: createPayload(address),
      },
      { authenticationToken },
    ),
    defaultShippingAddress &&
      getEvaService(
        Core.SetDefaultShippingAddress,
        {
          AddressBookID: addressBookItemId,
        },
        { authenticationToken },
      ),
    defaultBillingAddress &&
      getEvaService(
        Core.SetDefaultBillingAddress,
        {
          AddressBookID: addressBookItemId,
        },
        { authenticationToken },
      ),
  ]);

  if (!isAddressBookItemIdUpdated)
    throw new Error('[changeAccountAddress] AddressBookItemId not updated');
  if (defaultShippingAddress && !isDefaultShippingAddressSet)
    throw Error('[changeAccountAddress] DefaultShippingAddress not updated');
  if (defaultBillingAddress && !isDefaultBillingAddressSet)
    throw Error('[changeAccountAddress] DefaultBillingAddress not updated');
}

export async function removeAccountAddress({
  addressBookItemId,
  authenticationToken,
}: {
  addressBookItemId: number;
  authenticationToken: string | undefined;
}) {
  await getEvaService(
    Core.DeleteAddressBookItem,
    { ID: addressBookItemId },
    { authenticationToken },
  );
}

export async function getDutchAddressByZipcode(
  signal: AbortSignal | null,
  zipcode: string,
  housenumber: string,
): Promise<Address | null> {
  const data = await getEvaService(
    Core.GetAddressForZipCode,
    {
      ZipCode: zipcode,
      HouseNumber: housenumber,
    },
    { signal: signal ?? undefined },
  );

  assert(
    data?.Result,
    'Result in the response of GetAddressForZipCode is missing',
  );
  assertString(data.Result.Address1);
  assertString(data.Result.HouseNumber);
  assertString(data.Result.ZipCode);
  assertString(data.Result.City);
  assertString(data.Result.CountryID);

  return {
    firstName: data.Result.FirstName || null,
    lastName: data.Result.LastName || null,
    address1: data.Result.Address1 || null,
    address2: data.Result.Address2 || null,
    houseNumber: data.Result.HouseNumber,
    zipCode: data.Result.ZipCode,
    city: data.Result.City,
    region: data.Result.Region || null,
    countryId: data.Result.CountryID,
  };
}

export async function validateAddress(address: AjaxAddress | null) {
  if (!address) return false;
  if (!isDutchAddress(address)) return true; // @TODO: Find out how to validate foreign address from my.AJAX

  const { zipCode, houseNumber } = address;
  if (!zipCode || !houseNumber) return false;

  try {
    await getDutchAddressByZipcode(null, zipCode, houseNumber);
    return true;
  } catch {
    return false;
  }
}

export async function getValidAddressOptions({
  query,
  signal,
  selectedCountry,
}: {
  query: string;
  signal?: AbortSignal;
  selectedCountry?: string | null;
}): Promise<EVA.Core.AddressSuggestion[]> {
  const response = await getEvaService(
    Core.AutocompleteAddress,
    {
      Query: query,
      Countries: selectedCountry ? [selectedCountry] : undefined,
    },
    { signal },
  );
  return response?.Result || [];
}

export async function getValidAddressOption({
  option,
  signal,
}: {
  option: EVA.Core.AddressSuggestion;
  signal?: AbortSignal;
}): Promise<Address> {
  const response = await getEvaService(
    Core.GetAutocompleteAddressByReference,
    { Suggestion: option },
    { signal },
  );

  const addressDetails = response?.Result;
  const countryId = addressDetails?.Country?.ShortName || null;
  const houseNumber = addressDetails?.HouseNumber?.LongName || null;
  const street = addressDetails?.Street?.LongName || null;
  const address1 = houseNumber !== null ? `${street} ${houseNumber}` : street;
  const city = addressDetails?.City?.LongName;
  const zipCode = addressDetails?.PostalCode?.LongName || null;
  const region = addressDetails?.Region?.LongName || null;
  const isCountryWithoutZipcode = COUNTRIES_WITHOUT_ZIPCODE.includes(
    addressDetails?.Country?.LongName as string,
  );

  assert(countryId, 'missing countryId');
  assert(houseNumber, 'missing houseNumber');
  assert(city, 'missing city');

  return {
    firstName: null,
    lastName: null,
    address2: null,
    houseNumber,
    countryId,
    address1,
    city,
    zipCode: isCountryWithoutZipcode ? '00000' : zipCode,
    region,
  };
}
