import React from 'react';
import $ from './skip-link.module.scss';

type SkipLinkProps = React.PropsWithChildren<{
  targetId: string;
}>;

function SkipLink({ targetId, children }: SkipLinkProps) {
  return (
    <a href={`#${targetId}`} className={$.skipLink}>
      {children}
    </a>
  );
}

export default SkipLink;
