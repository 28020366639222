import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import transformGetProductRequirements from '~source/core/transformers/product/transform-get-product-requirements';

export default async function getProductRequirementValuesForOrder({
  orderId,
  authenticationToken,
}: {
  orderId: number;
  authenticationToken?: string;
}) {
  const response = await getEvaService(
    Core.GetProductRequirementValuesForOrder,
    {
      OrderID: orderId,
    },
    { authenticationToken },
  );
  if (!response) return null;

  return transformGetProductRequirements(response);
}
