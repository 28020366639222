import { format } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import { LocaleLanguageType } from '~source/core/models/unions/locale';

const localeMap = {
  'en-US': enUS,
  'nl-NL': nl,
};

function formatDate(
  timestamp: Date,
  locale: LocaleLanguageType,
  template: string,
) {
  const dateFnsLocaleObject = localeMap[locale];

  return timestamp
    ? format(timestamp, template, {
        locale: dateFnsLocaleObject,
      })
    : null;
}

function formatTimestamp(
  timestamp: string | undefined,
  locale: LocaleLanguageType,
) {
  if (!timestamp) return null;
  return formatDate(new Date(timestamp), locale, 'PP');
}

export { formatDate, formatTimestamp };
export default formatTimestamp;
