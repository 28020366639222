import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { Navigation as NavigationModel } from '~source/core/models/components/organisms/navigation';
import CartIcon from '~source/ui/components/atoms/cart-icon/cart-icon';
import HiddenBox from '~source/ui/components/atoms/hidden-box/hidden-box';
import LanguageSelector from '~source/ui/components/atoms/language-selector/language-selector';
import LoadingBar from '~source/ui/components/atoms/loading-bar/loading-bar';
import SearchInput from '~source/ui/components/molecules/search-input/search-input';
import MobileMenu from '~source/ui/components/organisms/navigation-components/mobile-menu/mobile-menu';
import NavigationItems from '~source/ui/components/organisms/navigation-components/navigation-items/navigation-items';
import NavigationLogo from '~source/ui/components/organisms/navigation-components/navigation-logo/navigation-logo';
import { useCart } from '~source/ui/hooks/cart/useCart/useCart';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { useDropdown } from '~source/ui/hooks/navigation/useDropdown';
import { useMobileMenu } from '~source/ui/hooks/navigation/useMobileMenu';
import { useSearch } from '~source/ui/hooks/navigation/useSearch';
import { useNavigation } from '~source/ui/hooks/ui/useNavigation';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './navigation.module.scss';

type Props = {
  navigation: NavigationModel;
  pagePending?: boolean;
  localeRedirectToHome?: boolean;
};

const Navigation: React.FC<Props> = ({
  navigation,
  pagePending,
  localeRedirectToHome,
}) => {
  const {
    isMobileMenuOpen,
    closeMobileMenu,
    toggleMobileMenu,
    mobileMenuRef,
    mobileMenuButtonRef,
  } = useMobileMenu();
  const {
    activeDropdownId,
    closeDropdown,
    toggleDropdown,
    dropdownHeight,
    updateDropdownHeight,
    backdropRef,
  } = useDropdown();
  const {
    isSearchOpen,
    toggleSearch,
    closeSearch,
    searchRef,
    searchButtonRef,
  } = useSearch();
  const { logo, menuItems, extraLinks, socialsMobile } = navigation;
  const { isTakeoverBanner, isAtTop } = useNavigation();
  const { shoppingCart, displayCartAmount } = useCart();
  const router = useRouter();
  const t = useTranslate();

  React.useEffect(() => {
    const handleRouteChange = () => {
      closeMobileMenu();
      closeDropdown();
      closeSearch();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <header
      className={cx(
        $.wrapper,
        {
          [$.wrapperActive]:
            (isTakeoverBanner && !isAtTop) ||
            activeDropdownId ||
            isMobileMenuOpen ||
            isSearchOpen,
        },
        { [$.wrapperNoTransition]: isTakeoverBanner && isAtTop },
      )}
    >
      <div className={$.loadingBar}>
        <LoadingBar active={pagePending} thin />
      </div>

      <HiddenBox
        id="cart-total"
        description={t('CART_TOTAL')}
        value={shoppingCart?.orderTotal || 0}
      />

      <nav className={$.nav}>
        <NavigationLogo logo={logo} />

        <div className={$.menu}>
          <div className={cx($.mobileItems, 'is-hidden--desktop')}>
            <CartIcon amount={displayCartAmount || 0} />

            <button
              type="button"
              aria-label={t(isSearchOpen ? 'SEARCH_CLOSE' : 'SEARCH_OPEN')}
              aria-expanded={isSearchOpen}
              aria-controls="shop-search"
              className={$.button}
              onClick={toggleSearch}
              ref={searchButtonRef}
            >
              <Image
                src={`/shop/images/icons/ic-${isSearchOpen ? 'close' : 'search'}.svg`}
                alt=""
                width="24"
                height="24"
              />
            </button>

            <MobileMenu
              isOpen={isMobileMenuOpen}
              menuItems={menuItems}
              extraLinks={extraLinks}
              socials={socialsMobile}
              localeRedirectToHome={localeRedirectToHome}
              activeNavItemId={activeDropdownId}
              onToggle={toggleMobileMenu}
              toggleDropdown={toggleDropdown}
              mobileMenuRef={mobileMenuRef}
              mobileMenuButtonRef={mobileMenuButtonRef}
              dropdownHeight={dropdownHeight}
              updateDropdownHeight={updateDropdownHeight}
            />
          </div>

          <div
            className={cx(
              $.desktopItems,
              'is-hidden--mobile',
              'is-hidden--tablet',
            )}
          >
            <div
              className={cx($.backdrop, !activeDropdownId && $.backdropHidden)}
              aria-hidden="true"
              onClick={closeDropdown}
              ref={backdropRef}
            />

            {!isSearchOpen && (
              <>
                <NavigationItems
                  items={menuItems}
                  extraLinks={extraLinks}
                  activeItemId={activeDropdownId}
                  toggleDropdown={toggleDropdown}
                  dropdownHeight={dropdownHeight}
                  updateDropdownHeight={updateDropdownHeight}
                />
                <hr className={$.divider} />
              </>
            )}
          </div>

          <SearchInput
            isOpen={isSearchOpen}
            toggleSearch={toggleSearch}
            searchRef={searchRef}
            closeSearch={closeSearch}
            searchButtonRef={searchButtonRef}
            autofocus
          />
        </div>

        <div
          className={cx(
            $.languageSelector,
            'is-hidden--mobile',
            'is-hidden--tablet',
          )}
        >
          <LanguageSelector
            id="shop-language-selector-desktop"
            localeRedirectToHome={localeRedirectToHome}
          />
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
