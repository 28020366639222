/* eslint-disable no-undef */
import { DYLoginData } from '~source/core/models/payload/dy-login-data';

export default function createDYLoginPayload(data: DYLoginData) {
  // @ts-ignore does exist when Dynamic yield is loaded
  if (!DYO) {
    console.warn(
      '[createDYLoginPayload] DYO - The window property DYO was not found',
    );
    return null;
  }
  return {
    name: 'Login',
    properties: {
      dyType: 'login-v1',
      // @ts-ignore does exist when Dynamic yield is loaded
      hashedEmail: DYO.dyhash.sha256(data.email.toLowerCase()),
    },
  };
}
