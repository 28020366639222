export const BASE_PATH = '/shop';
export const CART_PATH = '/cart';
export const CHECKOUT_PERSONAL_DETAIL_PATH = '/checkout/gegevens';
export const CHECKOUT_DELIVERY_PATH = '/checkout/bezorgen';
export const CHECKOUT_PAYMENT_PATH = '/checkout/betalen';
export const CHECKOUT_CONFIRMATION_PATH = '/checkout/besteld';
export const CHECKOUT_SUCCESS_PATH = '/checkout/success';

export const APP_ADYEN_PAYMENT_PATH = `${BASE_PATH}/app/payment`;
export const CHECKOUT_SUCCESS_PAGE_PATH = `${BASE_PATH}/checkout/success`;
