import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

export const config: (browser: boolean) => Parameters<typeof Sentry.init>[0] = (
  browser: boolean,
) => {
  return {
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.3,
    environment: SENTRY_ENVIRONMENT,
    denyUrls: [
      /paypal/,
      /pagead/,
      /googleads/,
      /googletagmanager/,
      /clarity/,
      /peakprotectv2\.js/,
      /injectedScript/,
      // Dynamic Yield
      /(dy-)(?:[^.]+.)?(js)/,
      /dynamicyield/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /chrome-extension:\/\//i,
      // Safari extensions
      /safari-web-extension:\/\//i,
      /safari-extension/,
    ],
    integrations: browser
      ? [
          new Sentry.BrowserTracing({
            tracingOrigins: [
              'api.ajax.prod.eva-online.cloud/api',
              // Regex from https://stackoverflow.com/questions/8830411/regex-to-match-simple-domain
              // Ensures tracing is only enabled on our domain
              /^(?!.*wachtrij)(?:https?:\/\/)?(?:[^.]+\.)?ajax\.nl(\/.*)?$/,
            ],
          }),
        ]
      : [],
    ignoreErrors: [
      'load /shop/images',
      'NetworkError when attempting to fetch resource',
      'The operation timed out.',
      'Request timed out',
      'A network error occurred',
      'The internet connection appears to be offline',
      'The network connection was lost',
      'Time-out van het verzoek',
      "Can't find variable: fbq",
      "Can't find variable: ttq",
      'fbq is not defined',
      'ttq is not defined',
      /change_ua/,
      // Each exception is preceded by the error message:
      // "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document."
      // It happens, when a user has some blocking cookies settings set on Chrome browser
      // https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document/
      /Cannot read properties of undefined (reading 'getInitialProps')/i,
      /object XMLHttpRequest/i,
      // Source https://github.com/getsentry/sentry-javascript/issues/3440
      // Most likely happening due to the safe links being scanned by Outlook
      'Non-Error promise rejection captured',
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;

      const isHandledPackageError =
        event.transaction?.includes('node_modules') &&
        event.tags?.handled === 'no';
      const isOldBrowserNextError =
        error instanceof Error &&
        error?.message?.match(
          /Cannot read property 'getInitialProps' of undefined/i,
        );

      if (isHandledPackageError || isOldBrowserNextError) return null;
      return event;
    },
  };
};
