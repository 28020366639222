import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import { CartLineWithBundleProducts } from '~source/core/models/components/atoms/cart-line';
import { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { transformAddress } from '~source/core/transformers/address/transform-address';
import getShippingCostsFromLines from '~source/core/transformers/cart/get-shipping-costs-from-lines';
import getDiscountsFromLines from '~source/core/transformers/cart/transform-discounts';
import transformLines from '~source/core/transformers/cart/transform-lines';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { assertNumber } from '~source/core/utils/validators';
import ensureString from '~source/ui/utils/checks/ensureString';

export default function transformShoppingCart({
  response,
  additionalPrintings = null,
  availabilityOfProducts = null,
  isOrdered = false,
  locale = 'nl-NL',
}: {
  response: EVA.Core.OrderDto;
  additionalPrintings: AdditionalPrintings[] | null;
  availabilityOfProducts: AvailabilityIndication | null;
  isOrdered?: boolean;
  locale?: LocaleLanguageType;
}): ShoppingCart {
  const {
    Lines,
    TotalAmountInTax,
    BillingAddress,
    BillingAddressID,
    ShippingAddress,
    ShippingAddressID,
    ID,
    PickupOrganizationUnitID,
    SoldFromOrganizationUnitID,
  } = response;

  assertNumber(ID, 'ID in GetOrder response is missing');
  assertNumber(
    PickupOrganizationUnitID,
    'PickupOrganizationUnitID in GetOrder response is missing',
  );

  const discounts = getDiscountsFromLines(Lines);
  const lines = transformLines({
    lines: Lines,
    additionalPrintings,
    availabilityOfProducts,
    isOrdered,
    locale,
  });
  const shippingCosts = getShippingCostsFromLines(Lines);
  const selectedBillingAddress = transformAddress(BillingAddress);
  const selectedShippingAddress = transformAddress(ShippingAddress);
  const orderTotal = lines.reduce((total, line: CartLineWithBundleProducts) => {
    const linePrice = line.price?.sale ?? line.price?.original;
    const bundlePrice = line.bundleProductsTotalPrice || 0;
    if (!linePrice) return total + bundlePrice;
    return total + linePrice + bundlePrice;
  }, 0);
  // If one of the bundles with printing has a different delivery estimate, use that one instead of the standard.
  const customDeliveryText =
    lines.find(
      (line) =>
        line.customDeliveryText !== null && (line.backNumber || line.printing),
    )?.customDeliveryText ?? null;
  const standardDeliveryText =
    lines.find((line) => line.standardDeliveryText)?.standardDeliveryText ??
    null;

  const deliveryInfo =
    ensureString(customDeliveryText) ?? ensureString(standardDeliveryText);

  return {
    orderId: ID,
    deliveryInfo,
    discounts,
    pickUpOrganizationUnitId: PickupOrganizationUnitID,
    isPickup: SoldFromOrganizationUnitID !== PickupOrganizationUnitID,
    lines,
    totalAmountInTax: addFallbackValue(
      TotalAmountInTax,
      'Cart total amount in tax',
      0,
    ),
    selectedBillingAddressId: BillingAddressID ?? null,
    selectedShippingAddressId: ShippingAddressID ?? null,
    selectedBillingAddress,
    selectedShippingAddress,
    shippingCosts,
    stores: availabilityOfProducts?.stores ?? null,
    orderTotal,
  };
}
