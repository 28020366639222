import {
  TransformedECommerceItem,
  TransformProps,
} from '~source/core/models/payload/e-commerce';
import transformItemResponseToProductDetails from '~source/core/transformers/e-commerce/transform-item-response-to-product-details';
import transformProductToECommerce from '~source/core/transformers/e-commerce/transform-product-to-e-commerce';
import { getEvaService, Core } from './get-eva-service';

const MAX_RETRIES = 3;

const getECommerceProduct = async (
  { item, variantInfo }: TransformProps,
  retryCount = 0,
): Promise<TransformedECommerceItem | null> => {
  if (!item.id) return null;
  try {
    const response = await getEvaService(Core.GetProductDetail, {
      ID: item.id,
    });
    const itemResponse = response?.Result;
    if (!itemResponse) return null;

    // Retry the function if the item has no name and has a bundle product.
    // The bundle product will give back more information about the product.
    const retry = !!itemResponse.bundle_id && !itemResponse.product_name;
    if (retry) {
      if (retryCount < MAX_RETRIES) {
        return await getECommerceProduct(
          {
            item: {
              id: itemResponse.bundle_id,
              quantity: item.quantity,
              variantInfo,
            },
          },
          retryCount + 1,
        );
      }
      throw new Error('Stopped retries');
    }
    const productDetails = transformItemResponseToProductDetails(itemResponse);
    if (!productDetails) return null;
    return transformProductToECommerce(productDetails, item);
  } catch {
    return null;
  }
};

export default getECommerceProduct;
