import { isEqual } from 'lodash';
import type { CartLineWithBundleProducts } from '~source/core/models/components/atoms/cart-line';
import calculateNewTotalPrice from '~source/ui/utils/math/calculate-new-total-price';

function isSameBundle(
  compareItem: CartLineWithBundleProducts,
  currentItem: CartLineWithBundleProducts,
) {
  const bothSameProduct = compareItem.productId === currentItem.productId;
  const bothHaveSameSize = compareItem.size === currentItem.size;
  const bothDoNotHaveBadge = !compareItem.badge && !currentItem.badge;
  const bothHaveSameBadge =
    compareItem.badge && currentItem.badge
      ? compareItem.badge.name === currentItem.badge.name
      : false;
  const bothHaveSamePrinting = compareItem.printing === currentItem.printing;
  const bothHaveSameBackNumber =
    compareItem.backNumber === currentItem.backNumber;
  const compareItemBundleProductIds =
    compareItem.bundleProducts?.map(({ productId }) => productId) ?? [];
  const currentItemBundleProductIds =
    currentItem.bundleProducts?.map(({ productId }) => productId) ?? [];
  const bothHaveSameBundleProducts = isEqual(
    compareItemBundleProductIds,
    currentItemBundleProductIds,
  );

  return (
    bothSameProduct &&
    bothHaveSameSize &&
    (bothDoNotHaveBadge || bothHaveSameBadge) &&
    bothHaveSamePrinting &&
    bothHaveSameBackNumber &&
    bothHaveSameBundleProducts
  );
}

export default function mergeDuplicateBundles(
  list: CartLineWithBundleProducts[],
  product: CartLineWithBundleProducts,
): CartLineWithBundleProducts[] {
  if (product.isCustomGatewayProduct) return [...list, product];
  const indexDuplicate = list.findIndex((item) => isSameBundle(item, product));
  if (indexDuplicate < 0) return [...list, product];
  const uniqueList = list;
  const newQuantity = uniqueList[indexDuplicate].quantityOpen + 1;

  const { price, quantityOpen, bundleProductsTotalPrice } =
    uniqueList[indexDuplicate];
  uniqueList[indexDuplicate].price = calculateNewTotalPrice(
    price,
    quantityOpen,
    newQuantity,
  );
  if (bundleProductsTotalPrice) {
    uniqueList[indexDuplicate].bundleProductsTotalPrice =
      (bundleProductsTotalPrice / quantityOpen) * newQuantity;
  }
  uniqueList[indexDuplicate].quantityOpen = newQuantity;
  uniqueList[indexDuplicate].bundleIds.push(product.id);
  return uniqueList;
}
