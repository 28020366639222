import { Price } from '~source/core/models/components/atoms/price';

// return price object with original and sale fields
// sale field will be null when original input is a falsy value (undefined, 0)
export function getProductPrice(
  displayPrice: number,
  original?: number,
): Price {
  return {
    original: original || displayPrice || null,
    sale: original ? displayPrice : null,
  };
}
