import { TextBlock } from '~source/core/models/components/molecules/text-block';
import { TextBlockResponse } from '~source/core/models/response/text-block-response';
import uuid from '~source/core/utils/uuid';

export default async function transformTextBlock(
  response: TextBlockResponse,
): Promise<TextBlock> {
  const { content } = response;
  const { text, title } = content;

  return {
    id: uuid(),
    moduleType: 'textBlock',
    title,
    content: text,
  };
}
