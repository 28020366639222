import { JSONLike } from '~source/core/utils/helper-types';

export function setItem<T>(key: string, value: T) {
  if (typeof window === 'undefined') return;
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getItem<T extends JSONLike = any>(key: string): T | null {
  if (typeof window === 'undefined') return null;
  const stringedValue = window.sessionStorage.getItem(key);
  if (stringedValue === null) return null;
  try {
    return JSON.parse(stringedValue) as T;
  } catch {
    window.sessionStorage.removeItem(key);
    return null;
  }
}

export function removeItem(key: string) {
  if (typeof window === 'undefined') return;
  window.sessionStorage.removeItem(key);
}
