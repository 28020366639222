import * as Sentry from '@sentry/nextjs';
import {
  bootstrapEndpoint,
  EvaService,
  IEvaServiceCallOptions,
} from '@springtree/eva-sdk-core-service';
import {
  Core,
  createServiceDefinition,
  IEvaServiceDefinition,
} from '@springtree/eva-services-core';
import DOMException from 'node-domexception';
import { EVA_BACKEND_URL } from '~source/constants';
import {
  getLocalGuestAccount,
  getLocalUserAccount,
  setGuestAccountLocally,
} from '~source/core/services/local-storage/account';
import {
  setOrderIdLocally,
  setOrderStatusLocally,
} from '~source/core/services/local-storage/cart';
import {
  getLocalEvaAppToken,
  setEvaAppTokenLocally,
} from '~source/core/services/local-storage/eva';

async function getEndpoint() {
  const endpoint = await bootstrapEndpoint({
    uri: EVA_BACKEND_URL,
  });

  return endpoint;
}

async function getEvaService<T extends IEvaServiceDefinition>(
  serviceDefinition: new () => T,
  payload?: T['request'],
  options?: IEvaServiceCallOptions & {
    signal?: AbortSignal;
    locale?: string;
  },
): Promise<T['response']> {
  try {
    const endpoint = await getEndpoint();
    const service = new EvaService(
      createServiceDefinition(serviceDefinition),
      endpoint,
    );
    service.setRequest(payload);

    const account = getLocalUserAccount() ?? getLocalGuestAccount();

    const authenticationToken =
      options?.authenticationToken ?? account?.evaAuthenticationToken;

    const fetchRequest = service.buildFetchRequest({
      ...options,
      authenticationToken: authenticationToken ?? undefined,
    });

    const locale = options?.locale ?? null;
    if (locale !== null) {
      fetchRequest.headers.set('Accept-Language', locale);
    }

    const appToken = getLocalEvaAppToken();
    if (appToken !== null) {
      fetchRequest.headers.set('eva-app-token', appToken);
    }

    const result = await fetch(fetchRequest, {
      signal: options?.signal,
    });

    if (result.headers.has('eva-app-token')) {
      const newAppToken = result.headers.get('eva-app-token');
      setEvaAppTokenLocally(newAppToken);
    }

    // A 401 respones indicates that the EVA app token has expired, so we clear it along with the order itself
    if (result.status === 401) {
      setEvaAppTokenLocally(null);
      setGuestAccountLocally(null);

      setOrderIdLocally(null);
      setOrderStatusLocally(null);
    }

    if (!result.ok) {
      return await Promise.reject(result);
    }

    const response = await result.json();
    return response as T['response'];
  } catch (err: any) {
    if (err?.name === 'AbortError') return null;
    Sentry.captureException(err);
    if (err?.constructor === DOMException) return null;
    return Promise.reject(err);
  }
}

function getAbortableEvaService<T extends IEvaServiceDefinition, R>(
  {
    service,
    payload,
    options,
  }: {
    locale?: string;
    service: new () => T;
    payload?: T['request'];
    options?: IEvaServiceCallOptions & {
      locale?: string;
    };
  },
  transformResponse: (response: T['response']) => R,
  handleError: (error: any) => Promise<R> | R = (error) =>
    Promise.reject(error),
): { promise: Promise<R>; abort: () => void } {
  const abortController = new AbortController();
  const promise = getEvaService(service, payload, {
    ...options,
    signal: abortController.signal,
  }).then(transformResponse, handleError);

  return { promise, abort: abortController.abort.bind(abortController) };
}

export default getEvaService;
export { getEndpoint, Core, getEvaService, getAbortableEvaService };
