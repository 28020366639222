import { ProductDetails } from '~source/core/models/components/organisms/product-details';
import { ThirdPartyProductInfo } from '~source/core/models/components/templates/order';
import transformThirdPartyProductInfoToDYFormat from '~source/core/transformers/payload/transform-third-party-products-info-to-dy-format';

export interface DYPurchaseData {
  uniqueTransactionId: string;
  price: number;
  currencyId: ProductDetails['currencyId'];
  products: ThirdPartyProductInfo[];
}

export default function createDYPurchasePayload({
  uniqueTransactionId,
  price,
  currencyId,
  products,
}: DYPurchaseData) {
  return {
    name: 'Purchase',
    properties: {
      uniqueTransactionId,
      dyType: 'purchase-v1',
      value: price,
      currency: currencyId,
      cart: transformThirdPartyProductInfoToDYFormat(products),
    },
  };
}
