import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { AJAX_HREF, AJAX_HREF_EN } from '~source/constants';
import {
  CART_PATH,
  CHECKOUT_PERSONAL_DETAIL_PATH,
  CHECKOUT_DELIVERY_PATH,
  CHECKOUT_PAYMENT_PATH,
  CHECKOUT_CONFIRMATION_PATH,
} from '~source/constants/paths';
import { AjaxAccount } from '~source/core/models/account';
import { Init } from '~source/core/models/components/templates/init';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import SkipLink from '~source/ui/components/molecules/skip-link/skip-link';
import Footer from '~source/ui/components/organisms/footer/footer';
import Navigation from '~source/ui/components/organisms/navigation-components/navigation/navigation';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { NavigationContextProvider } from '~source/ui/hooks/ui/useNavigation';
import $ from './layout.module.scss';

type Props = {
  initData: Init | null;
  pagePending?: boolean;
  localeRedirectToHome?: boolean;
};

function normalizeUrl(pathname: string, locale: LocaleLanguageType) {
  const href = locale === 'en-US' ? AJAX_HREF_EN : AJAX_HREF;
  const parsed = new URL(`${href}${pathname}`);
  // combine the origin and pathname, and remove the trailing slash.
  const result = `${parsed.origin}${parsed.pathname}`.replace(/\/$/, '');

  return result;
}

const Layout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  localeRedirectToHome,
  children,
  initData,
  pagePending = false,
}) => {
  // TODO: processing initData
  const { asPath, route } = useRouter();
  const locale = useLocale();
  const t = useTranslate();
  const title = initData?.title || 'Ajax Shop';
  const keywords = 'Ajax, shop, AFCA, Amsterdam';
  const href = React.useMemo(() => {
    return normalizeUrl(asPath, locale);
  }, [asPath, locale]);

  const isCheckout =
    route === CART_PATH ||
    route === CHECKOUT_PERSONAL_DETAIL_PATH ||
    route === CHECKOUT_DELIVERY_PATH ||
    route === CHECKOUT_PAYMENT_PATH ||
    route === CHECKOUT_CONFIRMATION_PATH;

  const { account } = useAccount();
  const [userId, setUserId] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!account) return;
    const encodeEmail = (userAccount: AjaxAccount | null) => {
      // @ts-ignore does exist when Dynamic yield is loaded
      if (!window.DYO) return null;
      // @ts-ignore does exist when Dynamic yield is loaded
      return window.DYO.dyhash.sha256(userAccount?.email.toLowerCase());
    };
    const encodedEmail = encodeEmail(account);
    setUserId(encodedEmail);
  }, [account]);

  return (
    <>
      <Head>
        {userId && (
          <script
            type="text/javascript"
            id="google-analytics-user-id"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.unshift({
                                    'userId': '${userId}'
                                });`,
            }}
          />
        )}
        <title>{title}</title>
        <meta
          name="viewport"
          content="initial-scale=1, viewport-fit=cover, width=device-width"
        />
        <link key="canonical" rel="canonical" href={href} />
        <meta key="og-url" property="og:url" content={href} />
        <meta key="og-type" property="og:type" content="website" />
        <meta key="robots" name="robots" content="index, follow" />
        <meta
          key="og-site_name"
          property="og:site_name"
          content="AJAX Fanshop"
        />

        <meta name="keywords" content={keywords} />
        <meta key="og-locale" property="og:locale" content={locale} />
        <meta name="author" content="Ajax" />
      </Head>
      <SkipLink targetId="main-content">
        {t('A11Y_SKIP_TO_MAIN_CONTENT')}
      </SkipLink>
      <NavigationContextProvider>
        {initData && (
          <Navigation
            localeRedirectToHome={localeRedirectToHome}
            navigation={initData.navigation}
            pagePending={pagePending}
          />
        )}
        <main id="main-content" className={$.main}>
          {children}
        </main>
      </NavigationContextProvider>
      {initData && !isCheckout && <Footer footerLinks={initData.footer} />}
    </>
  );
};

export default Layout;
