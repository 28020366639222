import React from 'react';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './grid.module.scss';

interface GridProps {
  className?: string;
  style?: React.CSSProperties;
  wrapperElement?: JSX.Element;
}

const Grid: React.FC<React.PropsWithChildren<GridProps>> = ({
  className,
  style,
  wrapperElement = <div />,
  children,
}) =>
  React.cloneElement(wrapperElement, {
    className: cx($.grid, className),
    style,
    children,
  });

export default Grid;
