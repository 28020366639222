import { NextRouter } from 'next/router';

export default function removeQueryParam(param: string, router: NextRouter) {
  const { pathname, query } = router;
  const params = new URLSearchParams(query.toString());
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
}
