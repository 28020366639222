import isEqual from 'lodash/isEqual';
import * as React from 'react';
import * as localStorage from '~source/core/services/local-storage/local-storage';

const isFunctionInitializer = <T>(value: unknown): value is (stored: T) => T =>
  typeof value === 'function';

export default function usePersistedState<T>(
  key: string,
  initialValue: T | ((stored: T) => T),
) {
  const [value, setValue] = React.useState<T>(() => {
    const stored = localStorage.getItem(key);

    if (isFunctionInitializer<T>(initialValue)) {
      return initialValue(stored);
    }
    return initialValue;
  });

  const setLocalValue = React.useCallback(
    (newValue: T) => {
      localStorage.setItem(key, newValue);
      if (typeof StorageEvent !== 'undefined') {
        const event = new StorageEvent('storage', { key });
        window.dispatchEvent(event);
      }
    },
    [key],
  );

  React.useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key !== key) return;
      const newValue = localStorage.getItem<T>(key) as T;
      if (!isEqual(value, newValue)) setValue(newValue);
    };

    window.addEventListener('storage', handleStorageChange, false);

    return () =>
      window.removeEventListener('storage', handleStorageChange, false);
  }, [key, value]);

  return [value, setLocalValue] as const;
}
