import { createContext, useContext } from 'react';
import { ApplicationConfiguration } from '~source/core/models/application-configuration';
import { Init } from '~source/core/models/components/templates/init';

type ContextType = {
  appConfig: ApplicationConfiguration | null;
  initData: Init | null;
};

const ApplicationContext = createContext<ContextType | null>(null);

function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (context === null) throw new Error('missing wrapping context');
  return context;
}

function useApplicationConfig() {
  return useApplicationContext().appConfig;
}

function useInitData() {
  return useApplicationContext().initData;
}

const { Provider } = ApplicationContext;

export { useApplicationConfig, useInitData, Provider };
export default useApplicationConfig;
