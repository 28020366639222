import * as Msal from '@azure/msal-browser';
import * as Sentry from '@sentry/nextjs';
import { getUnixTime, addDays } from 'date-fns';
import { isEqual } from 'lodash';
import * as React from 'react';
import { AJAX_SSO_CLIENT_PROVIDER_ID } from '~source/constants';
import type {
  AjaxAccount,
  AjaxGuestAccount,
  AjaxUserAccount,
  BaseAjaxAccount,
} from '~source/core/models/account';
import type {
  Address,
  AjaxAddress,
} from '~source/core/models/components/atoms/address';
import * as EvaAccountService from '~source/core/services/eva/api/account/account';
import * as EvaAddressService from '~source/core/services/eva/api/account/addresses';
import {
  GUEST_ACCOUNT_LOCAL_STORAGE_KEY,
  USER_ACCOUNT_LOCAL_STORAGE_KEY,
} from '~source/core/services/local-storage/account';
import parseNumber from '~source/core/utils/parse-number';
import { Popup } from '~source/ui/components/atoms/popup/popup';
import useAutoSignInFromApp from '~source/ui/hooks/auth/useAutoSignInFromApp/useAutoSignInFromApp';
import { useMsal } from '~source/ui/hooks/auth/useMsal';
import useReloadAccount from '~source/ui/hooks/auth/useReloadAccount/useReloadAccount';
import usePersistedState from '~source/ui/hooks/helper/usePersistedState/usePersistedState';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import sendDYEvent from '~source/ui/utils/dynamic-yield/send-dy-event';
import clamp from '~source/ui/utils/math/clamp';
import areAddressesEqual from '~source/ui/utils/order/are-addresses-equal';

type UpsertGuestParams = {
  orderId: number;
  details: BaseAjaxAccount;
};

type AccountProviderProps = React.PropsWithChildren<unknown>;

export type AccountContextType = {
  pending: boolean;
  initialized: boolean;
  account: AjaxAccount | null;
  addresses: AjaxAddress[] | null;
  signIn(): Promise<void>;
  signUp(): Promise<void>;
  signOut(): Promise<void>;
  upsertGuestAccount(params: UpsertGuestParams): Promise<AjaxGuestAccount>;
  deleteGuestAccount(): void;
  getUserAddress(addressBookItemId: number): Promise<AjaxAddress | null>;
  createUserAddress(address: Address & Partial<AjaxAddress>): Promise<number>;
  updateUserAddress(address: AjaxAddress): Promise<number>;
  upsertUserAddress(
    address: AjaxAddress | (Address & Partial<AjaxAddress>),
  ): Promise<number>;
  deleteUserAddress(address: AjaxAddress): Promise<void>;
  updateUserPhoneNumber: (phoneNumber?: string | null) => Promise<void>;
};

const AccountContext = React.createContext<AccountContextType | null>(null);

export function useAccount() {
  const context = React.useContext(AccountContext);
  if (!context) throw Error('[useAccount] No context found');
  return context;
}

function isValidUserAddress(
  address: AjaxAddress | (Address & Partial<AjaxAddress>),
): address is AjaxAddress {
  return address.type === 'ajax-address' && !!address.addressBookItemId;
}

function assertValidUserAccount(
  account: AjaxUserAccount | AjaxGuestAccount | null,
): asserts account is AjaxUserAccount {
  if (!account || account.type !== 'ajax-account')
    throw Error('[assertValidUserAccount] No user account active');
  if (!account.evaUserId)
    throw Error('[assertValidUserAccount] No evaUserId found');
}

export function AccountProvider({ children }: AccountProviderProps) {
  const t = useTranslate();
  const {
    msalAuthStatus,
    msalAuthInfo,
    msalAuthIsEmployee,
    msalApp,
    msalSignIn,
    msalSignUp,
    msalSignOut,
  } = useMsal();

  const [initialized, setInitialized] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  const [userAccount, setUserAccount] =
    usePersistedState<AjaxUserAccount | null>(
      USER_ACCOUNT_LOCAL_STORAGE_KEY,
      (stored) => stored || null,
    );
  const [guestAccount, setGuestAccount] =
    usePersistedState<AjaxGuestAccount | null>(
      GUEST_ACCOUNT_LOCAL_STORAGE_KEY,
      (stored) => stored || null,
    );

  const [addresses, setAddresses] = React.useState<AjaxAddress[] | null>(null);

  // Prioritize Ajax user account over a local guest account
  const account = userAccount ?? guestAccount;

  const shouldReload = useReloadAccount(userAccount);

  const signIn = React.useCallback(async () => {
    setGuestAccount(null);
    setAddresses(null);

    await msalSignIn();
  }, [setGuestAccount, msalSignIn]);

  const signUp = React.useCallback(async () => {
    setGuestAccount(null);
    setAddresses(null);

    await msalSignUp();
  }, [setGuestAccount, msalSignUp]);

  const signOut = React.useCallback(async () => {
    setUserAccount(null);
    setAddresses(null);

    await msalSignOut();
  }, [msalSignOut, setUserAccount]);

  const refreshAddresses = React.useCallback(
    async (signal?: AbortSignal) => {
      assertValidUserAccount(account);

      try {
        const response = await EvaAddressService.getAccountAddresses({
          userId: account.evaUserId,
          authenticationToken: account.evaAuthenticationToken,
          signal,
        });

        if (signal && signal.aborted) return;
        setAddresses(response);
      } catch {
        setAddresses(null);
      }
    },
    [account],
  );

  const upsertGuestAccount = React.useCallback(
    async ({ orderId, details }: UpsertGuestParams) => {
      if (
        guestAccount &&
        guestAccount.evaUserId &&
        guestAccount.evaAuthenticationToken
      ) {
        const updatedGuestAccount: AjaxGuestAccount = {
          ...guestAccount,
          ...details,
          type: 'ajax-guest-account',
        };
        await EvaAccountService.updateAccount({
          account: updatedGuestAccount,
        });
        setGuestAccount(updatedGuestAccount);
        return updatedGuestAccount;
      }

      const response = await EvaAccountService.createGuestAccount({
        orderId,
        payload: details,
      });

      setGuestAccount(response);
      return response;
    },
    [guestAccount, setGuestAccount],
  );

  const deleteGuestAccount = React.useCallback(() => {
    if (!guestAccount) return;

    // We only clear the EVA related info from the guest account, so
    // the reference to EVA is gone but the address is being kept
    setGuestAccount({
      ...guestAccount,
      evaUserId: null,
      evaAuthenticationToken: null,
      orderId: null,
    });
  }, [guestAccount, setGuestAccount]);

  const getUserAddress = React.useCallback(
    async (addressBookItemId: number) => {
      assertValidUserAccount(account);

      const result = await EvaAddressService.getAccountAddresses({
        userId: account.evaUserId,
        authenticationToken: account.evaAuthenticationToken,
      });
      return (
        result?.find(
          (address) => address.addressBookItemId === addressBookItemId,
        ) ?? null
      );
    },
    [account],
  );

  const createUserAddress = React.useCallback(
    async (address: Address & Partial<AjaxAddress>) => {
      assertValidUserAccount(account);

      const existingAddress = addresses?.find((_address) =>
        areAddressesEqual(_address, address),
      );
      if (existingAddress?.addressBookItemId) {
        return existingAddress.addressBookItemId;
      }

      const addressBookItemId = await EvaAddressService.createAccountAddress({
        userId: account.evaUserId,
        authenticationToken: account.evaAuthenticationToken,
        address,
        defaultShippingAddress: address.defaultShippingAddress ?? false,
        defaultBillingAddress: address.defaultBillingAddress ?? false,
      });
      await refreshAddresses();

      return addressBookItemId;
    },
    [account, addresses, refreshAddresses],
  );

  const updateUserAddress = React.useCallback(
    async (address: AjaxAddress) => {
      assertValidUserAccount(account);

      if (!address.addressBookItemId)
        throw Error('[updateUserAddress] No addressBookItemId found');

      await EvaAddressService.updateAccountAddress({
        authenticationToken: account.evaAuthenticationToken,
        address,
        addressBookItemId: address.addressBookItemId,
        defaultShippingAddress: address.defaultShippingAddress ?? false,
        defaultBillingAddress: address.defaultBillingAddress ?? false,
      });
      await refreshAddresses();

      return address.addressBookItemId;
    },
    [account, refreshAddresses],
  );

  const upsertUserAddress = React.useCallback(
    async (address: AjaxAddress | (Address & Partial<AjaxAddress>)) => {
      if (isValidUserAddress(address)) {
        return updateUserAddress(address);
      }

      return createUserAddress(address);
    },
    [createUserAddress, updateUserAddress],
  );

  const deleteUserAddress = React.useCallback(
    async (address: AjaxAddress) => {
      assertValidUserAccount(account);

      if (!address.addressBookItemId)
        throw Error('[deleteUserAddress] No addresss book ID found');

      await EvaAddressService.removeAccountAddress({
        authenticationToken: account.evaAuthenticationToken,
        addressBookItemId: address.addressBookItemId,
      });
      await refreshAddresses();
    },
    [account, refreshAddresses],
  );

  const initializeGuestAccount = React.useCallback(async () => {
    if (!guestAccount) return;

    setPending(true);

    try {
      if (guestAccount.evaUserId && guestAccount.evaAuthenticationToken) {
        // Just call the service to verify the account exists
        await EvaAccountService.getGuestAccount({
          authenticationToken: guestAccount.evaAuthenticationToken,
        });
      } else {
        setGuestAccount(null);
      }
    } catch {
      setGuestAccount(null);
    } finally {
      setPending(false);
    }
  }, [guestAccount, setGuestAccount]);

  const initializeUserAccount = React.useCallback(async () => {
    if (!msalAuthInfo) return;

    setPending(true);

    try {
      const providerId = parseNumber(AJAX_SSO_CLIENT_PROVIDER_ID);

      const response = await EvaAccountService.loginUserAccount({
        idToken: msalAuthInfo.idToken,
        providerId: providerId ?? undefined,
        isEmployee: msalAuthIsEmployee,
      });
      setUserAccount(response);

      sendDYEvent('Login', {
        email: response.email ?? '',
      });
    } catch {
      setUserAccount(null);
      setAddresses(null);
    } finally {
      setPending(false);
    }
  }, [msalAuthInfo, msalAuthIsEmployee, setUserAccount]);

  const updateUserPhoneNumber = React.useCallback(
    async (phoneNumber?: string | null) => {
      assertValidUserAccount(account);

      const updatedUserAccount: AjaxUserAccount = {
        ...account,
        phoneNumber,
      };

      await EvaAccountService.updateAccount({
        account: updatedUserAccount,
      });
      setUserAccount(updatedUserAccount);
    },
    [account, setUserAccount],
  );

  /* Initialize the account based on the MSAL status */
  const prevMsalAuthInfo = React.useRef<Msal.AuthenticationResult | null>(null);
  const prevGuestAccount = React.useRef<AjaxGuestAccount | null>(null);
  React.useEffect(() => {
    if (msalAuthStatus === 'pending') return;

    (async () => {
      // Fetch account from EVA when there has been logged in with MSAL
      if (msalAuthInfo && !isEqual(msalAuthInfo, prevMsalAuthInfo.current)) {
        await initializeUserAccount();
        prevMsalAuthInfo.current = msalAuthInfo;
      }
      // Initialize guest account from EVA when there has been stored one locally
      else if (
        guestAccount &&
        !isEqual(guestAccount, prevGuestAccount.current)
      ) {
        await initializeGuestAccount();
        prevGuestAccount.current = guestAccount;
      }

      setInitialized(true);
    })();
  }, [
    initializeUserAccount,
    initializeGuestAccount,
    msalAuthStatus,
    msalAuthInfo,
    guestAccount,
  ]);

  useAutoSignInFromApp({
    onSuccess: setUserAccount,
  });

  // Refresh addresses whenever the user account changes
  React.useEffect(() => {
    const abortController = new AbortController();

    if (account && account.type === 'ajax-account') {
      refreshAddresses(abortController.signal).catch(Sentry.captureException);
    }

    return () => abortController.abort();
  }, [account, refreshAddresses]);

  // Automatically clear guest account when it's expired
  React.useEffect(() => {
    if (guestAccount?.creationDate) {
      const now = getUnixTime(addDays(new Date(), 1));
      const duration = (now - guestAccount.creationDate) * 1000;

      const id = setTimeout(
        () => {
          setGuestAccount(null);
        },
        clamp(0, duration, Infinity),
      );

      return () => clearTimeout(id);
    }
    return undefined;
  }, [guestAccount?.creationDate, setGuestAccount]);

  // Reload user account when there was logged in/out out in another tab
  React.useEffect(() => {
    if (!msalApp) return undefined;

    msalApp.enableAccountStorageEvents();

    const callbackId = msalApp.addEventCallback(async (message) => {
      if (
        message.eventType === Msal.EventType.ACCOUNT_ADDED ||
        message.eventType === Msal.EventType.ACCOUNT_REMOVED
      ) {
        await initializeUserAccount();
      }
    });

    return () => {
      if (callbackId) msalApp.removeEventCallback(callbackId);

      msalApp.disableAccountStorageEvents();
    };
  }, [initializeUserAccount, msalApp]);

  const contextValue = React.useMemo<AccountContextType>(
    () => ({
      initialized,
      pending: pending || msalAuthStatus === 'pending' || !initialized,
      account: account ?? guestAccount,
      addresses,
      signIn,
      signUp,
      signOut,
      upsertGuestAccount,
      deleteGuestAccount,
      getUserAddress,
      createUserAddress,
      updateUserAddress,
      upsertUserAddress,
      deleteUserAddress,
      updateUserPhoneNumber,
    }),
    [
      initialized,
      pending,
      msalAuthStatus,
      account,
      guestAccount,
      addresses,
      signIn,
      signUp,
      signOut,
      upsertGuestAccount,
      deleteGuestAccount,
      getUserAddress,
      createUserAddress,
      updateUserAddress,
      upsertUserAddress,
      deleteUserAddress,
      updateUserPhoneNumber,
    ],
  );

  return (
    <>
      {shouldReload && (
        <Popup acceptOnClick={() => window.location.reload()}>
          <p>{t('LOGIN_POPUP_EXPIRED_DESCRIPTION')}</p>
        </Popup>
      )}
      <AccountContext.Provider value={contextValue}>
        {children}
      </AccountContext.Provider>
    </>
  );
}
