import type { TakeoverBanner } from '~source/core/models/components/organisms/takeover-banner';
import type { TakeoverBannerResponse } from '~source/core/models/response/banner-response';
import transformHeaderBanner from '~source/core/transformers/molecules/transform-header-banner';

export default async function transformTakeoverBanner(
  response: TakeoverBannerResponse,
): Promise<TakeoverBanner> {
  const {
    id,
    images,
    links,
    title,
    titleFont,
    titleSize,
    titleFontColor,
    contentAlignment,
    text,
  } = transformHeaderBanner(response.content);

  return {
    id,
    moduleType: 'takeoverBanner',
    images,
    links,
    title,
    titleFont,
    titleSize,
    titleFontColor,
    contentAlignment,
    text,
  };
}
