import { Init } from '~source/core/models/components/templates/init';
import { InitResponse } from '~source/core/models/response/init-response';
import transformFooterData from '~source/core/transformers/api/transform-footer-data';
import transformHolidayLabelData from '~source/core/transformers/api/transform-holiday-label-data';
import transformModules from '~source/core/transformers/api/transform-modules';
import transformNavigationData from '~source/core/transformers/api/transform-navigation-data';
import transformshirtConfigurationsData from '~source/core/transformers/product/transform-shirt-configurations-data';
import addFallbackValue from '~source/core/utils/add-fallback-value';

export default async function transformInit(
  data: InitResponse | null,
): Promise<Init | null> {
  if (!data) return null;
  const {
    fields: {
      logo,
      menu,
      extraMenuItems,
      accountLink,
      footerLogo,
      footerMenu,
      newsletter,
      partnerLogos,
      sponsorLogos,
      copyrightText,
      socialIcons,
      socialIconsMobile,
      dynamicYieldID,
      googleTagManagerID,
      metaTitle,
      enablePeakProtect,
      holidayLabels,
      translations,
      globalModules,
      shirtConfigurations,
    },
  } = data;

  return {
    title: addFallbackValue(metaTitle, 'Init meta title', ''),
    navigation: transformNavigationData({
      myAjaxLink: accountLink,
      extraLinks: extraMenuItems,
      // @TODO: Replace extraCartLinks with correct data field when available
      // @ts-ignore
      extraCartLinks: accountLink,
      menuItems: menu,
      socials: socialIcons,
      socialsMobile: socialIconsMobile,
      logo: {
        logoImage: logo && logo[0].logoImage,
        logoDesktop: logo && logo[0].logoDesktop,
        logoMobile: logo && logo[0].logoMobile,
      },
    }),
    footer: transformFooterData({
      categories: footerMenu,
      copyright: copyrightText,
      footerLogo,
      newsletter,
      partners: partnerLogos,
      sponsors: sponsorLogos,
      socials: socialIcons,
    }),
    dynamicYieldID,
    googleTagManagerID,
    enablePeakProtect:
      enablePeakProtect === undefined ? true : enablePeakProtect,
    holidayLabels: transformHolidayLabelData(holidayLabels),
    translations: addFallbackValue(translations, 'Init translations', {}),
    globalModules: await transformModules(globalModules),
    shirtConfigurations: transformshirtConfigurationsData(shirtConfigurations),
  };
}
