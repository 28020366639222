import { setLogLevel } from '@springtree/eva-sdk-core-logger';
import { setServiceSetting } from '@springtree/eva-sdk-core-service';
import { setCoreSetting } from '@springtree/eva-sdk-core-settings';
import packageJson from 'package.json';
import { DEFAULT_LOCALE, ORGANIZATION_UNIT_ID } from '~source/constants';

setCoreSetting('appName', packageJson.name);
setCoreSetting('appVersion', packageJson.version);
setCoreSetting('language', DEFAULT_LOCALE);
setServiceSetting('requestedOrganizationUnitID', ORGANIZATION_UNIT_ID);

setLogLevel('error');
