import { ShirtConfigurator } from '~source/core/models/components/molecules/shirt-configurator';
import { ShirtConfiguratorResponse } from '~source/core/models/response/shirt-configurator-response';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import uuid from '~source/core/utils/uuid';

export default async function transformShirtConfigurator(
  response: ShirtConfiguratorResponse,
): Promise<ShirtConfigurator> {
  const { content } = response;
  const { shirtConfigurator } = content;

  const {
    defaultPlayerShirtNumber,
    defaultProductBundle,
    headline,
    price,
    subtitle,
  } = shirtConfigurator;

  return {
    id: uuid(),
    moduleType: 'shirtConfigurator',
    description: addFallbackValue(
      subtitle,
      'description of shirt configurator',
      '',
    ),
    price,
    title: addFallbackValue(headline, 'title of shirt configurator', ''),
    initialBundleId: defaultProductBundle || null,
    defaultPlayerShirtNumber: defaultPlayerShirtNumber || null,
  };
}
