import { Image } from '~source/core/models/components/atoms/image';
import { ImageResponse } from '~source/core/models/response/image-response';

export default function transformImage(
  iconData: ImageResponse | null,
): Image | null {
  if (!iconData) return null;
  const { system, fields } = iconData;

  const getUrl = () => {
    if (typeof fields.umbracoFile === 'string') {
      return fields.umbracoFile;
    }
    return fields.umbracoFile.src;
  };

  // Next image component can not be converted to webp format
  const isSvg = getUrl().slice(-3) === 'svg';
  const url = isSvg ? getUrl() : `${getUrl()}?format=webp`;

  return {
    id: system.id,
    url,
    alt: fields.caption || '',
    width: fields.umbracoWidth ?? null,
    height: fields.umbracoHeight ?? null,
  };
}
