import type { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import type {
  CartLine,
  CartLineWithBundleProducts,
} from '~source/core/models/components/atoms/cart-line';
import type { LocaleLanguageType } from '~source/core/models/unions/locale';
import transformBadge from '~source/core/transformers/cart/transform-badge';
import { bundleProductAvailability } from '~source/ui/utils/checks/is-product-available';
import { isBundle } from '~source/ui/utils/checks/is-product-type';
import getLatestAvailableItem from '~source/ui/utils/product/get-latest-available-item';

const getBundlePrice = (bundleProducts: CartLine[], quantityOpen: number) => {
  // The shirt line is in the bundleProducts as well so remove it first
  const bundleProductsWithoutMainProduct = bundleProducts.filter(
    (childLine) => !childLine.size,
  );
  const total = bundleProductsWithoutMainProduct.reduce(
    (totalPrice, product) => {
      const productPrice = product.price.sale ?? product.price.original;
      if (!productPrice) return totalPrice;
      return totalPrice + productPrice;
    },
    0,
  );
  if (total === 0) return null;
  return total * quantityOpen;
};

type BundleProducts = {
  sizelessBundleProducts: CartLine[];
  restBundleProducts: CartLine[];
};

export default function assignProductPropsToBundle({
  product,
  cartProducts,
  availabilityOfProducts,
  locale,
}: {
  product: CartLine;
  cartProducts: CartLine[];
  availabilityOfProducts: AvailabilityIndication | null;
  locale?: LocaleLanguageType;
}): CartLineWithBundleProducts {
  const isNormalProductLine = product.type === 0; // 0 = NormalProduct
  if (!isNormalProductLine || !isBundle([product.productType])) return product;

  const { id, quantityOpen } = product;
  let {
    size,
    price,
    badge,
    printing,
    backNumber,
    isPlayerPrinting,
    availability,
    availabilityDate,
  } = product;

  const bundleProducts = cartProducts.filter(
    (childLine) => childLine.parentLineId === id,
  );

  const sizeLine = bundleProducts.find((childLine) => childLine.size);
  if (sizeLine) {
    price = sizeLine.price;
    size = sizeLine.size;
  }

  const badgeLine = bundleProducts.find(
    (childLine) => childLine.productCategory === 'badge',
  );
  if (badgeLine)
    badge = transformBadge(badgeLine, quantityOpen, availabilityOfProducts);

  const printingLine = bundleProducts.find(
    (childLine) => childLine.printing !== undefined,
  );
  if (printingLine) {
    printing = printingLine.printing;
    isPlayerPrinting = printingLine.isPlayerPrinting;
  }

  const backNumberLine = bundleProducts.find(
    (childLine) => childLine.backNumber || childLine.backNumber === null,
  );
  if (backNumberLine) backNumber = backNumberLine.backNumber;

  const { sizelessBundleProducts, restBundleProducts } = bundleProducts.reduce(
    (acc: BundleProducts, childLine: CartLine) => {
      if (
        !childLine.size &&
        childLine !== badgeLine &&
        childLine !== printingLine &&
        childLine !== backNumberLine
      ) {
        acc.sizelessBundleProducts.push(childLine);
      } else {
        acc.restBundleProducts.push(childLine);
      }
      return acc;
    },
    { sizelessBundleProducts: [], restBundleProducts: [] },
  );

  const bundleProductsTotalPrice = getBundlePrice(
    restBundleProducts,
    quantityOpen,
  );

  if (bundleProducts.length && sizelessBundleProducts.length === 0) {
    availability = bundleProductAvailability(bundleProducts);
  }

  availabilityDate =
    getLatestAvailableItem(bundleProducts, locale || 'nl-NL')
      ?.availabilityDate || null;

  const productStatus = bundleProducts.find(
    (item) => item.productStatus === 'pre-sale',
  )
    ? 'pre-sale'
    : product.productStatus;

  return {
    ...product,
    availability,
    availabilityDate,
    price,
    size,
    badge,
    printing,
    backNumber,
    isPlayerPrinting,
    bundleProductsTotalPrice,
    bundleProducts,
    productStatus,
    sizelessBundleProducts,
  };
}
