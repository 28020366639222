import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export async function changeOrderLinesToPickup({
  orderId,
  pickupId,
  signal,
}: {
  orderId: number;
  pickupId: number;
  signal?: AbortSignal;
}) {
  return getEvaService(
    Core.ChangeOrderLinesToPickup,
    {
      OrderID: orderId,
      PickupOrganizationUnitID: pickupId,
    },
    { signal },
  );
}
