import {
  HolidayLabelResponse,
  HolidayLabelData,
} from '~source/core/models/response/holiday-label-response';
import transformImage from '~source/core/transformers/atoms/transform-image';

export default function transformHolidayLabelData(
  holidayLabels: HolidayLabelResponse[],
): HolidayLabelData[] {
  if (!holidayLabels) return [];
  return holidayLabels.map(
    (label): HolidayLabelData => ({
      title: label.title,
      image: transformImage(label.image),
      color: label.backgroundColor,
      evaProperty: label.evaProperty,
    }),
  );
}
