import { WelcomeText } from '~source/core/models/components/molecules/welcome-text';
import { WelcomeTextResponse } from '~source/core/models/response/welcome-text-response';
import transformLink from '~source/core/transformers/atoms/transform-link';
import uuid from '~source/core/utils/uuid';

export default async function transformWelcomeText(
  response: WelcomeTextResponse,
): Promise<WelcomeText> {
  const { content } = response;
  const { text, button } = content;

  return {
    id: uuid(),
    moduleType: 'welecomeText',
    enabled: true,
    text,
    link: transformLink(button[0]),
  };
}
