import * as React from 'react';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './base-button.module.scss';

type Props = React.ComponentProps<'button'>;

const BaseButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, disabled, type = 'button', onClick, ...rest }, ref) => {
    return (
      <button
        data-testid="button"
        {...rest}
        ref={ref}
        className={cx($.button, className)}
        // Disabled as the type is locked by typescript and
        // the default is passed here when it is missing.
        // eslint-disable-next-line react/button-has-type
        type={type}
        aria-disabled={disabled}
        onClick={onClick}
        disabled={disabled}
      />
    );
  },
);

export default BaseButton;
