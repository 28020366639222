/* eslint-disable camelcase */
import {
  ProductTeaser,
  ProductPagination,
} from '~source/core/models/components/molecules/product-teaser';
import { getProductStatus } from '~source/core/transformers/product/transform-get-products';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { getProductPrice } from '~source/ui/utils/getters/get-product-price';
import { createProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';

function transformProduct(product: any) {
  const {
    currency_id,
    display_price,
    display_value,
    product_id,
    primary_image,
    slug: resultSlug,
    subtitel,
    customisable_product,
    original_price,
    marketing_titel,
    product_statuses,
    backend_id,
    artikel_categorie,
    meta_description,
    collectie,
    hoofd_categorie,
    leeftijd_groep,
    ontwerp_zelf,
    shirt_type,
  } = product;

  if (!product_id)
    console.warn(
      `Required '${product_id}' is missing in SearchProducts response`,
    );
  if (!currency_id)
    console.warn(
      `Required currency_id of product '${product_id}' is missing in SearchProducts response`,
    );
  if (!resultSlug)
    console.warn(
      `Required resultSlug of product '${product_id}' is missing in SearchProducts response`,
    );

  const name = primary_image?.name || display_value;
  const primaryImage = {
    blob: addFallbackValue(
      primary_image?.blob,
      `primary_image.blob of product ${product_id}`,
      '',
    ),
    name: addFallbackValue(name, 'name', ''),
    type: addFallbackValue(
      primary_image?.type,
      `primary_image.type of product ${product_id}`,
      '',
    ),
  };
  const evaProperties: string[] = Object.keys(product).filter(
    (key) => product[key] === true,
  );
  const displayValue = marketing_titel || display_value;

  return {
    currencyId: currency_id,
    displayValue: addFallbackValue(displayValue, 'displayValue', ''),
    productId: product_id,
    primaryImage,
    slug: resultSlug,
    path: createProductDetailUrl(resultSlug, product_id),
    price: getProductPrice(display_price, original_price),
    productStatus: getProductStatus(product_statuses),
    subtitle: subtitel ?? null,
    showCustomizeIcon: customisable_product ?? false,
    evaProperties,
    backendId: addFallbackValue(backend_id, 'backend_id', ''),
    category: addFallbackValue(artikel_categorie, 'artikel_categorie', ''),
    description: addFallbackValue(meta_description, 'meta_description', ''),
    collection: addFallbackValue(collectie, 'collectie', ''),
    mainCategory: addFallbackValue(hoofd_categorie, 'hoofd_categorie', ''),
    ageCategory: addFallbackValue(leeftijd_groep, 'leeftijd_groep', ''),
    configurable: ontwerp_zelf || false,
    shirtType: shirt_type || '',
  };
}

function transformProducts(productTeasers: ProductTeaser[], product: any) {
  const productTeaser = transformProduct(product);
  const { productId, currencyId, slug } = productTeaser;
  if (productId && currencyId && slug) {
    return [...productTeasers, productTeaser];
  }
  return productTeasers;
}

export default function transformSearchProducts(
  response?: EVA.Core.SearchProductsResponse,
): ProductPagination {
  if (!response)
    return {
      currentPage: 0,
      products: [],
      totalProducts: 0,
      totalPages: 0,
    };

  const { Products, Total, Page, PageSize } = response;

  return {
    products: Products ? Products.reduce(transformProducts, []) : [],
    totalProducts: Total ?? 0,
    currentPage: Page ?? 0,
    totalPages: Total && PageSize ? Math.ceil(Total / PageSize) : 0,
  };
}
