import { ShirtConfiguration } from '~source/core/models/components/molecules/shirt-configuration';
import { ShirtConfigurationResponse } from '~source/core/models/response/shirt-configuration-response';
import transformImage from '~source/core/transformers/atoms/transform-image';

export default function transformshirtConfigurationsData(
  shirtConfigurations: ShirtConfigurationResponse[],
): ShirtConfiguration[] {
  if (!shirtConfigurations?.length) return [];
  const shirts = shirtConfigurations.map((shirt) => {
    return {
      backImage: transformImage(shirt.backImage),
      backImageWithSponsors: transformImage(shirt.backImageWithSponsors),
      frontImage: transformImage(shirt.frontImage),
      shirtType: shirt.evaShirtType,
    };
  });
  return shirts;
}
