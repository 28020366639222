import { CartLine } from '~source/core/models/components/atoms/cart-line';

export default function groupBundleProductsInOrder(lines: CartLine[]) {
  if (!lines) return [];

  return lines.reduce((groupedLines: CartLine[], line) => {
    const { parentLineId } = line;
    const isPartOfBundleProduct =
      parentLineId &&
      lines.find((parentLine) => parentLine.id === parentLineId);

    if (!isPartOfBundleProduct) {
      groupedLines.push(line);
    }
    return groupedLines;
  }, []);
}
