import { WrappingLine } from '~source/core/models/components/molecules/available-gift-wrapping-lines';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export type SetGiftWrappingPayload = {
  orderId: number;
  wrapWholeOrder: boolean;
  wrapIndividually: boolean;
  itemsToWrap?: WrappingLine[];
};

export async function setGiftWrappingOptionsOnOrder(
  {
    orderId,
    wrapWholeOrder,
    wrapIndividually,
    itemsToWrap,
  }: SetGiftWrappingPayload,
  authenticationToken?: string | null,
) {
  const lines = itemsToWrap
    ? itemsToWrap.map((item) => ({
        OrderLineID: item.orderLineId,
        Quantity: item.quantityWrapped,
      }))
    : [];

  return getEvaService(
    Core.SetGiftWrappingOptionsOnOrder,
    {
      OrderID: orderId,
      WrapOrder: wrapWholeOrder,
      WrapIndividually: wrapIndividually,
      GiftWraps: [{ Lines: lines }],
    },
    {
      authenticationToken: authenticationToken ?? undefined,
    },
  );
}
