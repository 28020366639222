import { parse, compareAsc } from 'date-fns';
import { nl, enUS } from 'date-fns/locale';
import { CartLine } from '~source/core/models/components/atoms/cart-line';
import {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';

const parseDate = (date: string, locale: string) => {
  if (locale === 'en-US') {
    return parse(date, 'MMM dd, yyyy', new Date(), { locale: enUS });
  }
  return parse(date, 'd MMM yyyy', new Date(), { locale: nl });
};

export type Item = SizeModel | SizeModelConfigurable | CartLine;

export default function getLatestAvailableItem(items: Item[], locale: string) {
  if (!items.length) return null;

  return items.reduce((latestItem: Item | null, item) => {
    if (!item.availabilityDate) return latestItem;

    const currentDate = parseDate(item.availabilityDate, locale);

    const latestDate = latestItem?.availabilityDate
      ? parseDate(latestItem.availabilityDate, locale)
      : null;

    if (
      !latestItem ||
      (latestDate && compareAsc(currentDate, latestDate) === 1)
    ) {
      return item;
    }

    return latestItem;
  }, null);
}
