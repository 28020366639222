import * as React from 'react';
import { CUSTOM_ERROR } from '~source/constants';
import Button from '~source/ui/components/atoms/buttons/button/button';
import Container from '~source/ui/components/containers/container/container';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { useInitData } from '~source/ui/hooks/useApplicationConfig';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './custom-error.module.scss';

type Props = {
  isSmall?: boolean;
  isButtonVisible?: boolean;
  btnSelector?: string;
};

const CustomError: React.FunctionComponent<Props> = ({
  isSmall,
  isButtonVisible,
  btnSelector,
}) => {
  const initData = useInitData();
  const t = useTranslate();

  const title = initData ? t('CUSTOM_ERROR_TITLE') : CUSTOM_ERROR.TITLE;
  const text = initData ? t('CUSTOM_ERROR_TEXT') : CUSTOM_ERROR.TEXT;
  const secondaryText = initData
    ? t('CUSTOM_ERROR_SECONDARY_TEXT')
    : CUSTOM_ERROR.SECONDARY_TEXT;

  return (
    <Container>
      <h1 className={cx($.title, { [$.isSmall]: isSmall })}>{title}</h1>
      <p className={cx($.text, { [$.isSmall]: isSmall })}>{text}</p>
      <p className={cx($.text, { [$.isSmall]: isSmall })}>{secondaryText}</p>
      {isButtonVisible && (
        <Button className={$.button} variant="red" link="/" id={btnSelector}>
          {t('GO_BACK_TO_SHOP')}
        </Button>
      )}
    </Container>
  );
};

export default CustomError;
