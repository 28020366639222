import { cx } from '~source/ui/utils/join-classnames';
import $ from './loading-bar.module.scss';

type Props = {
  active?: boolean;
  /** progress from 0-1 */
  className?: string;
  thin?: boolean;
  isRounded?: boolean;
};

function LoadingBar({ active, thin, className, isRounded }: Props) {
  const containerClassName = cx(
    $.container,
    className,
    active && $.isActive,
    thin && $.isThin,
    isRounded && $.isRounded,
  );
  return (
    <div className={containerClassName}>
      <div className={$.bar} />
    </div>
  );
}

export default LoadingBar;
