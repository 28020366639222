import cx from 'classnames';
import * as React from 'react';
import $ from './container.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
  isFullWidth?: boolean;
  ref?: any;
}

const Container: React.FunctionComponent<Props> = React.forwardRef(
  (
    { children, className, isFullWidth, ...other },
    ref: React.LegacyRef<HTMLDivElement> | undefined,
  ) => (
    <div
      ref={ref}
      className={cx(
        isFullWidth && $.containerFullWidth,
        $.container,
        className,
      )}
      {...other}
    >
      {children}
    </div>
  ),
);

export default Container;
