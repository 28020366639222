import {
  BannerImage,
  Device,
} from '~source/core/models/components/atoms/banner-image';
import { BannerImageResponse } from '~source/core/models/response/banner-image-response';
import transformImage from '~source/core/transformers/atoms/transform-image';

export default function transformBannerImage({
  bannerDevice,
  image,
}: BannerImageResponse): BannerImage {
  const device = bannerDevice?.toLowerCase() as Device;
  const bannerImage = transformImage(image);

  return {
    image: bannerImage,
    device,
  };
}
