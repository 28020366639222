import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import { ProductDetailPlayerModel } from '~source/core/models/components/atoms/product-detail-player-model';
import getAdditionalPrintingValue from '~source/core/transformers/cart/get-additional-printing-value';
import transformPlayersFromOrderLine from '~source/core/transformers/cart/transform-players-from-orderline';

const getPlayerName = (
  printing: string | null | undefined,
  players: ProductDetailPlayerModel[] | null,
) => {
  if (!printing || !players) return null;
  // EVA returns the backnumber as a printing value not the name so we have to retrieve it from the provided players list
  const playerInfo = players.find(
    (player) => player.number.toString() === printing,
  );
  if (!playerInfo) return null;
  return playerInfo.name;
};

export default function getPrinting({
  id,
  productRequirements,
  isPlayerPrinting,
  printOption,
  additionalPrintings,
}: {
  id: number;
  productRequirements: any; // EVA hasn't typed the product requirements
  isPlayerPrinting: boolean;
  printOption: 'number' | 'letters';
  additionalPrintings: AdditionalPrintings[] | null;
}) {
  if (printOption !== 'letters') return undefined;
  if (!additionalPrintings) return ''; // No additionalPrintings means still retrieving printings
  if (!productRequirements) return null;

  const players = transformPlayersFromOrderLine(productRequirements);
  const printingResponseValue = getAdditionalPrintingValue(
    additionalPrintings,
    id,
    productRequirements,
  );

  if (isPlayerPrinting) return getPlayerName(printingResponseValue, players);
  return printingResponseValue;
}
