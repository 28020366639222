import { BASE_PATH } from '~source/constants/paths';
import { FeatureFlagsResponse } from '~source/core/models/response/feature-flags-response';
import { fetchResponse } from '~source/core/utils/fetch-response';

const getFeatureFlags = async ({ signal }: { signal: AbortSignal }) =>
  fetchResponse<FeatureFlagsResponse>(`${BASE_PATH}/api/dy/flags`, {
    params: { location: window.location.pathname },
    signal,
  });

export default getFeatureFlags;
