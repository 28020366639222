/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */

import { AJAX_SSO_CLIENT_ID } from '~source/constants';
import { b2cPolicies } from './policies';

// The current application coordinates were pre-registered in a B2C tenant.
const apiConfig = {
  b2cScopes: ['https://ajaxfans.onmicrosoft.com/api/read'],
  webApi:
    'https://prod-72.westeurope.logic.azure.com:443/workflows/9777c2efdf5446feb12b457ab7cc0126/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=3gNB0iLUyDnHKhYSW-IrauxPddGxfazUL7PyksESWw4',
};

const msalConfig = {
  auth: {
    clientId: AJAX_SSO_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri:
      typeof window !== 'undefined'
        ? `${window.location.origin}/shop/cart`
        : undefined, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export { apiConfig, msalConfig, loginRequest, tokenRequest };
