import {
  AvailabilityIndication,
  ProductAvailabilityIndication,
} from '~source/core/models/components/atoms/availability-indication';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import {
  transformCarryOutStore,
  transformStore,
} from '~source/core/transformers/product/transform-stores';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import formatTimestamp from '~source/ui/utils/formatters/format-date';
import getAvailabilityStatus from '~source/ui/utils/getters/get-availability-status';

export default function transformAvailibility(
  response: EVA.Core.GetProductAvailabilityResponse | undefined,
  locale: LocaleLanguageType,
): AvailabilityIndication | null {
  if (!response || !response.Products?.length) return null;

  const products: AvailabilityIndication['products'] = response.Products.reduce(
    (acc: ProductAvailabilityIndication[], item) => {
      const { ProductID, Delivery, Pickup } = item;
      const availabilityDate = Delivery?.AvailabilityDate || '';
      const availableForPickup = addFallbackValue(
        Pickup?.IsAvailable,
        'Availability pickup',
        false,
      );

      if (ProductID) {
        const availableForDelivery = addFallbackValue(
          Delivery?.IsAvailable,
          `Availability delivery of product id ${ProductID}`,
          false,
        );
        const product: ProductAvailabilityIndication = {
          id: ProductID,
          availableAmount: Delivery?.QuantityAvailable || 0,
          availability: getAvailabilityStatus(
            availableForDelivery,
            availabilityDate,
          ),
          availabilityDate: availabilityDate
            ? formatTimestamp(availabilityDate, locale)
            : null,
          availableForPickup,
        };
        acc.push(product);
      }
      return acc;
    },
    [],
  );

  const availableStores = response.PickupOrganizationUnits || [];

  const isPickupAvailable = response.Products.some(
    (product) => product.Pickup?.IsAvailable,
  );

  const stores = availableStores.map((store) =>
    transformStore(store, isPickupAvailable),
  );

  const carryOutStores =
    response?.CarryOutOrganizationUnits?.map((store) =>
      transformCarryOutStore(store),
    ) || [];

  return {
    products,
    stores,
    carryOutStores,
  };
}
