import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

type AddBundleProductToOrderPayload = {
  productId: number;
  lineActionType: number;
  quantity: number;
  orderId: number;
};

export const addProductToOrder = async (
  {
    productId,
    lineActionType,
    quantity,
    orderId,
  }: AddBundleProductToOrderPayload,
  authenticationToken?: string | null,
) => {
  return getEvaService(
    Core.AddProductToOrder,
    {
      ProductID: productId,
      LineActionType: lineActionType,
      QuantityOrdered: quantity,
      OrderID: orderId,
      Children: [],
    },
    {
      authenticationToken: authenticationToken ?? undefined,
    },
  );
};
