import { Address } from '~source/core/models/components/atoms/address';
import {
  Core,
  getEvaService,
} from '~source/core/services/eva/api/get-eva-service';
import getAddress from '~source/core/utils/get-address';

export function createPayload(orderId: number, address: Address | null) {
  return {
    OrderID: orderId,
    Address: address ? getAddress(address) : undefined,
  };
}

export async function updateBilling(
  {
    payload,
    signal,
  }: {
    payload: EVA.Core.UpdateOrderBillingAddress;
    signal?: AbortSignal;
  },
  authenticationToken: string | null,
) {
  return getEvaService(Core.UpdateOrderBillingAddress, payload, {
    authenticationToken: authenticationToken ?? undefined,
    signal,
  });
}

export async function updateShipping(
  {
    payload,
    signal,
  }: {
    payload: EVA.Core.UpdateOrderShippingAddress;
    signal?: AbortSignal;
  },
  authenticationToken?: string,
) {
  return getEvaService(Core.UpdateOrderShippingAddress, payload, {
    authenticationToken,
    signal,
  });
}
