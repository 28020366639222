import { AbortableFetch } from '~source/core/models/fetch/AbortableFetch';
import { QueryObject, addQueryString } from '~source/ui/utils/urls/url-query';

type FetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  params?: QueryObject;
  body?: string;
  signal?: AbortSignal;
};

class ResponseError extends Error {
  statusCode: number;

  url: string;

  constructor(statusCode: number, url: string) {
    super(`ResponseError: Server responded with status of ${statusCode}`);
    this.statusCode = statusCode;
    this.url = url;
  }
}

function isResponseError(error: any): error is ResponseError {
  return error instanceof ResponseError;
}

async function fetchResponse<T = any>(
  endpoint: string,
  { method = 'GET', headers, params = {}, body, signal }: FetchOptions = {},
): Promise<T> {
  const url = addQueryString(endpoint, params);
  const response = await fetch(url, {
    method,
    headers,
    signal,
    body,
  });
  if (!response.ok) {
    throw new ResponseError(response.status, url);
  }
  const data = (await response.json()) as T;

  return data;
}

function createAbortable<T, A extends any[]>(
  fetcher: (signal: AbortSignal, ...args: A) => Promise<T> | T,
): AbortableFetch<T, A> {
  return function abortable(...args) {
    const abortcontroller = new AbortController();
    const promise = Promise.resolve(fetcher(abortcontroller.signal, ...args));

    return {
      promise,
      abort: () => abortcontroller.abort(),
    };
  };
}

export { fetchResponse, isResponseError, createAbortable };
export default fetchResponse;
