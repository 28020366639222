import type { BannerImage } from '~source/core/models/components/atoms/banner-image';
import type {
  TitleFont,
  TitleSize,
} from '~source/core/models/components/molecules/banner';
import type { HeaderBanner } from '~source/core/models/components/molecules/header-banner';
import type { BannerImageResponse } from '~source/core/models/response/banner-image-response';
import type { HeaderBannerResponse } from '~source/core/models/response/banner-response';
import transformBannerImage from '~source/core/transformers/atoms/transform-banner-image';
import transformButton from '~source/core/transformers/atoms/transform-button';
import uuid from '~source/core/utils/uuid';

export default function transformHeaderBanner(
  response: HeaderBannerResponse,
): HeaderBanner {
  const {
    bannerImages,
    buttons,
    headline,
    titleFont: font,
    titleWeight,
    titleFontColor,
    contentAlignment,
    subtitle,
  } = response;

  const images = bannerImages.reduce(
    (acc: BannerImage[], image: BannerImageResponse) => {
      if (!image.bannerDevice) return acc;
      const transformedImage = transformBannerImage(image);
      return [...acc, transformedImage];
    },
    [],
  );
  const links = buttons.map((button) => transformButton(button));
  const title = headline || '';
  const titleFont = font.toLowerCase() as TitleFont;
  const titleSize = titleWeight.toLowerCase() as TitleSize;

  return {
    id: uuid(),
    images,
    links,
    title,
    titleFont,
    titleSize,
    titleFontColor,
    contentAlignment,
    text: subtitle || '',
  };
}
