import type { Address } from '~source/core/models/components/atoms/address';

const areAddressesEqual = (a: Address | null, b: Address | null) =>
  a?.address1 === b?.address1 &&
  a?.address2 === b?.address2 &&
  a?.city === b?.city &&
  a?.countryId === b?.countryId &&
  a?.zipCode === b?.zipCode &&
  a?.houseNumber === b?.houseNumber &&
  a?.firstName === b?.firstName &&
  a?.lastName === b?.lastName;

export default areAddressesEqual;
