import * as localStorage from '~source/core/services/local-storage/local-storage';

export const ORDER_ID_LOCAL_STORAGE_KEY = 'ajax:orderId';
export const ORDER_STATUS_LOCAL_STORAGE_KEY = 'ajax:orderStatus';
export const PAYMENT_TIMESTAMP_START_LOCAL_STORAGE_KEY =
  'ajax:paymentTimestampStart';

export function setOrderIdLocally(orderId: number | null) {
  localStorage.setItem(ORDER_ID_LOCAL_STORAGE_KEY, orderId);

  const event = new StorageEvent('storage', {
    key: ORDER_ID_LOCAL_STORAGE_KEY,
  });
  window.dispatchEvent(event);
}
export function getLocalOrderId() {
  return localStorage.getItem(ORDER_ID_LOCAL_STORAGE_KEY);
}

export function setOrderStatusLocally(orderStatus: string | null) {
  localStorage.setItem(ORDER_STATUS_LOCAL_STORAGE_KEY, orderStatus);

  const event = new StorageEvent('storage', {
    key: ORDER_STATUS_LOCAL_STORAGE_KEY,
  });
  window.dispatchEvent(event);
}
