import trimString from '~source/ui/utils/formatters/trim-string';

export const isValidName = (name: any): boolean => {
  const trimmedName = trimString(name);
  return !!trimmedName;
};
export const isEmail = (email: string | null): boolean => {
  const trimmedEmail = trimString(email);
  if (!trimmedEmail) return false;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emojiRegex = /\p{Extended_Pictographic}/gu;

  return regex.test(trimmedEmail) && !emojiRegex.test(trimmedEmail);
};

export const isValidDate = (string: string): boolean => {
  // String should be in this date format: [D]D/[M]M/YYYY
  const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/gm;

  if (!regex.test(string)) return false;

  const [day, month, year] = string.split('/');

  if (!day || !month || !year) return false;

  const date = new Date(`${year}-${month}-${day}`);

  return date instanceof Date && !Number.isNaN(date.getTime());
};
