import transformPlayers from '~source/core/transformers/product/transform-players';

export default function transformPlayersFromOrderLine(
  // Not typed by EVA
  productRequirements: any,
) {
  if (!productRequirements) return null;

  const players = productRequirements.find(
    (requirement: { data: { [key: string]: string } }) =>
      requirement.data !== null,
  )?.data;
  if (!players) return null;
  return transformPlayers(players);
}
