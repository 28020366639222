import { DYSyncCartData } from '~source/core/models/payload/dy-sync-data';
import transformCartToDYFormat from '~source/core/transformers/payload/transform-cart-to-dy-format';

export default function createDYSyncCartPayload({
  currencyId,
  cart,
}: DYSyncCartData) {
  return {
    name: 'Sync cart',
    properties: {
      dyType: 'sync-cart-v1',
      currencyId: currencyId || 'EUR',
      cart: cart ? transformCartToDYFormat(cart) : [],
    },
  };
}
