import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import { CartLine } from '~source/core/models/components/atoms/cart-line';
import getBackNumber from '~source/core/transformers/cart/get-backnumber';
import getCustomGatewayImageUrl from '~source/core/transformers/cart/get-custom-gateway-image-url';
import getPrinting from '~source/core/transformers/cart/get-printing';
import transformPrice from '~source/core/transformers/cart/transform-price';
import { getProductStatus } from '~source/core/transformers/product/transform-get-products';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { assertNumber } from '~source/core/utils/validators';
import { isBundle } from '~source/ui/utils/checks/is-product-type';
import getAvailabilityOfProduct from '~source/ui/utils/getters/get-availability-of-product';

export default function transformLineToCartProduct(
  line: EVA.Core.OrderLineDto,
  additionalPrintings: AdditionalPrintings[] | null,
  availabilityOfProducts: AvailabilityIndication | null,
  isOrdered: boolean,
): CartLine {
  const { ID, Product, CustomOrderLineType, NetTotalAmountInTax } = line;
  assertNumber(ID, 'Line.ID in GetOrder response is missing');
  assertNumber(Product?.ID, 'Product.ID in GetOrder response is missing');
  assertNumber(
    line.TotalAmountInTax,
    'Line.TotalAmountInTax in GetOrder response is missing',
  );
  assertNumber(
    line.QuantityOrdered,
    'Line.QuantityOrdered in GetOrder response is missing',
  );
  assertNumber(
    line.QuantityOpen,
    'Line.QuantityOpen in GetOrder response is missing',
  );

  const id = ID;
  const productId = Product?.ID;
  const productName =
    Product?.Properties?.marketing_titel ||
    addFallbackValue(
      Product?.PrimitiveName,
      'Line Product PrimitiveName',
      '',
    ) ||
    addFallbackValue(line.Description, 'Description', '');
  const price = transformPrice(
    line.TotalAmountInTax,
    line.OriginalTotalAmountInTax,
  );
  const isPlayerPrinting = Product?.Properties?.item === 'spelers';
  const printOption = Product?.Properties?.printing || null;
  const printing = getPrinting({
    id,
    productRequirements: Product?.Properties?.product_requirements,
    isPlayerPrinting,
    printOption,
    additionalPrintings,
  });
  const backNumber = getBackNumber({
    id,
    productRequirements: Product?.Properties?.product_requirements,
    printOption,
    additionalPrintings,
  });
  const { availability, availabilityDate } = getAvailabilityOfProduct(
    availabilityOfProducts,
    productId ?? null,
    Product?.Type ?? null,
  );
  const customGatewayImage = getCustomGatewayImageUrl(id, additionalPrintings);
  const quantityOpen = isOrdered ? line.QuantityOrdered : line.QuantityOpen;
  const productStatusesValue = line.Product?.Properties
    ?.product_statuses as number[];
  const productStatuses =
    (Array.isArray(productStatusesValue) && productStatusesValue) || [];
  const mainCategory = addFallbackValue(
    Product?.Properties?.hoofd_categorie,
    'Line Product Properties Main Category',
    '',
  );
  const collection = addFallbackValue(
    Product?.Properties?.collectie,
    'Line Product Properties Collection',
    '',
  );
  const articleCategory = addFallbackValue(
    Product?.Properties?.artikel_categorie,
    'Line Product Properties Article categpry',
    '',
  );
  const ageCategory = addFallbackValue(
    Product?.Properties?.leeftijd_groep,
    'Line Product Properties Age Category',
    '',
  );
  const configurable = addFallbackValue(
    Product?.Properties?.ontwerp_zelf,
    'Line Product Properties Configurable',
    '',
  );
  const customOrderLineType = addFallbackValue(
    CustomOrderLineType,
    'Line CustomOrderLineType',
    '',
  );
  const totalNetAmountInTax = addFallbackValue(
    NetTotalAmountInTax,
    'Line NetTotalAmountInTax',
    null,
  );
  const isDiscount = customOrderLineType === 'DISCOUNTPRODUCT';
  const isZeroInTotal = totalNetAmountInTax === 0;
  const isForFree = isDiscount && isZeroInTotal;

  return {
    id,
    backendId: Product?.BackendID || null,
    bundleIds: [ID],
    customGatewayImage,
    standardDeliveryText: Product?.Properties?.cta_pdp_text ?? null,
    customDeliveryText: Product?.Properties?.cta_pdp_text_prs ?? null,
    isCustomGatewayProduct: !!Product?.Properties?.custom_gateway,
    type: line.Type,
    parentLineId: line.ParentID,
    productId,
    productName,
    productType: Product?.Type,
    quantityOpen,
    price,
    lineId: line.ProductBundleLineID,
    size: Product?.Properties?.size ?? line.ProductVariation?.size,
    image: Product?.Properties?.primary_image || null,
    productCategory: Product?.Properties?.categorie || '',
    isPlayerPrinting,
    printOption,
    printing,
    backNumber,
    bundleProductsTotalPrice: null,
    sizelessBundleProducts: [],
    availability: isBundle([Product?.Type]) ? 'available' : availability,
    availabilityDate,
    productStatus: getProductStatus(productStatuses),
    mainCategory,
    collection,
    articleCategory,
    ageCategory,
    configurable,
    isForFree,
  };
}
