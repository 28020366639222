import type { Discount } from '~source/core/models/components/atoms/discount';
import { isDiscount, isFree } from '~source/ui/utils/checks/is-line-type';

export default function getDiscountsFromLines(
  // eslint-disable-next-line no-undef
  lines: EVA.Core.OrderLineDto[],
): Discount[] {
  if (!lines.length) return [];

  // A discount code is added to the lines array as if it's a product so we need to extract it
  const discountLines = lines.filter((line) => isDiscount(line));

  // Filter out discounts that are not free gifts
  const nonFreeDiscountLines = discountLines.filter(
    (line) => !isFree(line, lines),
  );

  if (!nonFreeDiscountLines.length) return [];

  const transformedDiscounts = nonFreeDiscountLines.map(
    ({
      DiscountCampaignName,
      DiscountCouponCode,
      DiscountID,
      ID,
      TotalAmountInTax,
    }) => ({
      id: DiscountID as number,
      title: DiscountCampaignName,
      amount: TotalAmountInTax,
      orderLineId: ID,
      couponCode: DiscountCouponCode,
    }),
  );
  return transformedDiscounts;
}
