import {
  Button,
  ButtonColor,
} from '~source/core/models/components/atoms/button';
import { ButtonResponse } from '~source/core/models/response/button-response';
import transformImage from '~source/core/transformers/atoms/transform-image';
import transformLink from '~source/core/transformers/atoms/transform-link';

export default function transformButton({
  buttonColor,
  buttonIcon,
  link,
}: ButtonResponse): Button {
  return {
    color: buttonColor.toLowerCase() as ButtonColor,
    icon: buttonIcon && transformImage(buttonIcon),
    link: transformLink(link),
  };
}
