import cx from 'classnames';
import React from 'react';
import $ from './grid-item.module.scss';

type GridItemSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridItemProps = React.PropsWithChildren<{
  className?: string;
  enabled?: boolean;
  xs?: GridItemSize;
  s?: GridItemSize;
  m?: GridItemSize;
  l?: GridItemSize;
  xl?: GridItemSize;
  // eslint-disable-next-line no-undef
  wrapperElement?: JSX.Element;
  style?: React.CSSProperties;
}>;

const GridItem: React.FC<GridItemProps> = (props) => {
  const {
    children,
    xs = 6,
    s = 0,
    enabled = true,
    className,
    wrapperElement = <div />,
    style,
  } = props;
  let { m = 0, l = 0, xl = 0 } = props;

  // When you set s this will only be caried within the s min width and s max width
  // With these 3 if statements we carry the lower set grid width to the higher screen widths
  // This component is build for Mobile first and then adding changes when going bigger screens
  if (s !== 0 && m === 0) {
    m = s;
  }

  if (m !== 0 && l === 0) {
    l = m;
  }

  if (l !== 0 && xl === 0) {
    xl = l;
  }

  return React.cloneElement(wrapperElement, {
    style,
    className: cx(
      className,
      enabled && $.item,
      enabled && $[`xs--${xs}`],
      enabled && s !== 0 && $[`s--${s}`],
      enabled && m !== 0 && $[`m--${m}`],
      enabled && l !== 0 && $[`l--${l}`],
      enabled && xl !== 0 && $[`xl--${xl}`],
    ),
    children,
  });
};

export default GridItem;
