import { Button } from '~source/core/models/components/atoms/button';
import {
  ButtonBlockList,
  CodeBlock,
  ImageBlock,
  RichContent,
} from '~source/core/models/components/molecules/content-block';
import {
  ButtonComponent,
  CodeBlockContentComponent,
  ImageBlockComponent,
  RichContentComponent,
} from '~source/core/models/response/content-block';
import transformButton from '~source/core/transformers/atoms/transform-button';
import uuid from '~source/core/utils/uuid';

export async function transformImageBlock(
  contentBlock: ImageBlockComponent,
  index: number,
): Promise<ImageBlock> {
  const { umbracoFile, umbracoWidth, umbracoHeight, caption } =
    contentBlock.content.image.fields;

  const src = typeof umbracoFile === 'string' ? umbracoFile : umbracoFile.src;

  return {
    moduleType: 'imageBlock',
    id: src,
    anchor: `section${index + 1}`,
    caption,
    src,
    width: umbracoWidth || 0,
    height: umbracoHeight || 0,
    displaySettings: contentBlock.displaySettings,
  };
}

export async function transformContentButton(
  contentBlock: ButtonComponent,
): Promise<ButtonBlockList> {
  const buttons: Button[] = contentBlock.content.buttons.map((item) =>
    transformButton({
      ...item,
    }),
  );
  const buttonBlockList: ButtonBlockList = {
    moduleType: 'buttonBlock',
    id: uuid(),
    displaySettings: contentBlock.displaySettings,
    buttons,
  };
  return buttonBlockList;
}

export async function transformCodeBlock(
  contentBlock: CodeBlockContentComponent,
  index: number,
): Promise<CodeBlock> {
  return {
    id: contentBlock.component,
    moduleType: 'codeBlock',
    anchor: `section${index + 1}`,
    code: contentBlock.content.codeBlock,
  };
}

export async function transformRichContent(
  contentBlock: RichContentComponent,
  index: number,
): Promise<RichContent> {
  return {
    id: contentBlock.component,
    moduleType: 'richContent',
    anchor: `section${index + 1}`,
    htmlString: contentBlock.content.htmlString,
  };
}
