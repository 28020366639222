import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export async function changeOrderLinesToDelivery({
  orderId,
  signal,
}: {
  orderId: number;
  signal?: AbortSignal;
}) {
  return getEvaService(
    Core.ChangeOrderLinesToDelivery,
    {
      OrderID: orderId,
    },
    { signal },
  );
}
