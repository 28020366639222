import { LRUCache } from 'lru-cache';

interface Options {
  /** @description max age of cached value in ms. */
  maxAge?: number;
}

const DAY_IN_MS = 1000 * 60 * 60 * 24; // a day.

export default function createCachedFunction<T extends object, A extends any[]>(
  func: (...args: A) => T,
  { maxAge = DAY_IN_MS }: Options = {},
) {
  const cache = new LRUCache<string, T>({
    ttl: maxAge,
    ttlAutopurge: true,
  });

  return function cachedFunction(...args: A): T {
    const key = JSON.stringify(args);
    if (cache.has(key)) {
      // if the cache has the key, it is T.
      return cache.get(key) as T;
    }

    const value = func(...args);
    cache.set(key, value);
    return value;
  };
}
