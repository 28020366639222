import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { useInitData } from '~source/ui/hooks/useApplicationConfig';

const splitRegExp = /\{\{.*?\}\}/g;
const keyRegExp = /\{\{(.*?)\}\}/g;

export function parseTranslationWithParams<T>(
  translation: string,
  replaceNode: (key: string) => T,
): (T | string)[] {
  const parts: (string | T)[] = [];

  let index = 0;
  for (const part of translation.split(splitRegExp)) {
    index += part.length;
    parts.push(part);
    const match = keyRegExp.exec(translation.slice(index));
    if (match) {
      const key = match[1];
      parts.push(replaceNode(key));
    }
  }

  return parts;
}

function useTranslate() {
  const initData = useInitData();
  const translations = initData?.translations;
  function translate(key: string): string {
    const translation = translations?.[key];
    return translation || `{ ${key} }`;
  }

  return translate;
}

export type { LocaleLanguageType as Locale };

export { useTranslate };
