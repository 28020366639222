import { LRUCache } from 'lru-cache';

interface CacheOptions {
  /** maximum age in seconds before the value is dumped. */
  maxAge: number;
  /** maximum size of values stored in the cache before it starts to dump less used values. */
  maxSize: number;
}
type CacheFunction = <T>(key: string, getter: () => T) => Promise<T>;

/**
 * creates a cache function to cache practically anything, it uses a LRU strategy
 * so that less used values are dumped so the server won't have an potential memory leak.
 */
function createCacheFunction({ maxAge, maxSize }: CacheOptions): CacheFunction {
  // disable the cache function for clientside to remove potential bugs like data
  // being out of sync with the expected response from the server.
  if (typeof window !== 'undefined') {
    return async function clientSideCacheFunction(key, cacheValueFunction) {
      return cacheValueFunction();
    };
  }

  const cache = new LRUCache<string, any>({
    ttl: maxAge * 1000,
    max: maxSize,
    allowStale: true,
  });

  return async function cacheFunction(key, cacheValueFunction) {
    if (!cache.has(key)) {
      for (let tryNumber = 0; tryNumber < 2; tryNumber += 1) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const cacheValue = await cacheValueFunction();
          if (cacheValue) {
            cache.set(key, cacheValue);
            return cacheValue;
          }
        } catch (e) {
          if (tryNumber > 0) {
            throw e;
          }
        }
      }
    }
    return cache.get(key);
  };
}

export default createCacheFunction;
