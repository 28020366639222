import {
  HighlightBanner,
  Highlights,
} from '~source/core/models/components/molecules/highlights';
import { BannerResponse } from '~source/core/models/response/banner-response';
import { HighlightsResponse } from '~source/core/models/response/highlights-response';
import transformLink from '~source/core/transformers/atoms/transform-link';
import transformBanner from '~source/core/transformers/molecules/transform-banner';
import uuid from '~source/core/utils/uuid';

function getHighlightType(banners: BannerResponse[] | null): HighlightBanner {
  const bannersCount = banners?.length || 0;
  switch (bannersCount) {
    case 0:
      return 'No-banners';
    case 1:
      return 'One-banner';
    case 2:
      return 'Two-banners';
    default:
      return 'Three-banners';
  }
}

export default async function transformHighlights(
  response: HighlightsResponse,
): Promise<Highlights> {
  const { content } = response;
  const featured = content.banners.length > 0 ? content.banners[0] : null;
  const banners =
    content.banners.length > 0
      ? content.banners.slice(1, content.banners.length)
      : null;
  // TODO: Put this title into useTranslate
  const welcomeText = {
    text: 'He Pim, er zijn 3 nieuwe producten beschikbaar voor jou als seizoenkaarthouder',
    link: {
      id: '2423345345jjkfl',
      url: '/',
      name: 'Bekijk producten',
      target: '',
    },
  };

  return {
    id: uuid(),
    moduleType: 'highlights',
    type: getHighlightType(banners),
    featured: featured ? transformBanner(featured, 'tablet and up') : null,
    title: 'Highlights',
    banners: banners ? banners.map((banner) => transformBanner(banner)) : null,
    welcomeText: {
      text: welcomeText.text,
      link: transformLink(welcomeText.link),
    },
  };
}
