import { LinkModel } from '~source/core/models/components/atoms/link';
import {
  MenuItems,
  Navigation,
} from '~source/core/models/components/organisms/navigation';
import { LinkResponse } from '~source/core/models/response/link-response';
import {
  MenuItemsResponse,
  NavigationResponse,
} from '~source/core/models/response/navigation-response';
import transformIcon from '~source/core/transformers/atoms/transform-icon';
import transformImage from '~source/core/transformers/atoms/transform-image';
import transformLink from '~source/core/transformers/atoms/transform-link';
import addFallbackValue from '~source/core/utils/add-fallback-value';

function transformMenuItems({
  submenuHighlight,
  name,
  submenu,
}: MenuItemsResponse): MenuItems {
  return {
    highlight: submenuHighlight && {
      image: transformImage(submenuHighlight.image),
      link: transformLink(submenuHighlight.link),
      partnerLogo: transformImage(submenuHighlight.partnerLogo),
    },
    label: name,
    links: submenu.map((link: LinkResponse, i: number) =>
      transformLink(link, i.toString()),
    ),
  };
}

function getExtraLinks(
  links: LinkResponse | LinkResponse[] | null,
): LinkModel[] | undefined {
  const validLinks = addFallbackValue(links, 'Navigation links', []);
  if (!validLinks) return undefined;
  if (validLinks.constructor === Array) {
    return validLinks.map((link) => transformLink(link));
  }
  return [validLinks as LinkResponse].map((link) => transformLink(link));
}

function getMyAjaxLink(link: LinkResponse): LinkModel | null {
  const validLink = addFallbackValue(link, 'Navigation my ajax link', null);
  if (!validLink) return null;
  return transformLink(link);
}

export default function transformNavigationData({
  myAjaxLink,
  extraLinks,
  extraCartLinks,
  socials,
  socialsMobile,
  menuItems,
  logo,
}: NavigationResponse): Navigation {
  const transformedMenuItems = menuItems.map(transformMenuItems);
  return {
    menuItems: addFallbackValue(
      transformedMenuItems,
      'Navigation menu items',
      [],
    ),
    myAjaxLink: getMyAjaxLink(myAjaxLink),
    socials: socials ? socials.map((social) => transformIcon(social)) : null,
    socialsMobile: socialsMobile
      ? socialsMobile.map((social) => transformIcon(social))
      : null,
    extraLinks: getExtraLinks(extraLinks),
    extraCartLinks: getExtraLinks(extraCartLinks),
    logo: {
      desktop: transformImage(logo.logoDesktop),
      image: transformImage(logo.logoImage),
      mobile: transformImage(logo.logoMobile),
    },
  };
}
