import { CartLineWithBundleProducts } from '~source/core/models/components/atoms/cart-line';

export default function getVariantInfo(item?: CartLineWithBundleProducts) {
  if (!item) return null;
  const { backNumber, isPlayerPrinting, printing, badge } = item;
  if (!backNumber && !printing && !badge) return null;

  const printingString = isPlayerPrinting ? printing : 'your name';
  return `${backNumber || 'none'} - ${printingString || 'none'} - ${
    badge || 'none'
  }`;
}
