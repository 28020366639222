import { TransformedEcommerceProductDetails } from '~source/core/models/payload/e-commerce';

const transformItemResponseToProductDetails = (
  itemResponse: any,
): TransformedEcommerceProductDetails | null => {
  if (!itemResponse) return null;
  return {
    name: itemResponse.product_name || itemResponse.display_value,
    id: itemResponse.product_id,
    price: itemResponse.display_price,
    collection: itemResponse.collectie,
    mainCategory: itemResponse.hoofd_categorie,
    articleCategory: itemResponse.artikel_categorie,
    ageCategory: itemResponse.leeftijd_groep,
    configurable: itemResponse.ontwerp_zelf,
  };
};

export default transformItemResponseToProductDetails;
