/* eslint-disable no-undef */

export function getInfoFromProductDetailUrlSlug(slug: string): {
  productId: number | null;
  productSlug: string | null;
} {
  const parts = slug.split('-');
  const maybeId = parts.pop();
  const id = maybeId ? parseInt(maybeId, 10) : NaN;

  if (!Number.isNaN(id)) {
    return {
      productId: id,
      productSlug: parts.join('-'),
    };
  }

  return { productId: null, productSlug: slug };
}

export function createProductDetailUrl(slug: string, id: number) {
  if (!slug) return `/product/${id}`;
  return encodeURI(`/product/${slug}-${id}`);
}

export default createProductDetailUrl;
