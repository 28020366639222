import * as localStorage from '~source/core/services/local-storage/local-storage';

export const EVA_APP_TOKEN_LOCAL_STORAGE_KEY = 'ajax.eva.appToken';

export function setEvaAppTokenLocally(appToken: string | null) {
  localStorage.setItem(EVA_APP_TOKEN_LOCAL_STORAGE_KEY, appToken);
}
export function getLocalEvaAppToken() {
  return localStorage.getItem(EVA_APP_TOKEN_LOCAL_STORAGE_KEY);
}
