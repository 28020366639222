import { Price } from '~source/core/models/components/atoms/price';

// return price object with original and sale fields
// sale field will be null only when original input is undefined
export default function transformPrice(
  total: number,
  original?: number,
): Price {
  return {
    original: original ?? total,
    sale: original !== undefined ? total : null,
  };
}
