import { AjaxAddress } from '~source/core/models/components/atoms/address';
import { transformAddress } from '~source/core/transformers/address/transform-address';
import { assert, assertNumber } from '~source/core/utils/validators';

export function transformAjaxUserAccountAddress(
  data: EVA.Core.AddressBookDto,
): AjaxAddress | null {
  const address = transformAddress(data.Address);

  if (address === null) return null;
  const { Address, ID, DefaultBillingAddress, DefaultShippingAddress } = data;
  assertNumber(ID, 'ID in ListAddressBook response is missing');
  assertNumber(
    Address?.ID,
    `Address ID of item '${ID}' in ListAddressBook response is missing`,
  );
  assert(
    DefaultBillingAddress,
    `DefaultBillingAddress of item '${ID}' in ListAddressBook response is missing`,
  );
  assert(
    DefaultShippingAddress,
    `DefaultShippingAddress of item '${ID}' in ListAddressBook response is missing`,
  );

  return {
    ...address,
    type: 'ajax-address',
    addressId: Address.ID,
    addressBookItemId: ID,
    defaultBillingAddress: DefaultBillingAddress,
    defaultShippingAddress: DefaultShippingAddress,
  };
}

export function transformAjaxUserAccountAddressArray(
  data: EVA.Core.AddressBookDto[] | undefined,
) {
  if (!data || data.length === 0) return [];

  const addresses = data.reduce<AjaxAddress[]>((acc, item) => {
    const address = transformAjaxUserAccountAddress(item);
    if (address !== null) {
      acc.push(address);
    }
    return acc;
  }, []);

  addresses.sort((address) => {
    if (address.defaultBillingAddress) {
      return -2;
    }
    if (address.defaultShippingAddress) {
      return -1;
    }
    return 1;
  });

  return addresses;
}
