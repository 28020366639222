import * as React from 'react';
import useOnUnmount from '~source/ui/hooks/ui/useOnUnmount/useOnUnmount';

export default function useTimer(duration: number, callback: () => void) {
  const timeoutIdRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const start = React.useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      callbackRef.current();
    }, duration);
  }, [duration]);

  const stop = React.useCallback(() => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  const reset = React.useCallback(() => {
    stop();
    start();
  }, [start, stop]);

  useOnUnmount(stop);

  return { start, stop, reset };
}
