import * as React from 'react';

export default function useOnClickOutside(
  ref: React.RefObject<HTMLElement | null | undefined>,
  callback: (event: MouseEvent | TouchEvent) => void,
  eventType: 'click' | 'default' = 'default',
) {
  // store in a ref so the global event listener only needs
  // to be created once for the lifetime of this hook.
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const handler = (event: MouseEvent | TouchEvent) => {
      const element = ref.current;
      if (element) {
        // cast as `any` as any other value passed
        //  from the dom should result to a false.
        if (!element.contains(event.target as any)) {
          callbackRef.current(event);
        }
      }
    };

    if (eventType === 'click') {
      document.addEventListener('click', handler);

      return () => {
        document.removeEventListener('click', handler);
      };
    }

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };

    // only thing missing is the ref and that should not belong here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);
}
