import { OrderLineTypes } from '~source/constants/eva';

export function isShipping(line: EVA.Core.OrderLineDto) {
  return line.Type === OrderLineTypes.ShippingCosts;
}

export function isDiscount(line: EVA.Core.OrderLineDto) {
  return !!line.DiscountID;
}

export function isFree(
  line: EVA.Core.OrderLineDto,
  lines: EVA.Core.OrderLineDto[],
) {
  const { ParentID } = line;
  if (!ParentID) return false;

  const parent = lines.find((l) => l.ID === ParentID);
  return (
    parent?.CustomOrderLineType === 'DISCOUNTPRODUCT' &&
    parent.NetTotalAmountInTax === 0
  );
}

export function isGiftWrapping(line: EVA.Core.OrderLineDto) {
  return line.Type === OrderLineTypes.GiftWrappingCosts;
}
