import { isShipping } from '~source/ui/utils/checks/is-line-type';

export default function getShippingCostsFromLines(
  // eslint-disable-next-line no-undef
  lines: EVA.Core.OrderLineDto[],
) {
  if (!lines || !lines.length) return null;

  const shippingCostsLine = lines.find((line) => isShipping(line));
  if (!shippingCostsLine) return null;
  return shippingCostsLine.TotalAmountInTax;
}
