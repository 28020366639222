import { CartLineWithBundleProducts } from '~source/core/models/components/atoms/cart-line';
import transformProductToECommerce from '~source/core/transformers/e-commerce/transform-product-to-e-commerce';
import getVariantInfo from '~source/ui/utils/e-commerce/get-variant-info';

const transformLinesToECommerceItems = (
  lines: CartLineWithBundleProducts[],
) => {
  if (!lines.length) return null;
  const items = lines.map((product) => {
    const productDetails = {
      name: product.productName,
      id: product.id,
      price: product.price?.sale || product.price?.original,
      collection: product.collection,
      mainCategory: product.mainCategory,
      articleCategory: product.articleCategory,
      ageCategory: product.ageCategory,
      configurable: product.configurable,
    };
    return transformProductToECommerce(productDetails, {
      quantity: product.quantityOpen,
      variantInfo: getVariantInfo(product),
    });
  });
  return items;
};

export default transformLinesToECommerceItems;
