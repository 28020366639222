import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { NavigationHighlight as NavigationHighlightModel } from '~source/core/models/components/organisms/navigation';
import $ from './navigation-highlight.module.scss';

interface Props extends NavigationHighlightModel {
  label: string | JSX.Element;
}

const NavigationHighlight: React.FunctionComponent<Props> = (props) => {
  const { image, link, partnerLogo, label } = props;
  return (
    <div className={$.wrap}>
      <div className={$.imageWrap}>
        {image?.url && image?.alt && (
          <Image
            src={image.url}
            alt={image.alt}
            loading="lazy"
            className={$.image}
            data-object-fit
            width="249"
            height="120"
          />
        )}

        {partnerLogo && (
          <Image
            src={partnerLogo.url}
            alt={partnerLogo.alt}
            loading="lazy"
            className={$.icon}
            width="24"
            height="16"
          />
        )}
      </div>
      <Link href={link.url} className={$.link}>
        {label}
      </Link>
    </div>
  );
};

export default NavigationHighlight;
