import * as Sentry from '@sentry/nextjs';
import { setCoreSetting } from '@springtree/eva-sdk-core-settings';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { DYNAMIC_YIELD_ID, TSD_API_KEY } from '~source/constants';
import { ApplicationConfiguration } from '~source/core/models/application-configuration';
import { Init } from '~source/core/models/components/templates/init';
import getInitialData from '~source/core/services/api/get-initial-data';
import getApplicationConfiguration from '~source/core/services/eva/api/get-application-configuration';
import { setCouponCodeInLocalStorage } from '~source/core/services/local-storage/coupon/coupon-code';
import { assert } from '~source/core/utils/validators';
import CustomError from '~source/ui/components/molecules/custom-error/custom-error';
import Layout from '~source/ui/components/templates/layout/layout';
import { AccountProvider } from '~source/ui/hooks/auth/useAccount/useAccount';
import { MsalProvider } from '~source/ui/hooks/auth/useMsal';
import { useCart, CartProvider } from '~source/ui/hooks/cart/useCart/useCart';
import { Provider as SiteConfigProvider } from '~source/ui/hooks/config/useSiteConfig';
import { FeatureFlagsProvider } from '~source/ui/hooks/feature-flags/useFeatureFlags';
import useExternalScript from '~source/ui/hooks/helper/useExternalScript';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { OrderProvider } from '~source/ui/hooks/order/useOrder/useOrder';
import { Provider as ApplicationConfigProvider } from '~source/ui/hooks/useApplicationConfig';
import composeComponentWrapper from '~source/ui/utils/compose-component-wrapper';
import sendDYEvent from '~source/ui/utils/dynamic-yield/send-dy-event';
import restructureNodes from '~source/ui/utils/restructure-nodes';
import '~source/core/services/eva/load-eva';
import '~source/ui/components/molecules/swiper/swiper-slider.scss';
import '~source/ui/App.global.scss';

type InitialProps = {
  appConfig: ApplicationConfiguration | null;
  initData: Init | null;
};
type AjaxAppProps = InitialProps & AppProps;

function usePagePending() {
  const [pagePending, setPagePending] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    const handlePagePending = () => setPagePending(true);
    const handlePageComplete = () => setPagePending(false);

    router.events.on('routeChangeStart', handlePagePending);
    router.events.on('routeChangeComplete', handlePageComplete);
    router.events.on('routeChangeError', handlePageComplete);

    return () => {
      router.events.off('routeChangeStart', handlePagePending);
      router.events.off('routeChangeComplete', handlePageComplete);
      router.events.off('routeChangeError', handlePageComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return pagePending;
}

function useAppInitializer() {
  const { shoppingCart } = useCart();
  const locale = useLocale();
  const router = useRouter();

  React.useEffect(() => {
    setCoreSetting('language', locale);
  }, [locale]);

  React.useEffect(() => {
    const sendDYEventOnRouteChange = () => {
      sendDYEvent('Sync cart', {
        cart: shoppingCart,
      });
    };

    sendDYEventOnRouteChange();

    router.events.on('routeChangeComplete', sendDYEventOnRouteChange);

    return () => {
      router.events.off('routeChangeComplete', sendDYEventOnRouteChange);
    };
  }, [router.events, shoppingCart]);
}

function Application({ children }: any) {
  useAppInitializer();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

/* eslint-disable react/jsx-key */
const ApplicationWrapper = composeComponentWrapper([
  <SiteConfigProvider />,
  <MsalProvider />,
  <AccountProvider />,
  <OrderProvider />,
  <CartProvider />,
  <Application />,
]);
/* eslint-enable react/jsx-key */

function AjaxApp({ Component, pageProps, appConfig, initData }: AjaxAppProps) {
  useExternalScript({
    src: `//cdn-eu.dynamicyield.com/api/${DYNAMIC_YIELD_ID}/api_dynamic.js`,
  });
  useExternalScript({
    src: `//cdn-eu.dynamicyield.com/api/${DYNAMIC_YIELD_ID}/api_static.js`,
  });

  const router = useRouter();
  const pagePending = usePagePending();
  const googleTagManagerID = initData?.googleTagManagerID;
  const enablePeakProtect = initData?.enablePeakProtect ?? true;
  const localeRedirectToHome = (Component as any).localeRedirectToHome ?? false;

  React.useEffect(() => {
    const { query } = router;
    if (!query.d) return;
    const coupon = query.d;
    setCouponCodeInLocalStorage(coupon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // detecting Google Chrome Translation
  React.useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.documentElement.className.match('translated')) {
        restructureNodes();
      }
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const isPreviewRoute = router.pathname.startsWith('/preview/');

  return (
    <>
      {enablePeakProtect && (
        <Head>
          <script
            key="peakprotect-1"
            src="https://wachtrij.ajax.nl/pkpcontroller/wp/ajaxshop/resources/peakprotectv2.js"
          />
          <script
            key="peakprotect-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
if (typeof PeakProtect !== 'undefined') {
    new PeakProtect(
        'https://wachtrij.ajax.nl',
        '9217dd6ee11964183ca07379e1087b55f764796bcffcc693bf9241ab464313b8',
    ).protect(window.location.href, null, null);
}
                            `.trim(),
            }}
          />
        </Head>
      )}
      <Head>
        {googleTagManagerID && (
          <script
            type="text/javascript"
            key="google-analytics-script"
            id="google-analytics"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
originalLocation: document.location.protocol + '//' +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search
});

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
{'gtm.start': new Date().getTime(),event:'gtm.js'}
);var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerID}');`,
            }}
          />
        )}
        <script
          type="text/javascript"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];`,
          }}
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.TSDAPIKEY = '${TSD_API_KEY}';`,
          }}
        />
        <script
          type="text/javascript"
          src="https://api.270degrees.nl/api/script/latest/viewer.js"
        />
        <script
          type="text/javascript"
          src="https://api.270degrees.nl/api/script/latest/customiser.js"
        />
        <script
          type="text/javascript"
          src="https://ajax.bbvms.com/launchpad/"
        />
      </Head>
      <ApplicationConfigProvider value={{ appConfig, initData }}>
        <Sentry.ErrorBoundary fallback={<CustomError />}>
          <FeatureFlagsProvider>
            <ApplicationWrapper>
              {isPreviewRoute ? (
                <Component {...pageProps} />
              ) : (
                <Layout
                  localeRedirectToHome={localeRedirectToHome}
                  initData={initData}
                  pagePending={pagePending}
                >
                  <Component {...pageProps} />
                </Layout>
              )}
            </ApplicationWrapper>
          </FeatureFlagsProvider>
        </Sentry.ErrorBoundary>
      </ApplicationConfigProvider>
      {googleTagManagerID && (
        <noscript>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
    </>
  );
}

AjaxApp.getInitialProps = async (
  appContext: AppContext,
): Promise<InitialProps> => {
  const { ctx, router } = appContext;

  const { locale } = router;
  assert(locale, 'missing locale.');

  if (ctx.pathname === '/404' || ctx.pathname === '/500') {
    const [appProps, initData] = await Promise.all([
      App.getInitialProps(appContext),
      getInitialData(locale),
    ]);

    return {
      ...appProps,
      appConfig: null,
      initData,
    };
  }

  if (ctx.pathname === '/_error') {
    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      appConfig: null,
      initData: null,
    };
  }

  const [appProps, appConfig, initData] = await Promise.all([
    App.getInitialProps(appContext),
    getApplicationConfiguration(),
    getInitialData(locale),
  ]);

  return {
    ...appProps,
    appConfig,
    initData,
  };
};

export default AjaxApp;
