import urlParse from 'url-parse';

type QueryValue = number | string | boolean | null | undefined;
export type QueryObject = Record<string, QueryValue | QueryValue[]>;

function createUrlQueryPart(
  key: string,
  value: NonNullable<QueryValue>,
): string {
  const stringedValue = value.toString();
  return `${encodeURIComponent(key)}=${encodeURIComponent(stringedValue)}`;
}

export function createQueryString(query: QueryObject) {
  const parts: string[] = [];
  if (!query) return null;

  Object.entries(query).forEach(([key, value]) => {
    if (value != null) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item != null) {
            parts.push(createUrlQueryPart(key, item));
          }
        });
      } else {
        parts.push(createUrlQueryPart(key, value));
      }
    }
  });

  return parts.length > 0 ? parts.join('&') : null;
}

export function addQueryString(url: string, query: QueryObject) {
  const parsedUrl = urlParse(url, true);
  if (parsedUrl === undefined) throw new Error('URL was invalid');
  const path = `${parsedUrl.origin}${parsedUrl.pathname}`;
  const queryString = createQueryString({ ...parsedUrl.query, ...query });
  if (queryString) {
    return `${path}?${queryString}`;
  }
  return path;
}

export function getSingleValue(
  query: { [key: string]: string | string[] | undefined },
  key: string,
): string | null {
  let value = query[key];
  if (Array.isArray(value)) {
    const [firstValue] = value;
    value = firstValue;
  }
  return value ?? null;
}
