import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import {
  SimplifiedProduct,
  EcommerceProductDataToTrack,
  EcommerceProductsDataToTrack,
} from '~source/core/models/payload/e-commerce';
import transformDataToECommerceItem from '~source/core/transformers/e-commerce/transform-data-to-e-commerce-item';
import transformProductToECommerce from '~source/core/transformers/e-commerce/transform-product-to-e-commerce';

const transformProductsToECommerceItems = (
  products: ProductTeaser[] | SimplifiedProduct[],
  data: EcommerceProductsDataToTrack | EcommerceProductDataToTrack,
) => {
  const items = (products as ProductTeaser[]).map((product, i) => {
    const productDetails = {
      name: product.displayValue,
      id: product.productId,
      price: product.price?.sale || product.price?.original,
      collection: product.collection,
      mainCategory: product.mainCategory,
      articleCategory: product.category,
      ageCategory: product.ageCategory,
      configurable: product.configurable,
    };
    return transformProductToECommerce(
      productDetails,
      transformDataToECommerceItem(data, product, i),
    );
  });
  return items;
};

export default transformProductsToECommerceItems;
