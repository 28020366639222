import { BannerImage } from '~source/core/models/components/atoms/banner-image';
import { CategoriesSlider } from '~source/core/models/components/organisms/categories-slider';
import { BannerImageResponse } from '~source/core/models/response/banner-image-response';
import { CategoriesSliderResponse } from '~source/core/models/response/categories-slider-response';
import transformBannerImage from '~source/core/transformers/atoms/transform-banner-image';
import transformButton from '~source/core/transformers/atoms/transform-button';
import uuid from '~source/core/utils/uuid';

export default async function transformCategoriesSlider(
  response: CategoriesSliderResponse,
): Promise<CategoriesSlider> {
  const { banners } = response.content;

  const sliderBanners = banners.map((banner) => {
    const { bannerImages, buttons } = banner;
    const images = bannerImages.reduce(
      (acc: BannerImage[], image: BannerImageResponse) => {
        if (!image.bannerDevice) return acc;
        const transformedImage = transformBannerImage(image);
        return [...acc, transformedImage];
      },
      [],
    );
    const links = buttons.map((button) => transformButton(button));

    return {
      id: uuid(),
      images,
      links,
    };
  });

  return {
    id: uuid(),
    moduleType: 'categoriesSlider',
    banners: sliderBanners,
  };
}
