export function isBundleType(productType: number | undefined) {
  return productType === 2048;
}

export function isBundle(productTypes: (number | undefined)[]) {
  return productTypes.findIndex(isBundleType) >= 0;
}

export function isProductConfigurableType(productType: number | undefined) {
  return productType === 16384;
}

export function isConfigurable(productTypes: (number | undefined)[]) {
  return productTypes.findIndex(isProductConfigurableType) >= 0;
}

export function isStock(productTypes: (number | undefined)[]) {
  return productTypes.findIndex((productType) => productType === 1) >= 0;
}
