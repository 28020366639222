import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import {
  EcommerceProductsDataToTrack,
  EcommerceProductDataToTrack,
  SimplifiedProduct,
  ECommerceInputItem,
} from '~source/core/models/payload/e-commerce';

const transformDataToECommerceItem = (
  data: EcommerceProductsDataToTrack | EcommerceProductDataToTrack,
  product: ProductTeaser | SimplifiedProduct,
  i = 0,
): ECommerceInputItem => {
  const { listId, index, title } = data;
  const location = window.location.pathname;
  const listData = {
    listId,
    listName: title || location,
  };
  return {
    id: product.productId,
    index: index || i,
    listData,
  };
};

export default transformDataToECommerceItem;
