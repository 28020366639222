import {
  ECommerceInputItem,
  TransformedECommerceItem,
  TransformedEcommerceProductDetails,
} from '~source/core/models/payload/e-commerce';

const transformProductToECommerce = (
  productDetails: TransformedEcommerceProductDetails,
  item: ECommerceInputItem,
): TransformedECommerceItem | null => {
  const {
    name,
    id,
    price,
    collection,
    mainCategory,
    articleCategory,
    ageCategory,
    configurable,
  } = productDetails;

  if (!id) throw new Error(`Missing id of product`);

  return {
    item_name: name, // Name or ID is required.
    item_id: id,
    price,
    item_brand: collection || null, // Adidas / Replay / Ajax
    item_category: mainCategory, // Wedstrijd / Training / Kleding / Fan items
    item_category2: articleCategory, // Thuistenue / Uittenue / Derde tenue / Keeperstenue / Kleding / Accessoires / Bob Marley / Replay / and so on.
    item_category3: ageCategory || null, // Senior / Junior / Baby -> if available
    quantity: item.quantity || 1,

    // If associated with a list selection.
    // * Search page, mini grid and swiper don't have list ID
    ...(item.listData && {
      item_list_name: item.listData.listName,
      item_list_id: item.listData.listId || 'not available',
      index: item.index,
    }),

    // If item is in cart and configurable, variant not available at purchase event
    ...(item.variantInfo &&
      configurable && {
        // - Printing (none / your name + number / player name + number)
        // - Badge (none / eredivisie thuis / eredivisie uit /  CL thuis / CL uit)
        // Don't use real names due to privacy
        item_variant: item.variantInfo,
      }),
  };
};

export default transformProductToECommerce;
