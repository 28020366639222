import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import transformOrderIdsToCancelOrderPayload from '~source/core/transformers/payload/transform-order-ids-to-cancel-order-payload';

export async function cancelOrder(
  {
    orderId,
    orderLineIds,
  }: {
    orderId: number;
    orderLineIds: number[];
  },
  authenticationToken?: string | null,
) {
  const payload = transformOrderIdsToCancelOrderPayload(orderLineIds);

  await getEvaService(
    Core.CancelOrder,
    {
      OrderID: orderId,
      OrderLines: payload,
    },
    { authenticationToken: authenticationToken ?? undefined },
  );
}
