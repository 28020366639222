import { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import { CartLine } from '~source/core/models/components/atoms/cart-line';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { assert, assertNumber } from '~source/core/utils/validators';
import getAvailabilityOfProduct from '~source/ui/utils/getters/get-availability-of-product';

export default function transformBadge(
  badgeProduct: CartLine,
  quantityOpen: number,
  availabilityOfProducts: AvailabilityIndication | null,
) {
  const { price, id, productId, lineId, productName, productType } =
    badgeProduct;

  assertNumber(id, 'badgeProduct id is missing');
  assert(price, `badgeProduct price of product ${id} is missing`);

  const displayPrice = price.sale ?? price.original;
  const { availability } = getAvailabilityOfProduct(
    availabilityOfProducts,
    productId as number,
    productType ?? null,
  );

  return {
    id,
    // Hard type set is needed because a badge always has a lineId as opposed to a bundle product
    productBundleLineId: lineId as number,
    name: addFallbackValue(productName, 'badgeProduct name', ''),
    price: {
      original: displayPrice ? quantityOpen * displayPrice : null,
      sale: null,
    },
    availability,
  };
}
