import { ZIPCODE_FALLBACK_VALUE } from '~source/constants';
import { Address } from '~source/core/models/components/atoms/address';

export default function getAddress(address: Address) {
  const {
    address1,
    address2,
    city,
    countryId,
    firstName,
    lastName,
    zipCode,
    houseNumber,
  } = address;
  const addressWithoutHouseNumber = {
    Address1: address1 || undefined,
    Address2: address2 || undefined,
    City: city || undefined,
    CountryID: countryId || undefined,
    FirstName: firstName || undefined,
    LastName: lastName || undefined,
    ZipCode: zipCode || ZIPCODE_FALLBACK_VALUE,
  };
  if (countryId !== 'NL') return addressWithoutHouseNumber;
  return {
    ...addressWithoutHouseNumber,
    HouseNumber: houseNumber || undefined,
  };
}
