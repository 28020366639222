import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';

export default function getAdditionalPrintingValue(
  additionalPrintings: AdditionalPrintings[],
  id: number,
  productRequirements: any, // EVA hasn't typed the product requirements
) {
  const additionalPrintingsForProduct = additionalPrintings.find(
    (printing) => printing.id === id,
  );
  if (!additionalPrintingsForProduct) return null;

  const additionalPrintingForLine = additionalPrintingsForProduct.values?.find(
    (printing) => {
      const hasMatchingId = productRequirements.find(
        (requirement: { id: number }) =>
          requirement.id === printing.productRequirementValueId &&
          printing.value !== null,
      );
      return hasMatchingId;
    },
  );
  if (!additionalPrintingForLine) return null;
  return additionalPrintingForLine.value;
}
