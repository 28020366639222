import { ProductDetailPlayerModel } from '~source/core/models/components/atoms/product-detail-player-model';
import addFallbackValue from '~source/core/utils/add-fallback-value';

export default function transformPlayers(players: {
  [key: string]: string;
}): ProductDetailPlayerModel[] {
  return Object.entries(players).map(([key, name]) => {
    return {
      image: null,
      number: parseInt(key, 10),
      name: addFallbackValue(name, 'Player name', ''),
    };
  });
}
