import * as React from 'react';
import Button from '~source/ui/components/atoms/buttons/button/button';
import { Portal } from '~source/ui/components/atoms/portal/portal';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import $ from './popup.module.scss';

type Props = {
  acceptOnClick?: () => void;
  acceptLabel?: string;
  cancelOnClick?: () => void;
  cancelLabel?: string;
};

export const Popup: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  acceptLabel: _acceptLabel,
  acceptOnClick,
  cancelLabel: _cancelLabel,
  cancelOnClick,
}) => {
  const t = useTranslate();
  const acceptLabel = _acceptLabel ?? t('POPUP_ACCEPT_LABEL');
  const cancelLabel = _cancelLabel ?? t('POPUP_CANCEL_LABEL');

  return (
    <Portal>
      <div className={$.container}>
        <div aria-live="polite" className={$.popup}>
          <div>{children}</div>
          <div className={$.buttonContainer}>
            {cancelOnClick && (
              <Button onClick={cancelOnClick} variant="grey">
                {cancelLabel}
              </Button>
            )}
            {acceptOnClick && (
              <Button onClick={acceptOnClick} variant="red">
                {acceptLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};
