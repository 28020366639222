import * as React from 'react';

export default function useOnUnmount(cb: () => void) {
  const cbRef = React.useRef(cb);

  React.useEffect(() => {
    cbRef.current = cb;
  }, [cb]);

  React.useEffect(
    () => () => {
      cbRef.current();
    },
    [],
  );
}
