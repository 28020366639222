import { ORGANIZATION_UNIT_ID } from '~source/constants';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

const organizationUnitID = Number(ORGANIZATION_UNIT_ID);

export async function createOrder({
  authenticationToken,
}: {
  authenticationToken: string | null;
}) {
  const response = await getEvaService(
    Core.CreateOrder,
    {
      OrganizationUnitID: organizationUnitID,
      Type: 0,
    },
    { authenticationToken: authenticationToken ?? undefined },
  );

  if (!response) return null;

  return response.OrderID;
}
