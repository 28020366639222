import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useRef, useState } from 'react';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';
import useOnClickOutside from '~source/ui/hooks/ui/useOnClickOutside/useOnClickOutside';

export const useSearch = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const searchRef = useRef<HTMLFormElement | null>(null);
  const { isTablet } = useBreakpoints();

  const openSearch = () => {
    setIsSearchOpen(true);
    !isTablet && searchRef.current && disableBodyScroll(searchRef.current);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
    searchRef.current && enableBodyScroll(searchRef.current);
  };

  const toggleSearch = () => {
    if (isSearchOpen) {
      closeSearch();
      return;
    }
    openSearch();
  };

  useOnClickOutside(searchRef, (e) => {
    if (searchButtonRef.current?.contains(e.target as Node) && isSearchOpen)
      return;

    closeSearch();
  });

  return {
    searchRef,
    searchButtonRef,
    isSearchOpen,
    setIsSearchOpen,
    openSearch,
    closeSearch,
    toggleSearch,
  };
};
