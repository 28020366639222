import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

type ModifyQuantityOrderedPayload = {
  orderLineId: number;
  newQuantity: number;
};

export const modifyQuantityOrdered = async (
  { orderLineId, newQuantity }: ModifyQuantityOrderedPayload,
  authenticationToken?: string | null,
) => {
  return getEvaService(
    Core.ModifyQuantityOrdered,
    {
      Lines: [
        {
          OrderLineID: orderLineId,
          NewQuantityOrdered: newQuantity,
        },
      ],
    },
    {
      authenticationToken: authenticationToken ?? undefined,
    },
  );
};
