/* eslint-disable no-undef */
import {
  CarryOutStoreType,
  StoreAddress,
  StoreType,
} from '~source/core/models/components/atoms/store-type';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { assertNumber } from '~source/core/utils/validators';

const transformOrganizationUnitAddress = (
  id: number,
  address: EVA.Core.AddressDto,
): StoreAddress => ({
  type: 'store-address',
  organizationUnitID: id,
  firstName: address?.FirstName ?? null,
  lastName: address?.LastName ?? null,
  address1: address?.Address1 ?? null,
  address2: address?.Address2 ?? null,
  houseNumber: address?.HouseNumber ?? null,
  zipCode: address?.ZipCode ?? null,
  city: address?.City ?? null,
  region: address?.Region ?? null,
  countryId: address?.CountryID ?? 'NL',
});

export const transformStore = (
  { Address, Name, ID }: EVA.Core.ProductAvailabilityPickupOrganizationUnit,
  areAllProductsAvailableHere: boolean,
): StoreType => {
  assertNumber(
    ID,
    'PickupOrganizationUnitID in GetProductAvailability response is missing',
  );
  return {
    address: transformOrganizationUnitAddress(ID, Address),
    areAllProductsAvailableHere,
    name: addFallbackValue(Name, 'Store name', ''),
    organizationUnitID: ID,
  };
};

export const transformCarryOutStore = ({
  Address,
  Name,
  ID,
  HasStock,
}: EVA.Core.ProductAvailabilityCarryOutOrganizationUnit): CarryOutStoreType => {
  assertNumber(
    ID,
    'CarryOutOrganizationUnitID in GetProductAvailability response is missing',
  );
  return {
    address: transformOrganizationUnitAddress(ID, Address),
    hasStock: HasStock,
    name: addFallbackValue(Name, 'CarryOut store name', ''),
    organizationUnitID: ID,
  };
};
