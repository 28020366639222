import { Init } from '~source/core/models/components/templates/init';
import * as api from '~source/core/services/api/get';
import transformInit from '~source/core/transformers/api/transform-init';

export default async function getInitialData(
  locale: string,
): Promise<Init | null> {
  const response = await api.get({
    path: '/',
    culture: locale,
  });

  return transformInit(response);
}
