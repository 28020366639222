// When using Google Translate on a page using React, a certain code pattern produces a Javascript error when the rendered content changes
// There is a workaround you can use that will fix the error
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
export default function restructureNodes() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;

    // eslint-disable-next-line func-names
    Node.prototype.removeChild = function <T extends Node>(child: T): T {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments as any) as any;
    };

    const originalInsertBefore = Node.prototype.insertBefore;

    // eslint-disable-next-line func-names
    Node.prototype.insertBefore = function <T extends Node>(
      newNode: T,
      referenceNode: Node | null,
    ): T {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments as any) as any;
    };
  }
}
