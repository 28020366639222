import Image from 'next/image';
import { useRouter } from 'next/router';
import { ChangeEvent, useEffect, useState, useRef, useCallback } from 'react';
import * as React from 'react';
import { searchProducts } from '~source/core/services/eva/api/search-products';
import useAbortableFetch from '~source/ui/hooks/helper/useAbortableFetch/useAbortableFetch';
import useDebouncedValue from '~source/ui/hooks/helper/useDebouncedValue/useDebouncedValue';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import useClientSide from '~source/ui/hooks/ui/useClientSide/useClientSide';
import { cx } from '~source/ui/utils/join-classnames';
import focus from '~source/ui/utils/ui/focus';
import parseQuery from '~source/ui/utils/urls/parse-query';
import { getSingleValue } from '~source/ui/utils/urls/url-query';
import SearchInputItem from './search-input-item/search-input-item';
import $ from './search.module.scss';

interface Props {
  autofocus?: boolean;
  isOpen: boolean;
  searchRef: React.MutableRefObject<HTMLFormElement | null>;
  searchButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  toggleSearch: () => void;
  closeSearch: () => void;
}

export default function SearchInput(props: Props) {
  const {
    autofocus,
    isOpen,
    toggleSearch,
    searchRef,
    searchButtonRef,
    closeSearch,
  } = props;
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const [isFocussed, setIsFocussed] = useState(false);
  const [currentFocusIndex, setCurrentFocusIndex] = useState(-1);
  const router = useRouter();
  const [query, setQuery] = useState(
    () => getSingleValue(router.query, 'query') ?? '',
  );
  const locale = useLocale();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isClientSide = useClientSide();
  const suggestionElementRef = useRef<HTMLUListElement | null>(null);
  const t = useTranslate();
  const suggestionOptions = React.useMemo(
    () => ({ query, locale }),
    [query, locale],
  );
  const debouncedSuggestionOptions = useDebouncedValue(suggestionOptions, 300);
  const searchOptions = React.useMemo(() => {
    return {
      query: debouncedSuggestionOptions.query,
      pageSize: 5,
      locale: debouncedSuggestionOptions.locale,
      filters: {
        logical_level: {
          IncludeMissing: true,
          Values: ['root'],
        },
        searchable: {
          IncludeMissing: true,
          Values: ['true'],
        },
      },
    };
  }, [debouncedSuggestionOptions]);

  const [suggestions] = useAbortableFetch(searchProducts, searchOptions);
  const products = suggestions?.products ?? [];

  // reset the showSuggestions when it changed
  React.useEffect(() => {
    setShowSuggestions(true);
  }, [suggestions]);

  const checkQuery = () => {
    const params = parseQuery(window.location.search);
    const { q: queryFromUrl } = params;
    if (queryFromUrl) {
      const decodedQuery = queryFromUrl.replace(/\+/g, ' ');
      setQuery(decodedQuery);
    }
  };

  const focusOnInput = useCallback(() => {
    if (!isClientSide || !inputRef.current) return;
    focus(inputRef.current);
  }, [isClientSide]);

  const goToPreviousItem = () => {
    const suggestionElement = suggestionElementRef.current;
    if (suggestions === null || !suggestionElement || currentFocusIndex <= 0)
      return;

    setCurrentFocusIndex(currentFocusIndex - 1);
    const prevEl: HTMLElement | null = suggestionElement.querySelector(
      `[data-suggestion="${products[currentFocusIndex - 1].displayValue}"]`,
    );
    prevEl?.focus();
  };

  const goToNextItem = () => {
    if (
      suggestions === null ||
      currentFocusIndex === products.length - 1 ||
      !suggestionElementRef ||
      !suggestionElementRef.current
    )
      return;

    setCurrentFocusIndex(currentFocusIndex + 1);
    const nextEl: HTMLElement | null =
      suggestionElementRef.current.querySelector(
        `[data-suggestion="${products[currentFocusIndex + 1].displayValue}"]`,
      );
    nextEl?.focus();
  };

  const selectSuggestion = (suggestion: string) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    inputRef.current?.focus();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleInputKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      closeSearch();
      searchButtonRef.current?.focus();
      return;
    }

    if (
      !suggestions ||
      e.key !== 'ArrowDown' ||
      !suggestionElementRef ||
      !suggestionElementRef.current
    )
      return;
    e.preventDefault();

    setCurrentFocusIndex(0);
    const firstEl: HTMLElement | null =
      suggestionElementRef.current.querySelector(
        `[data-suggestion="${products[0].displayValue}"]`,
      );
    firstEl?.focus();
  };

  const handleSuggestionKey = (
    e: React.KeyboardEvent<HTMLLIElement>,
    suggestion: string,
  ) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        selectSuggestion(suggestion);
        break;
      case 'ArrowDown':
        goToNextItem();
        break;
      case 'ArrowUp':
        goToPreviousItem();
        break;
      default:
        break;
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();

    if (products.length === 0) {
      closeSearch();
      return;
    }

    if (router.pathname === '/search') {
      router.replace(
        {
          pathname: '/search',
          query: {
            query,
          },
        },
        undefined,
        {
          shallow: true,
        },
      );
      return;
    }

    router.push({
      pathname: '/search',
      query: {
        query,
      },
    });
  };

  useEffect(() => {
    isOpen && autofocus && focusOnInput();
  }, [isOpen, focusOnInput, autofocus]);

  useEffect(() => {
    checkQuery();
    // Only needs to be checked once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      id="shop-search"
      role="search"
      className={cx($.form, isFocussed && $.formFocus, isOpen && $.formOpen)}
      onSubmit={handleSearch}
      ref={searchRef}
    >
      <div className={$.reveal}>
        <input
          className={$.input}
          name="q"
          type="text"
          aria-label={t('SEARCH')}
          placeholder={t('SEARCH_PLACEHOLDER')}
          autoComplete="off"
          value={query}
          onFocus={() => setIsFocussed(true)}
          onBlur={() => setIsFocussed(false)}
          onChange={handleInputChange}
          onKeyUp={handleInputKey}
          ref={inputRef}
        />
        <button
          className={$.clear}
          type="button"
          aria-label={t('CLEAR_SEARCH')}
          onClick={() => setQuery('')}
        >
          <Image
            src="/shop/images/icons/ic-clear.svg"
            width="24"
            height="24"
            loading="lazy"
            alt=""
          />
        </button>
        {showSuggestions &&
          query &&
          suggestions !== null &&
          products.length > 0 && (
            <ul className={$.suggestions} ref={suggestionElementRef}>
              {products.map((suggestion) => (
                <SearchInputItem
                  suggestionItem={suggestion}
                  query={query}
                  onKeyUp={(e) =>
                    handleSuggestionKey(e, suggestion.displayValue)
                  }
                  key={suggestion.productId}
                />
              ))}
            </ul>
          )}
      </div>
      <button
        type="button"
        aria-label={t(isOpen ? 'SEARCH_CLOSE' : 'SEARCH_OPEN')}
        className={cx($.trigger, $.triggerDesktop)}
        aria-expanded={isOpen}
        aria-controls="shop-search"
        onClick={toggleSearch}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path
            d="M10.5 2a8.5 8.5 0 000 17 8.458 8.458 0 005.64-2.153L21.293 22l.707-.707-5.153-5.153A8.458 8.458 0 0019 10.5 8.5 8.5 0 0010.5 2m0 1c4.136 0 7.5 3.364 7.5 7.5a7.486 7.486 0 01-1.9 4.976l-.294.33-.33.294A7.486 7.486 0 0110.5 18C6.364 18 3 14.636 3 10.5S6.364 3 10.5 3"
            fill="#fff"
            fillRule="nonzero"
          />
        </svg>
      </button>
    </form>
  );
}
