import { ThirdPartyProductInfo } from '~source/core/models/components/templates/order';
import { DynamicYieldCartItem } from '~source/core/models/payload/dy-cart-item';
import createUniqueDYProductsList from '~source/ui/utils/dynamic-yield/create-unique-dy-products-list';

function transformProductToDYFormat(product: ThirdPartyProductInfo) {
  const { sku, quantity, price, size } = product;
  return {
    productId: sku,
    quantity,
    itemPrice: price,
    size,
  };
}

export default function transformThirdPartyProductInfoToDYFormat(
  products: ThirdPartyProductInfo[],
): DynamicYieldCartItem[] {
  const transformedProducts = products.map((product) =>
    transformProductToDYFormat(product),
  );
  return createUniqueDYProductsList(transformedProducts);
}
