import * as React from 'react';
import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import useClientSide from '~source/ui/hooks/ui/useClientSide/useClientSide';

interface Props {
  disableScrollBlock?: boolean;
}

export const Portal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  disableScrollBlock = false,
}) => {
  const clientSide = useClientSide();

  if (!clientSide) return null;

  let render = <>{children}</>;

  if (disableScrollBlock === false) {
    render = <RemoveScroll>{render}</RemoveScroll>;
  }

  return createPortal(render, document.body);
};
