export default function parseQuery(
  query: string,
): Record<string, string | undefined> {
  if (query.length === 0) return {};

  // Decode to normal string
  // Remove '?' begin of string
  // Split on '&' character
  // Fetch key and value for each part and store theme in accumulator
  return decodeURI(query)
    .replace(/^\?/g, '')
    .split('&')
    .reduce<Record<string, string | undefined>>((acc, part: string) => {
      const keyValueSepIndex: number = part.indexOf('=');
      // No separator found or no key found
      if (keyValueSepIndex === -1 || keyValueSepIndex === 0) {
        return acc;
      }

      const key = part.substring(0, keyValueSepIndex);
      const value = part.substring(keyValueSepIndex + 1, part.length);

      return { ...acc, [key]: value };
    }, {});
}
