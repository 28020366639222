import * as msal from '@azure/msal-browser';
import * as Sentry from '@sentry/nextjs';
import * as React from 'react';
import {
  accountInformationIsEmployee,
  acquireTokenSilentMsal,
  getMsalApp,
  loginMsal,
  logoutMsal,
  signUpMsal,
} from '~source/core/services/msal';
import useAutoSignInFromSite from '~source/ui/hooks/auth/useAutoSignInFromSite/useAutoSignInFromSite';

type AuthStatus = 'pending' | 'logged-in' | 'logged-out';

type MsalContextType = {
  msalAuthStatus: AuthStatus;
  msalAuthInfo: msal.AuthenticationResult | null;
  msalAuthIsEmployee: boolean;
  msalApp: msal.PublicClientApplication | null;
  msalSignIn: () => Promise<void>;
  msalSignUp: () => Promise<void>;
  msalSignOut: () => Promise<void>;
};

const MsalContext = React.createContext<MsalContextType | null>(null);

export function useMsal() {
  const context = React.useContext(MsalContext);
  if (!context) throw new Error('[useMsal] No context found');
  return context;
}

export function MsalProvider({ children }: React.PropsWithChildren<unknown>) {
  const [msalApp, setMsalApp] =
    React.useState<msal.PublicClientApplication | null>(null);

  const [authStatus, setAuthStatus] = React.useState<AuthStatus>('pending');
  const [authInfo, setAuthInfo] =
    React.useState<msal.AuthenticationResult | null>(null);

  const handleAutoSignInSuccess = React.useCallback(
    (result: msal.AuthenticationResult) => {
      setAuthInfo(result);
      setAuthStatus('logged-in');
    },
    [],
  );

  useAutoSignInFromSite({
    onSuccess: handleAutoSignInSuccess,
  });

  React.useEffect(() => {
    (async () => {
      await getMsalApp().then(setMsalApp);

      try {
        const result = await acquireTokenSilentMsal();
        setAuthInfo(result);
        setAuthStatus('logged-in');
      } catch (error) {
        Sentry.captureException(error);
        setAuthStatus('logged-out');
      }
    })();
  }, [authStatus, handleAutoSignInSuccess]);

  const isEmployee = authInfo ? accountInformationIsEmployee(authInfo) : false;

  const contextValue = React.useMemo<MsalContextType>(
    () => ({
      msalApp,
      msalAuthStatus: authStatus,
      msalAuthInfo: authInfo,
      msalAuthIsEmployee: isEmployee,
      msalSignIn: loginMsal,
      msalSignUp: signUpMsal,
      msalSignOut: logoutMsal,
    }),
    [authInfo, authStatus, isEmployee, msalApp],
  );

  return (
    <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>
  );
}
