class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    this.message = `AssertionError: ${message}`;
  }
}

function assertString(
  value: any,
  message = 'Value was not a string.',
): asserts value is string {
  if (typeof value !== 'string') {
    throw new AssertionError(`${message}`);
  }
}

function assertNumber(
  value: any,
  message = 'Value was not a number.',
): asserts value is number {
  if (typeof value !== 'number') {
    throw new AssertionError(`${message}`);
  }
}

function assert(value: any, message = 'Value was not defined.'): asserts value {
  if (value == null) {
    throw new AssertionError(`${message}`);
  }
}

export { assert, assertString, assertNumber };
