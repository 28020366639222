import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';
import {
  Printing,
  PrintingType,
} from '~source/core/models/components/organisms/product-details';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';

export function createRequirementPayload(
  printingOptionsInfo: Printing | undefined,
  requirements: AdditionalPrintings[],
  value: string | number | null,
): UpdateProductRequirementsForOrderPayload | null {
  if (!printingOptionsInfo) return null;
  const requirement = requirements.find(
    (req) => req.productId === printingOptionsInfo.id,
  );
  if (!requirement) return null;
  return {
    orderLineId: requirement.id,
    values: {
      [printingOptionsInfo.productRequirementValueId]: value,
    },
  };
}

export function createProductRequirementsPayload({
  printingOptionsInfo,
  printing,
  backNumber,
  printingType,
  requirements,
}: {
  backNumber: string | null;
  printing: string | null;
  printingOptionsInfo: Printing[] | null;
  printingType: PrintingType;
  requirements: AdditionalPrintings[];
}) {
  const payload: UpdateProductRequirementsForOrderPayload[] = [];
  if (!printingOptionsInfo || printingType === 'none') return payload;
  if (printingType === 'custom' && backNumber) {
    const info = printingOptionsInfo.find(
      (option) => !option.isPlayer && option.printing === 'number',
    );
    const requirement = createRequirementPayload(
      info,
      requirements,
      Number(backNumber),
    );
    if (!requirement) return payload;
    payload.push(requirement);
  }
  if (printingType === 'custom' && printing) {
    const info = printingOptionsInfo.find(
      (option) => !option.isPlayer && option.printing === 'letters',
    );
    const requirement = createRequirementPayload(info, requirements, printing);
    if (!requirement) return payload;
    payload.push(requirement);
  }
  if (printingType === 'player' && backNumber) {
    const numberInfo = printingOptionsInfo.find(
      (option) => option.isPlayer && option.printing === 'number',
    );
    const numberRequirement = createRequirementPayload(
      numberInfo,
      requirements,
      backNumber,
    );
    if (!numberRequirement) return payload;
    payload.push(numberRequirement);
    const letterInfo = printingOptionsInfo.find(
      (option) => option.isPlayer && option.printing === 'letters',
    );
    const letterRequirement = createRequirementPayload(
      letterInfo,
      requirements,
      backNumber,
    );
    if (!letterRequirement) return payload;
    payload.push(letterRequirement);
  }
  return payload;
}

type UpdateProductRequirementsForOrderPayload = {
  orderLineId: number;
  values: Record<number, string | number | null>;
};

export async function updateProductRequirementsForOrder(
  payload: UpdateProductRequirementsForOrderPayload,
  authenticationToken?: string | null,
) {
  await getEvaService(
    Core.UpdateProductRequirementValuesForOrderLine,
    {
      OrderLineID: payload.orderLineId,
      Values: payload.values,
    },
    { authenticationToken: authenticationToken ?? undefined },
  );
}

export function addAdditionalRequirementsToBasket({
  printingOptionsInfo,
  printing,
  backNumber,
  printingType,
  requirements,
  authenticationToken,
}: {
  printing: string | null;
  backNumber: string | null;
  printingType: PrintingType;
  requirements: AdditionalPrintings[];
  authenticationToken: string | null;
  printingOptionsInfo: Printing[] | null;
}) {
  const requirementsList = createProductRequirementsPayload({
    printingOptionsInfo,
    printing,
    backNumber,
    printingType,
    requirements,
  });

  requirementsList.forEach(async (requirement) => {
    await updateProductRequirementsForOrder(requirement, authenticationToken);
  });
}
