import * as React from 'react';

export default function AccountIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="28" height="28">
      <g fill="none">
        <path d="M2.333 2.333h23.333v23.333H2.333z" />
        <path
          fill="currentColor"
          d="M14 2.333c6.443 0 11.667 5.224 11.667 11.667 0 3.3-1.37 6.28-3.573 8.402l-.033-.105c-1.192-3.476-4.347-5.964-8.061-5.964-3.83 0-7.065 2.646-8.167 6.295l.072-.227A11.63 11.63 0 012.333 14C2.333 7.557 7.557 2.333 14 2.333zm0 5.834a3.5 3.5 0 10-.001 6.999A3.5 3.5 0 0014 8.167z"
        />
      </g>
    </svg>
  );
}
