/* eslint-disable camelcase */
import {
  ProductStatus,
  ProductTeaser,
} from '~source/core/models/components/molecules/product-teaser';
import addFallbackValue from '~source/core/utils/add-fallback-value';
import { getProductPrice } from '~source/ui/utils/getters/get-product-price';
import { createProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';

export function getProductStatus(productStatuses: number[]): ProductStatus {
  if (!Array.isArray(productStatuses)) return null;
  if (productStatuses.find((status: number) => status === 1)) {
    return 'pre-sale';
  }
  if (productStatuses.find((status: number) => status === 0)) {
    return 'future-sale';
  }
  return null;
}

export default function transformSearchProducts(
  response: EVA.Core.GetProductsResponse,
): ProductTeaser[] {
  const { Products } = response;
  const products: ProductTeaser[] = [];

  if (!Products) return products;

  for (const item of Object.values(Products)) {
    const {
      currency_id,
      display_price,
      display_value,
      product_id,
      primary_image,
      original_price,
      marketing_titel,
      slug,
      product_statuses,
      collectie,
      hoofd_categorie,
      artikel_categorie,
      leeftijd_groep,
      ontwerp_zelf,
      shirt_type,
    } = item;

    if (!product_id)
      console.warn(
        `Required '${product_id}' is missing in GetProducts response`,
      );
    if (!currency_id)
      console.warn(
        `Required currency_id of product '${product_id}' is missing in GetProducts response`,
      );
    if (!slug)
      console.warn(
        `Required slug of product '${product_id}' is missing in GetProducts response`,
      );

    const name = primary_image?.name || display_value;
    const primaryImage = {
      blob: addFallbackValue(
        primary_image?.blob,
        `primary_image.blob of product ${product_id}`,
        '',
      ),
      name: addFallbackValue(name, 'name', ''),
      type: addFallbackValue(
        primary_image?.type,
        `primary_image.type of product ${product_id}`,
        '',
      ),
    };
    const category = addFallbackValue(
      artikel_categorie,
      'artikel_categorie',
      '',
    );
    const collection = addFallbackValue(collectie, 'collectie', '');
    const mainCategory = addFallbackValue(
      hoofd_categorie,
      'hoofd_categorie',
      '',
    );
    const ageCategory = addFallbackValue(leeftijd_groep, 'leeftijd_groep', '');
    const configurable = addFallbackValue(ontwerp_zelf, 'ontwerp_zelf', '');

    const product: ProductTeaser = {
      currencyId: currency_id,
      displayValue: marketing_titel || display_value,
      productId: product_id,
      primaryImage,
      slug,
      price: getProductPrice(display_price, original_price),
      productStatus: getProductStatus(product_statuses),
      path: createProductDetailUrl(slug, product_id),
      evaProperties: [],
      category,
      collection,
      mainCategory,
      ageCategory,
      configurable,
      shirtType: shirt_type || '',
    };

    if (product_id && currency_id && slug) {
      products.push(product);
    }
  }

  return products;
}
