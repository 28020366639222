import { LinkModel } from '~source/core/models/components/atoms/link';
import { LinkResponse } from '~source/core/models/response/link-response';

const REGEXP_IS_EXTERNAL_ADDRESS = /^https?:\/\//;
const REGEXP_HAS_LEADING_SLASH = /^\//;

function parseLinkUrl(link: LinkResponse): {
  url: string;
  isExternal: boolean;
} {
  const { url } = link;

  if (link.responseType === 'ContentPage') {
    return { url: `/content${url}`, isExternal: false };
  }

  if (link.responseType === 'ProductOverview') {
    return { url, isExternal: false };
  }

  if (REGEXP_IS_EXTERNAL_ADDRESS.test(url)) {
    return { url, isExternal: true };
  }

  if (!REGEXP_HAS_LEADING_SLASH.test(url)) {
    return {
      url: `/${url}`,
      isExternal: false,
    };
  }

  return {
    url,
    isExternal: false,
  };
}

// Only leave out the index if the Link object is not a part of an array
export default function transformLink(
  link: LinkResponse,
  index = '0',
): LinkModel {
  return {
    id: index.toString(),
    name: link.name,
    target: link.target,
    ...parseLinkUrl(link),
  };
}
