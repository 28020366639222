import { useState, useEffect } from 'react';

export default function useClientSide() {
  // Initially the values should always be false to be consistent
  // on both serverside and clientside to avoid different outputs.
  const [clientSide, setClientSide] = useState(false);

  // only the client will trigger the `useEffect`.
  useEffect(() => {
    setClientSide(true);
  }, []);

  return clientSide;
}
