import type { ModuleTypeResponse } from '~source/core/models/response/module-type-response';
import type { ModuleType } from '~source/core/models/unions/module-type';
import {
  transformCodeBlock,
  transformContentButton,
  transformImageBlock,
  transformRichContent,
} from '~source/core/transformers/api/transform-content-page-blocks';
import transformHorizontalDivider from '~source/core/transformers/atoms/transform-horizontal-divider';
import transformLoginButton from '~source/core/transformers/atoms/transform-login-button';
import transformBannersCarousel from '~source/core/transformers/page-modules/transform-banners-carousel';
import transformBannersSlider from '~source/core/transformers/page-modules/transform-banners-slider';
import transformCategoriesSlider from '~source/core/transformers/page-modules/transform-categories-slider';
import { transformFilterBar } from '~source/core/transformers/page-modules/transform-filter-bar';
import transformHighlights from '~source/core/transformers/page-modules/transform-highlights';
import transformPlayerBanner from '~source/core/transformers/page-modules/transform-player-banner';
import transformProductGrid from '~source/core/transformers/page-modules/transform-product-grid';
import transformProductHeader from '~source/core/transformers/page-modules/transform-product-header';
import transformProductRecommendationsSlider from '~source/core/transformers/page-modules/transform-product-recommendations-slider';
import transformProductsList from '~source/core/transformers/page-modules/transform-products-list';
import transformShirtConfigurator from '~source/core/transformers/page-modules/transform-shirt-configurator';
import transformTakeoverBanner from '~source/core/transformers/page-modules/transform-takeover-banner';
import transformTextBlock from '~source/core/transformers/page-modules/transform-text-block';
import transformVideoPlayer from '~source/core/transformers/page-modules/transform-video-player';
import transformWelcomeText from '~source/core/transformers/page-modules/transform-welcome-text';

export default async function transformModules(
  responses: ModuleTypeResponse[] | undefined,
): Promise<ModuleType[]> {
  const promises: Promise<ModuleType>[] = [];

  if (!responses) return [];

  responses.forEach((response, index) => {
    switch (response.component) {
      case 'HighlightBannerComponent':
        promises.push(transformHighlights(response));
        break;
      case 'ProductSliderComponent':
      case 'MinimalProductGridComponent':
        promises.push(transformProductsList(response));
        break;
      case 'MultipleBannerSliderComponent':
        promises.push(transformBannersCarousel(response));
        break;
      case 'ShirtConfiguratorComponent':
        promises.push(transformShirtConfigurator(response));
        break;
      case 'productCategoryHeaderComponentSplit':
        promises.push(transformProductHeader(response, index));
        break;
      case 'productCategoryHeaderComponentFullWidth':
        promises.push(transformProductHeader(response, index));
        break;
      case 'ProductGridComponent':
        promises.push(transformProductGrid(response));
        break;
      case 'ImageBlockComponent':
        promises.push(transformImageBlock(response, index));
        break;
      case 'ButtonsComponent':
        promises.push(transformContentButton(response));
        break;
      case 'CodeBlockContentComponent':
        promises.push(transformCodeBlock(response, index));
        break;
      case 'RichContentComponent':
        promises.push(transformRichContent(response, index));
        break;
      case 'WelcomeTextComponent':
        promises.push(transformWelcomeText(response));
        break;
      case 'ProductFiltersComponent':
        promises.push(transformFilterBar(response));
        break;
      case 'TextBlockComponent':
        promises.push(transformTextBlock(response));
        break;
      case 'DividerLineComponent':
        promises.push(transformHorizontalDivider(response));
        break;
      case 'PlayerBannerComponent':
        promises.push(transformPlayerBanner(response));
        break;
      case 'TakeoverBannerComponent':
        promises.push(transformTakeoverBanner(response));
        break;
      case 'VideoComponent':
        promises.push(transformVideoPlayer(response));
        break;
      case 'MultipleBannerSliderComponentFullWidth':
        promises.push(transformBannersSlider(response));
        break;
      case 'MultipleCategorySliderComponent':
        promises.push(transformCategoriesSlider(response));
        break;
      case 'DyProductRecommendationsSliderComponentResponseModel':
        promises.push(transformProductRecommendationsSlider(response));
        break;
      case 'LoginButtonComponent':
        promises.push(transformLoginButton(response));
        break;
      default:
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            `Failed to transform moduleType: "${
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              response.component || 'UNKNOWN'
            }"`,
          );
        }
        break;
    }

    return promises;
  }, []);

  return Promise.all(promises);
}
