import Link from 'next/link';
import * as React from 'react';

interface Props {
  ariaLabel?: string;
  children: string | React.ReactNode | React.ReactNode[];
  className?: string;
  isExternal?: boolean;
  link: string;
  target?: string;
  title?: string;
  onClick?: () => void;
}
const AjaxLink = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      ariaLabel,
      isExternal,
      children,
      className,
      link,
      title,
      target,
      onClick,
    },
    ref,
  ) => {
    if (isExternal) {
      return (
        <a
          ref={ref}
          className={className}
          target={target || undefined}
          title={title || undefined}
          href={link}
          rel="noopener noreferrer"
          aria-label={ariaLabel || undefined}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        href={link}
        ref={ref}
        className={className}
        target={target || undefined}
        title={title || undefined}
        aria-label={ariaLabel || undefined}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  },
);

export default AjaxLink;
