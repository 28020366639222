var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VoeLCkuDVlhMQ4iNY6bUv"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/shop";

import * as Sentry from '@sentry/nextjs';
import { config } from './sentry-config';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

/* Sentry's documentation recommends keeping the config for the server and the config for
 * the client the same but that breaks our implementation. In order for it to work, we
 * need to have an additional conditon in the server config that checks if the value of the
 * the env variables aren't the non-replaced values. In the client we don't need that check
 * otherwise the is application runs without Sentry
 */
if (SENTRY_DSN && SENTRY_ENVIRONMENT) {
  Sentry.init(config(true));
} else {
  console.info('Running application without Sentry');
}
