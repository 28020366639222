import { FilterBarPageModule } from '~source/core/models/components/molecules/filter-bar';
import { FilterBarPageModuleResponse } from '~source/core/models/response/filter-bar-page-module-response';
import uuid from '~source/core/utils/uuid';

export async function transformFilterBar(
  response: FilterBarPageModuleResponse,
): Promise<FilterBarPageModule> {
  return {
    moduleType: 'filterBar',
    id: uuid(),
    filters: response.content.filters.map((filter) => ({
      id: uuid(),
      type: 'multi',
      label: filter.content.labelName,
      property: filter.content.evaPropertyName,
      options: filter.content.options.map((option) => ({
        id: uuid(),
        label: option.content.label,
        property: option.content.value,
      })),
    })),
  };
}
