import { useState, useEffect } from 'react';
import {
  BREAK_PHONE_VALUE,
  BREAK_SMALL_TABLET_VALUE,
  BREAK_TABLET_VALUE,
  BREAK_TABLET_LS_VALUE,
  BREAK_LAPTOP_VALUE,
  BREAK_LAPTOP_BIG_VALUE,
} from '~source/constants/';
import debounce from '~source/ui/utils/debounce';

function IsPhone(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth <= BREAK_PHONE_VALUE
    : false;
}

function IsSmallTablet(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth >= BREAK_SMALL_TABLET_VALUE
    : false;
}

function IsTablet(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth >= BREAK_TABLET_VALUE
    : false;
}

function IsTabletLandscape(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth >= BREAK_TABLET_LS_VALUE
    : false;
}

function IsLaptop(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth >= BREAK_LAPTOP_VALUE
    : false;
}

function IsBigLaptop(): boolean {
  return typeof window !== 'undefined'
    ? window.innerWidth >= BREAK_LAPTOP_BIG_VALUE
    : false;
}

function useBreakpoints() {
  // Initially the values should always be false to be consistent
  // on both serverside and clientside to avoid different outputs.
  const [isPhone, setIsPhone] = useState(false);
  const [isSmallTablet, setIsSmallTablet] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isTabletLandscape, setIsTabletLandscape] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isBigLaptop, setIsBigLaptop] = useState(false);

  useEffect(() => {
    const handler = debounce(
      () => {
        setIsPhone(IsPhone());
        setIsSmallTablet(IsSmallTablet());
        setIsTablet(IsTablet());
        setIsTabletLandscape(IsTabletLandscape());
        setIsLaptop(IsLaptop());
        setIsBigLaptop(IsBigLaptop());
      },
      300,
      true,
    );
    // Initially set the devices.
    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return {
    isPhone,
    isSmallTablet,
    isTablet,
    isTabletLandscape,
    isLaptop,
    isBigLaptop,
  };
}

export default useBreakpoints;
