import { AdditionalPrintings } from '~source/core/models/components/atoms/additional-printings';

export default function getCustomGatewayImageUrl(
  lineId: number,
  additionalPrintings: AdditionalPrintings[] | null,
) {
  let customGatewayImage = null;

  if (!additionalPrintings) return null;
  additionalPrintings.forEach(({ id, values }) => {
    if (id === lineId && values) {
      values.forEach(({ value }) => {
        if (value && /(.png)$/g.test(value)) {
          customGatewayImage = value;
        }
      });
    }
  });

  return customGatewayImage;
}
