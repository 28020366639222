import Image from 'next/image';
import * as React from 'react';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import toSlug from '~source/core/utils/to-slug';
import uuid from '~source/core/utils/uuid';
import useImageUrl from '~source/ui/hooks/formatter/useImageUrl/useImageUrl';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import escapeRegExp from '~source/ui/utils/escape-reg-exp';
import { createProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';
import $ from './search-input-item.module.scss';

interface TitleProps {
  title: string;
  query: string;
}

function FormattedTitle({ title, query }: TitleProps) {
  const textElements = React.useMemo(() => {
    const escapeCharacter = escapeRegExp(query);
    const parts = title.split(new RegExp(`(${escapeCharacter})`, 'gi'));
    return parts.map((part) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <b key={uuid()}>{part}</b>
      ) : (
        part
      ),
    );
  }, [query, title]);

  return <>{textElements}</>;
}

interface Props {
  suggestionItem: ProductTeaser;
  query: string;
  onKeyUp: (e: React.KeyboardEvent<HTMLLIElement>) => void;
}

export default function SearchInputItem({
  suggestionItem,
  query,
  onKeyUp,
}: Props) {
  const { displayValue, productId, primaryImage } = suggestionItem;
  const imageUrl = useImageUrl(primaryImage, { width: 200, height: 200 });
  const t = useTranslate();
  if (!displayValue) return null;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={$.suggestion}
      onKeyUp={onKeyUp}
      data-suggestion={displayValue}
    >
      <Image
        className={$.thumbnailImage}
        src={imageUrl}
        alt={primaryImage.name || displayValue}
        width={40}
        height={40}
        placeholder="blur"
        blurDataURL={imageUrl}
      />
      <a
        href={`/shop${createProductDetailUrl(toSlug(displayValue), productId)}`}
        aria-label={t('GO_TO_PRODUCT')}
        className={$.suggestionTitle}
      >
        <FormattedTitle title={displayValue} query={query} />
      </a>
    </li>
  );
}
