import { ProductRecommendationsSlider } from '~source/core/models/components/organisms/product-recommendations-slider';
import { ProductRecommendationsSliderResponse } from '~source/core/models/response/product-recommendations-slider-response';
import uuid from '~source/core/utils/uuid';

export default async function transformProductRecommendationsSlider(
  response: ProductRecommendationsSliderResponse,
): Promise<ProductRecommendationsSlider> {
  return {
    id: uuid(),
    moduleType: 'productRecommendationsSlider',
    dySelector: response.content.dySelector,
  };
}
