import {
  RequirementValue,
  AdditionalPrintings,
} from '~source/core/models/components/atoms/additional-printings';

function transformProductRequirementValues(requirements: {
  [key: number]: number | string | null;
}) {
  const values: RequirementValue[] = [];

  for (const [productRequirementValueId, value] of Object.entries(
    requirements,
  )) {
    values.push({
      productRequirementValueId: parseInt(productRequirementValueId, 10),
      value: value !== null ? String(value) : null,
    });
  }
  return values;
}

export default function transformGetProductRequirements(
  response: EVA.Core.GetProductRequirementValuesForOrderResponse,
): AdditionalPrintings[] | null {
  const { Requirements: values } = response;
  const additionalPrintings: AdditionalPrintings[] = [];

  if (!values?.length) return null;
  values.forEach((requirement) => {
    additionalPrintings.push({
      id: requirement.OrderLineID,
      productId: requirement.ProductID,
      values: transformProductRequirementValues(requirement.Requirements),
    });
  });

  return additionalPrintings;
}
