import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { updateAccount } from '~source/core/services/eva/api/account/account';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import useOnClickOutside from '~source/ui/hooks/ui/useOnClickOutside/useOnClickOutside';
import $ from './language-selector.module.scss';

type Props = {
  localeRedirectToHome?: boolean;
  id: string;
};

export default function LanguageSelector({ localeRedirectToHome, id }: Props) {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const { locales, pathname, query } = useRouter();
  const { account } = useAccount();
  const currentLocale = useLocale();

  const handleLocaleClick = async (locale: LocaleLanguageType) => {
    setIsOpen(false);

    if (account && account.evaAuthenticationToken)
      updateAccount({
        account,
        locale,
      });
  };
  useOnClickOutside(selectorRef, () => setIsOpen(false));

  return (
    <div className={$.wrap} ref={selectorRef}>
      <button
        type="button"
        className={$.button}
        aria-label="Select Language"
        aria-expanded={isOpen}
        aria-controls={id}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={$.iconWrap}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 25"
            className={$.icon}
            aria-hidden="true"
          >
            <path
              fill="#FFF"
              d="M12 2.308c5.355 0 9.727 4.21 9.988 9.5H22v1h-.012c-.26 5.29-4.633 9.5-9.988 9.5-5.355 0-9.727-4.21-9.988-9.5H2v-1h.012c.261-5.29 4.633-9.5 9.988-9.5zm2.322 16H9.679c.3 1.218.675 2.218 1.078 2.914a9.094 9.094 0 002.486.001c.403-.696.779-1.697 1.079-2.914zm-4.79 2.657c-.346-.745-.645-1.642-.885-2.656l-3.356-.001a9.009 9.009 0 004.24 2.657zm9.177-2.657l-3.356.001c-.24 1.013-.54 1.91-.885 2.655a8.977 8.977 0 004.24-2.656zm2.277-5.5h-4.99a27.328 27.328 0 01-.432 4.5h3.92a8.954 8.954 0 001.502-4.5zm-16.47 4.5h3.92a27.22 27.22 0 01-.432-4.5h-4.99c.09 1.66.631 3.2 1.502 4.5zm10.48-4.5H9.004a26.48 26.48 0 00.455 4.5h5.082a26.38 26.38 0 00.455-4.5zm4.488-5.501h-3.92a27.23 27.23 0 01.432 4.5h4.99a8.944 8.944 0 00-1.502-4.5zm-16.47 4.5h4.99c.026-1.614.18-3.14.432-4.5h-3.92a8.954 8.954 0 00-1.502 4.5zm6.446-4.5a26.284 26.284 0 00-.456 4.5h5.992a26.396 26.396 0 00-.456-4.5H9.46zM12 3.308c-.422 0-.837.03-1.243.085-.403.696-.779 1.697-1.078 2.914h4.642c-.27-1.1-.603-2.022-.963-2.703l-.115-.21A8.937 8.937 0 0012 3.308zm-1.681.157l-.133.026a8.99 8.99 0 00-4.894 2.817l3.355-.001c.24-1.013.54-1.91.885-2.656.257-.073.52-.136.787-.186zm4.15.186c.344.745.644 1.643.884 2.656h3.355a9.01 9.01 0 00-4.24-2.656z"
            />
          </svg>
        </div>
        <span className={$.buttonText}>
          {t(`LANGUAGE_NAME_${currentLocale}`)}
        </span>
        <Image
          src="/shop/images/icons/ic-arrow-down.svg"
          alt=""
          className={$.arrow}
          width="16"
          height="16"
        />
      </button>
      {locales && locales.length > 0 && (
        <ul
          aria-label="Select Language"
          id={id}
          className={classnames($.list, !isOpen && 'is-hidden')}
        >
          {locales.map((locale) => (
            <li className={$.language} key={locale}>
              <Link
                href={localeRedirectToHome ? '/' : { pathname, query }}
                locale={locale}
                aria-current={currentLocale === locale ? 'true' : undefined}
                className={classnames(
                  $.link,
                  currentLocale === locale && $.linkCurrent,
                )}
                onClick={() => handleLocaleClick(locale as LocaleLanguageType)}
              >
                {t(`LANGUAGE_NAME_${locale}`)}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
