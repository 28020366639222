import { cloneElement } from 'react';
import type { ReactElement } from 'react';

export default function composeComponentWrapper(elements: ReactElement[]) {
  return function Wrapper({ children }: { children: ReactElement }) {
    const WrapperElement = elements.reduceRight<ReactElement>(
      (acc, element) => {
        return cloneElement(element, { children: acc });
      },
      <>{children}</>,
    );

    return WrapperElement;
  };
}
