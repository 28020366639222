import { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';

export default function calculateTotalItemsInCart(
  cart: ShoppingCart | null,
): number | null {
  if (!cart) return null;
  return cart.lines.reduce<number>(
    (total, currentLine) => total + currentLine.quantityOpen,
    0,
  );
}
