import { Icon } from '~source/core/models/components/atoms/icon';
import { ImageLinkResponse } from '~source/core/models/response/image-link-response';
import transformLink from '~source/core/transformers/atoms/transform-link';

export default function transformIcon(iconData: ImageLinkResponse): Icon {
  const { image, link } = iconData;
  const { fields, system } = image;

  const getUrl = () => {
    const umbracoFile = fields?.umbracoFile;
    if (typeof umbracoFile === 'string') {
      return umbracoFile;
    }
    return umbracoFile.src;
  };

  return {
    id: system?.id,
    image: {
      url: getUrl(),
      alt: fields?.caption || '',
    },
    link: transformLink(link, link.id || system.id),
  };
}
