import { ProductGrid } from '~source/core/models/components/organisms/product-grid';
import { ProductGridComponentResponse } from '~source/core/models/response/product-grid-component-response';
import uuid from '~source/core/utils/uuid';

export default async function transformProductGrid(
  _data: ProductGridComponentResponse,
): Promise<ProductGrid> {
  return {
    moduleType: 'productGrid',
    layout: _data.content.layout,
    id: uuid(),
  };
}
