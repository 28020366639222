import { ProductHeader } from '~source/core/models/components/molecules/product-header';
import {
  ProductCategoryHeaderComponentSplit,
  ProductCategoryHeaderComponentFull,
} from '~source/core/models/response/product-category-header-component-response';
import transformBannerImage from '~source/core/transformers/atoms/transform-banner-image';
import uuid from '~source/core/utils/uuid';

export default async function transformProductHeader(
  data:
    | ProductCategoryHeaderComponentSplit
    | ProductCategoryHeaderComponentFull,
  index: number,
): Promise<ProductHeader> {
  const base: ProductHeader = {
    moduleType: 'productHeader',
    id: uuid(),
    anchor: `section${index + 1}`,
    title: data.content?.title || null,
    content: data.content?.text || null,
    imageLocation: 'full',
    images:
      data.content?.images.map((image) => transformBannerImage(image)) || [],
  };

  if (data.component === 'productCategoryHeaderComponentSplit') {
    base.imageLocation =
      data.content?.mirror === 'true' ? 'right' : 'left' || 'left';
  }

  return base;
}
