import { ThirdPartyProductInfo } from '~source/core/models/components/templates/order';
import transformProductToECommerce from '~source/core/transformers/e-commerce/transform-product-to-e-commerce';

const transformThirdPartyDataToECommerceItems = (
  thirdPartyProductsData: ThirdPartyProductInfo[],
) => {
  if (!thirdPartyProductsData.length) return null;
  const items = thirdPartyProductsData.map((product) => {
    const productDetails = {
      name: product.name,
      id: product.id,
      price: product.price,
      collection: product.collection,
      mainCategory: product.category,
      articleCategory: product.articleCategory,
      ageCategory: product.ageCategory,
      configurable: product.configurable,
    };
    return transformProductToECommerce(productDetails, {
      quantity: product.quantity,
    });
  });
  return items;
};

export default transformThirdPartyDataToECommerceItems;
