import * as Sentry from '@sentry/nextjs';
import * as React from 'react';
import { ID_DHL_SHIPPING_METHOD } from '~source/constants';
import type { BundleLineBase } from '~source/core/models/bundle-line-base';
import type {
  Address,
  AjaxAddress,
} from '~source/core/models/components/atoms/address';
import type { StoreType } from '~source/core/models/components/atoms/store-type';
import type { WrappingLine } from '~source/core/models/components/molecules/available-gift-wrapping-lines';
import type { ShoppingCart } from '~source/core/models/components/templates/shopping-cart';
import { sendECommerce } from '~source/core/services/e-commerce/e-commerce';
import * as ChangeOrderLinesToDeliveryService from '~source/core/services/eva/api/checkout/change-order-lines-to-delivery';
import * as ChangeOrderLinesToPickupService from '~source/core/services/eva/api/checkout/change-order-lines-to-pickup';
import * as SetOrderFieldService from '~source/core/services/eva/api/checkout/set-order-field';
import {
  setShippingMethod,
  ShippingMethod,
} from '~source/core/services/eva/api/checkout/set-shipping-method';
import type { CheckoutInfo } from '~source/core/services/eva/api/checkout/types';
import * as EvaAddressService from '~source/core/services/eva/api/checkout/update-order-address';
import { addBundleProductToOrder } from '~source/core/services/eva/api/order/add-bundle-product-to-order';
import * as AddDiscountToOrderService from '~source/core/services/eva/api/order/add-discount-coupon-to-order';
import { addProductToOrder } from '~source/core/services/eva/api/order/add-product-to-order';
import * as CancelDiscountOrderLineService from '~source/core/services/eva/api/order/cancel-discount-order-line';
import * as CancelOrderService from '~source/core/services/eva/api/order/cancel-order';
import * as GetShoppingCartService from '~source/core/services/eva/api/order/get-shopping-cart';
import * as ModifyQuantityOrderedService from '~source/core/services/eva/api/order/modify-quantity-ordered';
import * as SetGiftWrappingOptionsOnOrderService from '~source/core/services/eva/api/order/set-gift-wrapping-options-on-order';
import * as UpdateProductRequirementsForOrderService from '~source/core/services/eva/api/order/update-product-requirements-for-order';
import { transformAddress } from '~source/core/transformers/address/transform-address';
import type { UnpackPromise } from '~source/core/utils/helper-types';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { useOrder } from '~source/ui/hooks/order/useOrder/useOrder';
import { useSignalR } from '~source/ui/hooks/useSignalR/useSignalR';
import calculateDisplayCartAmount from '~source/ui/utils/math/calculate-display-cart-amount';
import calculateTotalItemsInCart from '~source/ui/utils/math/calculate-total-items-in-cart';

type AddToCartArgs = {
  id: number;
  lines?: BundleLineBase[];
  quantity?: number;
};

type ModifyProductQuantityArgs = {
  orderLineId: number;
  newQuantity: number;
};

type RemoveProductsArgs = {
  orderLineIds: number[];
};

type AddDiscountArgs = {
  couponCode: string;
};

type RemoveDiscountArgs = {
  orderLineId: number;
};

type GiftWrapArgs = {
  wrapWholeOrder: boolean;
  wrapIndividually: boolean;
  itemsToWrap?: WrappingLine[];
};

type AdditionalValuesArgs = {
  orderLineId: number;
  values: Record<number, string | number | null>;
};

type UpdateDeliveryArgs = {
  checkoutInfo: CheckoutInfo;
  authenticationToken?: string | null;
  signal?: AbortSignal;
};

type UpdateBillingArgs = {
  address: Address | null;
  authenticationToken?: string | null;
  signal?: AbortSignal;
};

export type CartContextType = {
  getShoppingCart: () => Promise<ShoppingCart | null>;
  clearShoppingCart: () => void;
  addProductToCart: (args: AddToCartArgs) => Promise<number | null>;
  modifyProductQuantity: (args: ModifyProductQuantityArgs) => Promise<void>;
  removeProducts: (args: RemoveProductsArgs) => Promise<void>;
  addDiscount: (args: AddDiscountArgs) => Promise<void>;
  removeDiscount: (args: RemoveDiscountArgs) => Promise<void>;
  giftWrap: (args: GiftWrapArgs) => Promise<void>;
  addProductRequirements: (args: AdditionalValuesArgs) => Promise<void>;
  updateDelivery: (args: UpdateDeliveryArgs) => Promise<void>;
  updateBilling: (args: UpdateBillingArgs) => Promise<AjaxAddress | null>;
  shoppingCart: ShoppingCart | null;
  displayCartAmount: number | null;
  totalItemsInCart: number | null;
  stores: StoreType[] | null;
  isLoading: boolean;
};

const CartContext = React.createContext<CartContextType | null>(null);

export function useCart(): CartContextType {
  const context = React.useContext(CartContext);
  if (!context) throw Error('[useCart] No context found');
  return context;
}

const isNetworkError = (e: any) =>
  e?.name === 'NetworkError' ||
  (e instanceof TypeError && e.message.includes('NetworkError'));

export const CartProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const locale = useLocale();
  const t = useTranslate();
  const { orderStatus, getOrder, clearOrder } = useOrder();
  const { pending: isAccountPending } = useAccount();
  const { subscribeToOrder } = useSignalR();

  const [cart, setCart] = React.useState<ShoppingCart | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const displayCartAmount = calculateDisplayCartAmount(cart);

  const clearCart = () => setCart(null);

  const getCart = React.useCallback(async () => {
    const orderId = await getOrder();

    if (!orderId) {
      setCart(null);
      return null;
    }

    setIsLoading(true);

    try {
      const response = await GetShoppingCartService.getShoppingCart({
        orderId,
        locale,
      });
      setCart(response);
      return response;
    } catch (e: any) {
      // Ignore network errors, because they can be caused by aborted requests
      if (isNetworkError(e)) return null;

      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }

    return null;
  }, [locale, getOrder]);

  const addProductToCart = React.useCallback(
    async ({ id: productId, lines, quantity = 1 }: AddToCartArgs) => {
      const orderId = await getOrder();

      let response:
        | UnpackPromise<ReturnType<typeof addBundleProductToOrder>>
        | UnpackPromise<ReturnType<typeof addProductToOrder>>;

      if (lines) {
        response = await addBundleProductToOrder({
          orderId,
          bundleProductId: productId,
          lines,
          lineActionType: 4, // delivery
        });
      } else {
        response = await addProductToOrder({
          orderId,
          productId,
          quantity,
          lineActionType: 4, // delivery
        });
      }

      if (!response) {
        throw Error('[useCart/addProductToCart] Could not add to order');
      }

      await getCart();

      sendECommerce('add_to_cart', {
        orderId: response.OrderID,
        id: productId,
        quantity,
      });

      return response.OrderLineID;
    },
    [getOrder, getCart],
  );

  const modifyProductQuantity = React.useCallback(
    async ({ orderLineId, newQuantity }: ModifyProductQuantityArgs) => {
      await ModifyQuantityOrderedService.modifyQuantityOrdered({
        orderLineId,
        newQuantity,
      });

      await getCart();
    },
    [getCart],
  );

  const removeProducts = React.useCallback(
    async ({ orderLineIds }: RemoveProductsArgs) => {
      const orderId = await getOrder();

      await CancelOrderService.cancelOrder({ orderId, orderLineIds });

      const response = await getCart();

      // Order needs to be cleared when cart became empty, because in that case EVA makes the order immutable
      if (response?.lines.length === 0) {
        clearOrder();
        clearCart();
      }
    },
    [getOrder, getCart, clearOrder],
  );

  const addDiscount = React.useCallback(
    async ({ couponCode }: AddDiscountArgs) => {
      const orderId = await getOrder();

      await AddDiscountToOrderService.addDiscountCouponToOrder({
        orderId,
        couponCode,
      });

      await getCart();
    },
    [getOrder, getCart],
  );

  const removeDiscount = React.useCallback(
    async ({ orderLineId }: RemoveDiscountArgs) => {
      await CancelDiscountOrderLineService.cancelDiscountOrderLine({
        orderLineId,
      });

      await getCart();
    },
    [getCart],
  );

  const giftWrap = React.useCallback(
    async ({ wrapWholeOrder, wrapIndividually, itemsToWrap }: GiftWrapArgs) => {
      const orderId = await getOrder();

      await SetGiftWrappingOptionsOnOrderService.setGiftWrappingOptionsOnOrder({
        orderId,
        wrapWholeOrder,
        wrapIndividually,
        itemsToWrap,
      });

      await getCart();
    },
    [getOrder, getCart],
  );

  const addProductRequirements = React.useCallback(
    async ({ orderLineId, values }: AdditionalValuesArgs) => {
      await UpdateProductRequirementsForOrderService.updateProductRequirementsForOrder(
        { orderLineId, values },
      );

      await getCart();
    },
    [getCart],
  );

  const updateDelivery = React.useCallback(
    async ({ checkoutInfo, signal }: UpdateDeliveryArgs) => {
      const orderId = await getOrder();

      const deliveryInfo = cart?.deliveryInfo ?? t('SHIPPING_COSTS');

      let address: Address | null = null;
      if (checkoutInfo.type === 'deliver-to-shipping-address') {
        address = checkoutInfo.shippingAddress;
      }

      if (checkoutInfo.type === 'deliver-to-dhl-address') {
        const { dhlAddress } = checkoutInfo;
        address = dhlAddress;

        await SetOrderFieldService.setOrderField({
          orderId,
          deliveryInfo,
          dhlServicepointId: dhlAddress.id,
          dhlServicepointName: dhlAddress.name,
          signal,
        });
      } else {
        await SetOrderFieldService.setOrderField({
          orderId,
          deliveryInfo,
          signal,
        });
      }

      if (checkoutInfo.type === 'deliver-to-pickup-address') {
        if (!checkoutInfo.pickupId)
          throw Error('[useCart/updateDelivery] No pickupId');

        await ChangeOrderLinesToPickupService.changeOrderLinesToPickup({
          orderId,
          pickupId: checkoutInfo.pickupId,
          signal,
        });
      } else {
        await ChangeOrderLinesToDeliveryService.changeOrderLinesToDelivery({
          orderId,
          signal,
        });
      }

      if (address) {
        const payload = EvaAddressService.createPayload(orderId, address);
        await EvaAddressService.updateShipping({ payload, signal });
      }

      if (address && checkoutInfo.type !== 'deliver-to-pickup-address') {
        let shippingMethodId =
          address.countryId === 'NL'
            ? ShippingMethod.HOME_DUTCH
            : ShippingMethod.HOME_FOREIGN;

        if (checkoutInfo.type === 'deliver-to-dhl-address') {
          shippingMethodId = +ID_DHL_SHIPPING_METHOD;
        }

        await setShippingMethod({
          orderId,
          shippingMethodId,
          signal,
        });
      }

      await getCart();
    },
    [t, getOrder, getCart, cart?.deliveryInfo],
  );

  const updateBilling = React.useCallback(
    async ({ address, authenticationToken, signal }: UpdateBillingArgs) => {
      const orderId = await getOrder();

      const payload = EvaAddressService.createPayload(orderId, address);
      const response = await EvaAddressService.updateBilling(
        { payload, signal },
        authenticationToken ?? null,
      );
      return transformAddress(response?.BillingAddress);
    },
    [getOrder],
  );

  /** Listen to SignalR order events */
  React.useEffect(() => {
    let cleanup: (() => void) | undefined;

    getOrder()
      .then(async (orderId) => {
        if (!orderId) return;
        cleanup = await subscribeToOrder(orderId, async () => {
          await getCart();
        });
      })
      .catch(Sentry.captureException);

    return cleanup;
  }, [getCart, getOrder, subscribeToOrder, orderStatus]);

  /** Get shopping cart once, after (optional) account has been retrieved */
  React.useEffect(() => {
    if (!isAccountPending && orderStatus !== 'unset') {
      getCart().catch(() => {
        setCart(null);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountPending]);

  /** Clear cart when order status becomes unset */
  React.useEffect(() => {
    if (orderStatus === 'unset' && cart !== null) {
      setCart(null);
    }
  }, [orderStatus, cart]);

  const contextValue = React.useMemo<CartContextType>(
    () => ({
      getShoppingCart: getCart,
      clearShoppingCart: clearCart,
      addProductToCart,
      modifyProductQuantity,
      removeProducts,
      addDiscount,
      removeDiscount,
      giftWrap,
      addProductRequirements,
      updateDelivery,
      updateBilling,
      shoppingCart: cart,
      displayCartAmount,
      totalItemsInCart: calculateTotalItemsInCart(cart),
      stores: cart?.stores ?? null,
      isLoading,
    }),
    [
      getCart,
      addProductToCart,
      modifyProductQuantity,
      removeProducts,
      addDiscount,
      removeDiscount,
      giftWrap,
      addProductRequirements,
      updateDelivery,
      updateBilling,
      cart,
      displayCartAmount,
      isLoading,
    ],
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

export default useCart;
