import {
  Banner,
  ShowText,
  TitleFont,
  TitleSize,
} from '~source/core/models/components/molecules/banner';
import {
  BannerResponse,
  BannerBaseResponse,
} from '~source/core/models/response/banner-response';
import { BannerCarouselResponse } from '~source/core/models/response/banners-carousel-response';
import transformBannerImage from '~source/core/transformers/atoms/transform-banner-image';
import transformButton from '~source/core/transformers/atoms/transform-button';
import uuid from '~source/core/utils/uuid';

const getIfIsDark = (titleFontColor: 'Black' | 'White' | null) =>
  titleFontColor === 'White';

export default function transformBanner(
  response: BannerResponse | BannerCarouselResponse | BannerBaseResponse,
  showText?: ShowText,
): Banner {
  const images = response.bannerImages.map((image) =>
    transformBannerImage(image),
  );
  const price = response.price || null;
  const title = response.headline || '';
  const links = response.buttons.map((button) => transformButton(button));
  const isDark = getIfIsDark(response.titleFontColor);
  const titleFont = response.titleFont.toLowerCase() as TitleFont;
  const titleSize = response.titleWeight.toLowerCase() as TitleSize;

  return {
    id: uuid(),
    moduleType: 'banner',
    images,
    isDark,
    links,
    price,
    title,
    text: response.subtitle,
    showText: showText || 'all devices',
    titleFont,
    titleSize,
  };
}
