export default function addFallbackValue<T>(
  value: T | null | undefined,
  propName: string,
  fallback: T,
) {
  if (value === null || value === undefined) {
    console.warn(`Required '${propName}' is missing`);
    return fallback;
  }
  return value;
}
