import {
  Availability,
  AvailabilityIndication,
} from '~source/core/models/components/atoms/availability-indication';
import { isBundle } from '~source/ui/utils/checks/is-product-type';

export default function getAvailabilityOfProduct(
  availabilityOfProducts: AvailabilityIndication | null,
  id: number | null,
  productType: number | null,
): {
  availability: Availability;
  availabilityDate: string | null;
} {
  const availability: Availability = 'pending';
  const availabilityDate = null;
  if (productType && isBundle([productType]))
    return { availability: 'available', availabilityDate };
  if (!availabilityOfProducts) return { availability, availabilityDate };
  const matchingProduct = availabilityOfProducts.products.find(
    (item) => item.id === id,
  );

  if (!matchingProduct) {
    console.warn(
      `[getAvailabilityOfProduct] - No availability found for id ${id}`,
    );
    return { availability: 'unavailable', availabilityDate };
  }
  return {
    availability: matchingProduct.availability,
    availabilityDate: matchingProduct.availabilityDate,
  };
}
